import React from "react";
import { Grid, Container, CircularProgress } from "@material-ui/core";
import Item from "./Item";
import "./style.scss";
import Title from "../UI/Title";
import * as API from "../../../api";
import { Link } from "react-router-dom";
import Carousels from "../UI/Carousel";

const PostsCard = (props) => {
  const [posts, setPosts] = React.useState([]);
  const [loadingPosts, setLoadingPosts] = React.useState(false);

  React.useEffect(() => {
    setLoadingPosts(true);
    API.Posts()
      .then((res) => {
        //console.clear();
        //console.log(res.data);
        setPosts(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .then(() => {
        setLoadingPosts(false);
      });
  }, []);
  return (
    <Container maxWidth="lg">
      {props.titleSection && (
        <Grid container spacing={4} className="p-top-30 p-bottom-30">
          <Grid item xs={12} md={12} container>
            <Title title={props.titleSection} />
          </Grid>
        </Grid>
      )}
      {loadingPosts ? (
        <>
          <Grid container justifyContent="center" alignItems="center">
            <CircularProgress alignItems="center" />
          </Grid>
        </>
      ) : (
        <>
            <Carousels
              items={posts.map((item, index) => {
                return (
                    <Link to={`/post/${item.id}?slug=${item.slug}`} className="no-decoration w-100-100">
                      <Item data={item} />
                    </Link>
                );
              })}
              NumberOfDesktop={3}
            />
        </>
      )}
    </Container>
  );
};
export default PostsCard;
