import React from 'react'
import { Grid, Container, CircularProgress } from '@material-ui/core'
import Event from '../Event'
import '../style.scss'

import * as API from '../../../../api';
import { Pagination } from '@material-ui/lab';
import { Link, useLocation } from 'react-router-dom';


const EventGrid = (props) => {

    const location = useLocation();
    const query    = new URLSearchParams( location.search );
    const _page    = parseInt(query.get('page') || '1', 10);

    const [ loadingEvents , setLoadingEvent ] = React.useState(false);
    const [ events        , setEvents       ] = React.useState([]);
    const [ page          , setPage         ] = React.useState( _page );
    const [ pages         , setPages        ] = React.useState(0);


    const loadEvenets = (_page) => {
        setLoadingEvent(true);
        API.Events(_page)
            .then((res)=>{
                setEvents( res.data );
                setPages( res.meta.last_page );
            })
            .catch((err)=>{
                console.log( err );
            })
            .then(()=>{
                setLoadingEvent(false);
            });
    }
    const handleChangePagination = (value) =>{
        // Set the curent page
        setPage(value);
        // make the connection to load the next page by value
        loadEvenets(value);
        // push value of page to the link
        window.history.pushState( {} , '', '?page='+value );
    }
    React.useEffect(()=>{
        loadEvenets(page);
    },[0]);

    const getPriceDescription = (prices) => {
        let price_description = prices.filter((item) => item.default == "1")[0]
          .description ?? "";
        return price_description;
      };

    return (
        <Container>
            <Grid
                container spacing={2}
                justifyContent="center"
                alignItems="center">

                {
                    loadingEvents ?
                    <CircularProgress />
                    :
                    <>
                        {
                            events.map(( event )=>{
                                return (
                                <Grid key={event.id} item xs={12} md={4} container>
                                    <Link to={`event/${event.id}`} className="link--events">
                                        <Event
                                            data={event}
                                            priceDescription={getPriceDescription(event.prices)}
                                            price={event.prices.slice(0, 1).map((p, index) =>  <span key={index}>{`${parseInt(p.price)} MAD` }</span>)}
                                            cityDepart={event.gathering.map((d, index) => <span className="events--circuit" key={index}> <i className="events--circuit--divider" >-</i> {d.gathering}  </span>)}
                                            circuit={event.circuit.map((c, index) =>  <span className="events--circuit" key={index}> <i className="events--circuit--divider" >-</i> {c.circuit}  </span>)}
                                        />
                                    </Link>
                                </Grid>
                                )
                            })
                        }
                    </>
                }

            </Grid>

            {
                pages > 0 &&
                <Pagination count={pages} page={page} size="large" onChange={(e,value)=>{ handleChangePagination(value)  }} />
            }
        </Container>
    )
}
export default EventGrid