import React from 'react'
import PropTypes from "prop-types";
import './style.scss'

const COLORS = ["primary", "white"];


function Large({title, color}) {
    return (
        <h1 className={`wrapper `}>
            <div className="highlight"/>
            <div className={`thelink ${color}`}>{title}</div>
        </h1>
    )
}

Large.propTypes = {
    color: PropTypes.oneOf(COLORS),
  };
  
  Large.defaultProps = {
    color: "primary",
  };
  
export default Large
