import React from "react";
import { Grid, Container, CircularProgress } from "@material-ui/core";
import Item from "./Item";
import "./style.scss";
import Title from "../UI/Title";

import * as API from '../../../api';
import { Link } from "react-router-dom";
import Carousels from "../UI/Carousel";

const GalleryCard = (props) => {
  const [ galleries        , setGalleries        ] = React.useState([]);
  const [ loadingGalleries , setLoadingGalleries ] = React.useState(false);

  React.useEffect(()=>{
    setLoadingGalleries(true);
    API.Galleries()
      .then((res)=>{
        //console.log(res.data);
        setGalleries( res.data );
      })
      .catch((err)=>{
        console.log(err);
      })
      .then(()=>{
        setLoadingGalleries(false);
      })
  },[0]);
  return (
    <Container maxWidth="lg">
      { props.titleSection && 
        <Grid container spacing={4} className="p-top-30 p-bottom-30">
            <Grid item xs={12} md={12} container>
                <Title title={props.titleSection} />
            </Grid>
        </Grid>
      }
      {
        loadingGalleries ?
        <>
          <Grid container justifyContent="center" alignItems="center" >
            <CircularProgress alignItems='center' />
          </Grid>
        </>
        :
        <>
          <Carousels
              showDots={false}
              NumberOfDesktop={3}
              items={galleries.map((item, index) => {
              return (
                  <Grid key={index} item xs={12} md={12} >
                    <Link to={`/gallery/${item.id}`} className="w-100-100">
                      <Item data={item} />
                    </Link>
                  </Grid>
              );
            })}
            />
        </>
      }
    </Container>
  );
};
export default GalleryCard;
