import React from "react";
import Collapse from "../../shared/components/UI/Collapse";
import { Grid, Container, CircularProgress } from "@material-ui/core";
import Banner from "../../shared/components/UI/Banner";
import banner02 from "../../shared/assets/images/banner04.jpg";
import * as API from '../../api';


const FQA = () => {
  const [ data        , setData        ] = React.useState([]);
  const [ loadingData , setLoadingData ] = React.useState(false);

  React.useEffect(()=>{
    
    setLoadingData(true);
    API.FAQ()
      .then((res)=>{
        //console.log(res.data);
        setData( res.data );
      })
      .catch((err)=>{
        console.log(err);
      })
      .then(()=>{
        setLoadingData(false);
      });

  },[0]);
  
  return (
    <div className="section--faqs">
      <Banner size={"240px"} bg={banner02}>
        <Container maxWidth="lg" alignItems="center" justify="center">
          <Grid
           container
            item
            xs={12}
            sm={12}
            alignItems="center"
            justifyContent="center"
            className="h-100-100"
          >
            <div className="tafwija--banner--content">
              <h1> FQAS </h1>
              <p>
                Réservez en deux clicks des expériences inoubliables{" "}
              </p>
            </div>
          </Grid>
        </Container>
      </Banner>

      <div className="p-top-40">
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          {
            loadingData ?
            <>
              <CircularProgress />
            </>
            :
            <>
              <Grid item xs={12} md={10}>
                {data.map((item, index) => {
                  return (
                    <Collapse
                      key={index}
                      header={
                        <h3 className="section--faqs--title" >
                          {item.question}
                        </h3>
                      }
                    >
                      <p className="section--faqs--desc">
                      {item.answer}
                      </p>
                    </Collapse>
                  );
                })}
              </Grid>
            </>
          }
              
        </Grid>
      </div>
    </div>
  );
}

export default FQA;
