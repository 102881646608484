import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Button from "../../UI/Button/Button";
import Link from "../../UI/Link";
import Modal from "../../UI/Modal";
import { Logo } from "../../UI/Logo";
import Container from "@material-ui/core/Container";
// import "./style.scss";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";

import Divider from "@material-ui/core/Divider";
import {
  FiUser,
  FiUserPlus,
  FiSliders,
  FiLogOut,
  FiLock,
  FiMail,
} from "react-icons/fi";
import {
  FcMoneyTransfer,
  FcShop,
  FcBusinessContact,
  FcGoogle,
} from "react-icons/fc";
import { FaFacebookF } from "react-icons/fa";

import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "../../UI/Input";
import Login from "./login";
import Register from "./register";

import * as API from "../../../../api";
import { useSnackbar } from "notistack";
import homeIcon from "../../../assets/images/menu--01.svg";
import TripIcon from "../../../assets/images/menu--02.svg";
import ActivityIcon from "../../../assets/images/menu--03.svg";

const Header = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [auth, setAuth] = useState(false);
  const [modalShown, toggleModal] = React.useState(false);
  const [modalShownRegister, toggleModalRegister] = React.useState(false);

  const [click, setClick] = useState(false);
  const [alert, setAlert] = useState(true);
  const [statusButton, setStatusButton] = useState(false);
  const [button, setButton] = useState(false);
  const [subLink, setSubLink] = useState(false);
  const [statusSubLink, setStatusSubLink] = useState(false);
  const [settings, setSettings] = React.useState({});

  const [loadingAuth, setLoadingAuth] = useState(false);
  const [loadingResendEmail, setLoadingResendEmail] = useState(false);
  const [emailMessageReceve, setEmailMessageReceve] = useState(false);

  const [drawer, setDrawer] = useState(true);
  const handleClickMenu = () => setDrawer(!drawer);
  const [open, setState] = useState(false);
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState(open);
  };

  const [drawerEspace, setDrawerEspace] = useState(true);
  const handleClickMenuEspace = () => setDrawerEspace(!drawerEspace);
  const [openEspace, setStateEspace] = useState(false);
  const toggleDrawerEspace = (openEspace) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setStateEspace(openEspace);
  };

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (statusButton) {
      if (window.innerWidth <= 960) {
        setButton(false);
      } else {
        setButton(true);
      }
    }
  };

  const showSubMenu = () => {
    if (statusSubLink) {
      if (window.innerWidth <= 960) {
        setSubLink(true);
      } else {
        setSubLink(false);
      }
    }
  };

  const resendEmailVerifecation = () => {
    //API
    setLoadingResendEmail(true);

    API.ResendVerefecationEmail()
      .then((res) => {
        enqueueSnackbar(res.message, { variant: "success" });
        setEmailMessageReceve(true);
      })
      .catch((err) => {
        enqueueSnackbar(err.data.errors, { variant: "error" });
      })
      .then(() => {
        setLoadingResendEmail(false);
      });
  };

  useEffect(() => {
    setLoadingAuth(true);
    API.Auth()
      .then(({ user }) => {
        console.log("this is our user", user);
        setAuth(true);

        // Hide 'becom a partner' button
        setStatusButton(false);
      })
      .catch((error) => {
        console.log("this is our error", error);
        setAuth(false);

        // Show 'becom a partner' button
        setStatusButton(true);
        setStatusSubLink(true);
        showButton();
        showSubMenu();
      })
      .then(() => {
        setLoadingAuth(false);
      });

    //   setTimeout( () => {
    //     setAlert(!alert)
    // }, 5000)

    API.Settings().then((res) => {
      setSettings(res.settings);
    });
  }, []);

  window.addEventListener("resize", showButton);
  window.addEventListener("resize", showSubMenu);

  return (
    <>
      <nav className="navbar">
        <Container maxWidth="lg">
          <div className="navbar-container">
            <NavLink to="/" className="navbar-logo" onClick={closeMobileMenu}>
              <Logo />
            </NavLink>

            <ul className={click ? "nav-menu active" : "nav-menu"}>
              <li className="nav-item">
                <NavLink
                  to="/"
                  activeClassName={"active"}
                  exact={true}
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  Accueil
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/events?type=Event"
                  activeClassName={
                    window.location.path === "events?type=Event" ? "active" : ""
                  }
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  Voyages
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  to={{
                    pathname: "/events",
                    search: "?type=Activitie",
                  }}
                  activeClassName={
                    window.location.href === "events?type=Activitie"
                      ? "active"
                      : ""
                  }
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  Activités
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  to={{
                    pathname: "/events",
                    search: "?type=Sejour",
                  }}
                  activeClassName={
                    window.location.href === "events?type=Sejour"
                      ? "active"
                      : ""
                  }
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  Séjours
                </NavLink>
              </li>

              {!loadingAuth && auth && (
                <>
                  <li className="nav-item">
                    <NavLink
                      to="/user/orders-confirmed"
                      className="nav-links-mobile"
                      onClick={closeMobileMenu}
                    >
                      Mes réservations
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink
                      to="/logout"
                      className="nav-links-mobile "
                      onClick={closeMobileMenu}
                    >
                      Déconnextion
                    </NavLink>
                  </li>
                </>
              )}
            </ul>

            <div className="menu--right">
              {!loadingAuth && (
                <>
                  {!auth && (
                    <>
                      <Link
                        linkStyle={"link--btn--one"}
                        linkSize={"link--btn--medium"}
                        // to="/connection/?target=login"
                        to="#"
                        onClick={() => {
                          toggleModal(!modalShown);
                        }}
                      >
                        <FiUser size={20} />
                        <span>Se connecter</span>{" "}
                      </Link>
                      <Link
                        linkStyle={"link--btn--one"}
                        linkSize={"link--btn--medium"}
                        // to="/connection/?target=login"
                        to="/become-partner"
                      >
                        <i className="fas fa-briefcase"></i>
                        <span> Devenir Partenaire </span>{" "}
                      </Link>
                    </>
                  )}

                  {auth && (
                    <>
                      <Link
                        buttonSize={"link--btn--medium"}
                        buttonStyle="link--btn--one"
                        to={"#"}
                        aria-label="open drawer"
                        onClick={toggleDrawerEspace(true)}
                      >
                        <FiUser />
                        <span>{API.AuthStorage.user.name}</span>
                      </Link>
                    </>
                  )}
                </>
              )}
              <IconButton
                className="menu--drawer"
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer(true)}
                sx={{
                  mr: 2,
                  display: {
                    xs: "block",
                    sm: "none",
                  },
                }}
              >
                <MenuIcon />
              </IconButton>
            </div>
          </div>
        </Container>
      </nav>

      <Drawer
        className="sidebar--menu"
        anchor="right"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        {/* <IconButton sx={{ mb: 2 }}>
            <CloseIcon onClick={toggleDrawer(false)} />
          </IconButton> */}

        <NavLink to="/" className="logo" onClick={closeMobileMenu}>
          <Logo />
        </NavLink>

        <Divider sx={{ mb: 2 }} />

        <div className="sidebar--menu--heading">
          {!loadingAuth && (
            <>
              {!auth && <h2>Bonjour</h2>}
              {auth && <h2>Bonjour, {API.AuthStorage.user.name}</h2>}
            </>
          )}
        </div>

        <div className="sidebar--menu--items">
          <NavLink
            to="/"
            activeClassName={"active"}
            exact={true}
            className="sidebar--menu--items--item"
            onClick={toggleDrawer(false)}
          >
            <h3>Accueil</h3>
            <img src={homeIcon} alt="" />
          </NavLink>
          <NavLink
            to="/events?type=Event"
            activeClassName={
              window.location.path === "events?type=Event" ? "active" : ""
            }
            onClick={toggleDrawer(false)}
            className="sidebar--menu--items--item"
          >
            <h3>Voyages</h3>
            <img src={TripIcon} alt="" />
          </NavLink>
          <NavLink
            to={{
              pathname: "/events",
              search: "?type=Activitie",
            }}
            activeClassName={
              window.location.href === "events?type=Activitie" ? "active" : ""
            }
            onClick={toggleDrawer(false)}
            className="sidebar--menu--items--item"
          >
            <h3>Activités</h3>
            <img src={ActivityIcon} alt="" />
          </NavLink>
        </div>

        <div className="sidebar--menu--bottom">
          <Divider sx={{ mb: 2 }} />

          {!loadingAuth && (
            <>
              {/* {!auth && (
                <>
                  <div className="sidebar--menu--bottom--item">
                    <Link
                      linkStyle={"link--btn--two"}
                      linkSize={"link--btn--medium"}
                      to="#"
                      onClick={() => {
                        toggleDrawer(false);
                        toggleModal(!modalShown);

                      }}
                    >
                      <FiUser size={20} />
                      <span>Se connecter</span>{" "}
                    </Link>
                      <Link
                        linkStyle={"link--btn--one"}
                        linkSize={"link--btn--medium"}
                        // to="/connection/?target=login"
                        to="/become-partner"
                      >
                        <i className="fas fa-briefcase"></i>
                        <span> Devenir Partenaire </span>{" "}
                      </Link>
                  </div>
                  <div className="sidebar--menu--bottom--item">
                    <Link
                      linkStyle={"link--btn--three"}
                      linkSize={"link--btn--medium"}
                      to="#"
                      onClick={() => {
                        toggleDrawer(false);
                        toggleModal(!modalShown);
                      }}
                    >
                      <FiUserPlus size={20} />
                      <span>S'inscrire </span>{" "}
                    </Link>
                  </div>
                </>
              )} */}
              {auth && (
                <>
                  <div className="sidebar--menu--bottom--item">
                    <Link
                      linkStyle={"link--btn--three"}
                      linkSize={"link--btn--medium"}
                      to={`/account/orders`}
                      onClick={toggleDrawer(false)}
                    >
                      <FiSliders />
                      <span> Mon Espace</span>
                    </Link>
                  </div>

                  <div className="sidebar--menu--bottom--item">
                    <Link
                      linkStyle={"link--btn--danger"}
                      linkSize={"link--btn--medium"}
                      to="/logout"
                    >
                      <FiLogOut />
                      <span> Déconnextion</span>
                    </Link>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </Drawer>

      <Drawer
        className="sidebar--menu"
        anchor="right"
        open={openEspace}
        onClose={toggleDrawerEspace(false)}
        onOpen={toggleDrawerEspace(true)}
      >
        <NavLink to="/" className="logo" onClick={closeMobileMenu}>
          <Logo />
        </NavLink>

        <Divider sx={{ mb: 2 }} />

        <div className="sidebar--menu--heading">
          {!loadingAuth && (
            <>
              {!auth && <h2>Bonjour</h2>}
              {auth && <h2>Bonjour, {API.AuthStorage.user.name}</h2>}
            </>
          )}
        </div>

        <ul className={"sidebar--menu--list"}>
          <li className="sidebar--menu--list--item">
            <NavLink
              to="/account/orders"
              activeClassName={"active"}
              exact={true}
              className="sidebar--menu--list--item--link"
              onClick={toggleDrawerEspace(false)}
            >
              <span>Mes Réservations</span>
              <FcShop size={32} />
            </NavLink>
          </li>
          {/*
          <li className="sidebar--menu--list--item">
            <NavLink
              to="/account"
              activeClassName={
                window.location.path === "account" ? "active" : ""
              }
              className="sidebar--menu--list--item--link"
              onClick={toggleDrawerEspace(false)}
            >
              <span>Mes crédites</span>
              <FcMoneyTransfer size={32} />
            </NavLink>
          </li>
          */}

          <li className="sidebar--menu--list--item">
            <NavLink
              to={{
                pathname: "/account/edit",
              }}
              activeClassName={
                window.location.href === "/account/edit" ? "active" : ""
              }
              className="sidebar--menu--list--item--link"
              onClick={toggleDrawerEspace(false)}
            >
              <span>Mes informations</span>
              <FcBusinessContact size={32} />
            </NavLink>
          </li>
        </ul>

        <div className="sidebar--menu--bottom">
          <Divider sx={{ mb: 2 }} />

          {!loadingAuth && (
            <>
              {!auth && (
                <>
                  <div className="sidebar--menu--bottom--item">
                    <Link
                      linkStyle={"link--btn--two"}
                      linkSize={"link--btn--medium"}
                      to="/connection/?target=login"
                    >
                      <FiUser size={20} />
                      <span>Se connecter</span>{" "}
                    </Link>
                    <Link
                        linkStyle={"link--btn--one"}
                        linkSize={"link--btn--medium"}
                        // to="/connection/?target=login"
                        to="/become-partner"
                      >
                        <i className="fas fa-briefcase"></i>
                        <span> Devenir Partenaire </span>{" "}
                      </Link>
                  </div>
                  <div className="sidebar--menu--bottom--item">
                    <Link
                      linkStyle={"link--btn--three"}
                      linkSize={"link--btn--medium"}
                      to="/connection/?target=signup"
                    >
                      <FiUserPlus size={20} />
                      <span>S'inscrire </span>{" "}
                    </Link>
                  </div>
                </>
              )}
              {auth && (
                <>
                  <div className="sidebar--menu--bottom--item">
                    <Link
                      linkStyle={"link--btn--danger"}
                      linkSize={"link--btn--medium"}
                      to="/logout"
                    >
                      <FiLogOut />
                      <span> Déconnextion</span>
                    </Link>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </Drawer>

      <Login
        shown={modalShown}
        close={() => {
          toggleModal(false);
        }}
        toRegister={() => {
          toggleModalRegister(true);
          toggleModal(false);
        }}
      />
      <Register
        shown={modalShownRegister}
        close={() => {
          toggleModalRegister(false);
        }}
        toLogin={() => {
          toggleModal(true);
          toggleModalRegister(false);
        }}
      />
    </>
  );
};

export default Header;
