import React from "react";
import Collapse from "../../shared/components/UI/Collapse";
import {
  Grid,
  Container,
  CircularProgress,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import Banner from "../../shared/components/UI/Banner";
import Input from "../../shared/components/UI/Input";

import banner02 from "../../shared/assets/images/banner04.jpg";
import * as API from "../../api";
import Button from "../../shared/components/UI/Button/Button";
import { Alert } from "@material-ui/lab";
import Title from "../../shared/components/UI/Title";

const Contact = () => {
  const [loading, setLoading] = React.useState(false);
  const [nameHelper, setNameHelper] = React.useState("");
  const [emailHelper, setEmailHelper] = React.useState("");
  const [phoneHelper, setPhoneHelper] = React.useState("");
  const [messageHelper, setMessageHelper] = React.useState("");

  const [contactHelper, setContactHelper] = React.useState({
    type: "error",
    message: "",
  });

  const sendMessage = (event) => {
    const formData = new FormData(event.target);

    setContactHelper({ type: "", message: "" });
    setNameHelper("");
    setEmailHelper("");
    setPhoneHelper("");

    setLoading(true);
    API.Contact(formData)
      .then((res) => {
        setContactHelper({
          type: "success",
          message: " Votre message a été envoyé avec succès ",
        });
        event.target.reset();
      })
      .catch((err) => {
        // Validation
        if (err.status === 422) {
          setContactHelper({ type: "error", message: err.data.message });
          if (err.data.errors.full_name) {
            setNameHelper(err.data.errors.full_name);
          }
          if (err.data.errors.email) {
            setEmailHelper(err.data.errors.email);
          }
          if (err.data.errors.phone) {
            setPhoneHelper(err.data.errors.phone);
          }
        }
        // Unauthorized or user not found
        if (err.status === 401) {
          setContactHelper({ type: "error", message: err.data.errors });
        }
        // Server error
        if (err.status === 500) {
          setContactHelper({ type: "error", message: "Erreur du serveur" });
        }
      })
      .then(() => {
        setLoading(false);
      });

    event.preventDefault();
  };

  return (
    <div className="section--faqs">
      <Banner size={"240px"} bg={banner02}>
        <Container maxWidth="lg" alignItems="center" justify="center">
          <Grid
           container
            item
            xs={12}
            sm={12}
            alignItems="center"
            justifyContent="center"
            className="h-100-100"
          >
            <div className="tafwija--banner--content">
              <h1> Contact </h1>
              <p>Formular pour contact</p>
            </div>
          </Grid>
        </Container>
      </Banner>

      <Container maxWidth="lg">
        <Grid container spacing={4}  alignItems="center" justifyContent="center" className="p-top-30 p-bottom-30">
          <Grid item xs={12} md={6} container>
            <div className="auth--wrapper--form">
              <form
                onSubmit={sendMessage}
                method="POST"
                className="auth--wrapper--form--content"
              >
                {contactHelper.message && (
                <div className="auth--wrapper--form--content--item p-bottom-20">
                  <Alert severity={contactHelper.type}>
                    {" "}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: contactHelper.message,
                      }}
                    ></div>{" "}
                  </Alert>
                </div>
              )}
                <div className="auth--wrapper--form--content--item">
                    <Input
                      title={"Nom et Prénom"}
                      helperText={nameHelper}
                      error
                      name="full_name"
                    />
                  </div>
                  <div className="auth--wrapper--form--content--item">
                    <Input
                      title={"Email"}
                      helperText={emailHelper}
                      error
                      name="email"
                    />
                  </div>
                  <div className="auth--wrapper--form--content--item">
                    <Input
                      title={"Votre numéro téléphone"}
                      helperText={phoneHelper}
                      error
                      name="phone"
                    />
                  </div>
                  <div className="auth--wrapper--form--content--item">
                    <TextField
                      title={"Message"}
                      helperText={messageHelper}
                      style={{ width: "100%" }}
                      multiline
                      rows={4}
                      variant="outlined"
                      name="message"
                    />
                  </div>
                  <div className="auth--wrapper--form--content--item p-top-20">
                    <Button
                      type={"submit"}
                      buttonSize={"btn--large"}
                      disabled={loading}
                    >
                      {loading ? (
                        <>
                          <CircularProgress color="white" />
                        </>
                      ) : (
                        <> Envoyer </>
                      )}
                    </Button>
                  </div>

              </form>
            </div>
          </Grid>
        </Grid>
      </Container>


    </div>
  );
};

export default Contact;
