import { CircularProgress } from "@material-ui/core";
import Button from "../shared/components/UI/Button/Button";
import { useState } from "react";
import * as API from "../api";


const PaymentContainer = ({buttonText = "", style = {}, reservation = null}) => {
    const order = reservation || JSON.parse(localStorage.getItem("order_response")) || {};

    const [preparingPayment, setPreparingPayment] = useState(false);


    function submitToPaywall(payload, signature, paywallUrl) {
        const form = document.createElement('form');
        form.method = 'POST';
        form.action = paywallUrl;
        form.target = '_self';

        const payloadInput = document.createElement('input');
        payloadInput.type = 'hidden';
        payloadInput.name = 'payload';
        payloadInput.value = payload;

        const signatureInput = document.createElement('input');
        signatureInput.type = 'hidden';
        signatureInput.name = 'signature';
        signatureInput.value = signature;

        form.appendChild(payloadInput);
        form.appendChild(signatureInput);

        document.body.appendChild(form);

        form.submit();

        // Optional: Remove form after submission
        document.body.removeChild(form);
    }
    // handle payment
    const preparePayment = () => {
        if (!order.id) return;
        setPreparingPayment(true);
        API.preparePayment(order.id)
            .then((res) => {
                submitToPaywall(res?.data?.json_payload, res?.data?.signature, res?.data?.paywallUrl);
            })
            .catch((err) => {
                console.log("this is error", err);
            })
            .then(() => {
                setPreparingPayment(false);
            });
    }

    return(
        <Button
            buttonStyle={"btn--second"}
            buttonSize={"btn--medium"}
            onClick={preparePayment}
            style={style}
        >
            {preparingPayment ? (
                <CircularProgress size={28} color="white" />
            ) : (
                <> <i class="fa fa-credit-card"></i> <span>{buttonText}</span> </> 
            )}
        </Button>
    )

};

export default PaymentContainer;