import React from "react";
import Header from "./shared/components/Layout/Header";
import { BrowserRouter as Router, Switch, Route, useHistory,useLocation ,withRouter} from "react-router-dom";
import NotFound from "./screens/NotFound";
import Home from "./screens/Home";
import Connection from "./screens/Connection";
//import ConnectionClient from "./screens/ConnectionClient";
import Reset from "./screens/Connection/Reset";    
import EmailVerify from "./screens/Connection/EmailVerify";    
import User from "./screens/User";
// import Partner from "./screens/Partner";
import Events from "./screens/Events";
import Detail from "./screens/Events/Detail";
import Booking from "./screens/Booking";
import Galleries from "./screens/Galleries";
import Details from "./screens/Galleries/Details";
import Blog from "./screens/Blog";
import DetailsBlog from "./screens/Blog/Details";

import FQA from "./screens/FQA";
import CGU from "./screens/CGU";
import Contact from "./screens/Contact";

import Checkout from "./screens/Booking/checkout";
import Thankyou from "./screens/Booking/thankyou";
import PaymentCanceled from "./screens/Payment/PaymentCanceled";
import PaymentFailed from "./screens/Payment/PaymentFailed";
import PaymentSuccess from "./screens/Payment/PaymentSuccess";
import * as API from "./api";
import Footer from "./shared/components/Layout/Footer";



import { SnackbarProvider } from 'notistack';
import Partners from "./screens/Partners";


function _ScrollToTop(props) {
  const { pathname } = useLocation();
  React.useEffect(() => {
      window.scrollTo(0, 0);
  }, [pathname]);
  return props.children
}
const ScrollToTop = withRouter(_ScrollToTop)




const Logout = () => {
  const history = useHistory();
  React.useEffect(() => {
    const query = new URLSearchParams( history.location.search );
    const target= query.get('target');
    let directed="/";
    if( target === "login" || target === "signup" ){
        //setValue(1);
        directed="/connection?target="+target;
    }
    
    API.Logout()
      .then((r) => {
        window.location.replace( directed );
      })
      .catch((e) => {
        window.location.reload();
        // console.log(e);
      });
    
  }, [0]);
  return <div style={{height:'50vh', display:'flex', alignItems:'center', justifyContent:'center'}}>Déconnexion 👋...</div>;
};

const App = () => {
  const [statusHeader, setStatausHeader] = React.useState(true);

  React.useEffect(() => {
    document.title = API.APP_NAME + " - Home ";
    if (window.location.pathname === "/logout") {
      setStatausHeader(false);
    }
  }, [0]);
  return (
    <>
    <SnackbarProvider maxSnack={3} >
      <Router>
      <ScrollToTop>
        {statusHeader && <Header />}
        <Switch>
          <Route path="/" exact component={Home} />

          <Route path="/connection/:provider"  component={Connection} />
          <Route path="/logout" component={Logout} />
          <Route path="/reset/:key?" component={Reset} />
          <Route path="/email/verify/:key?" component={EmailVerify} />

          <Route path="/account/:page?" component={User} />
          {/*
          <Route path="/partner" component={Partner} />
          <Route path="/partner/:page?" component={Partner} />
          */}
          <Route path={["/events", "/search"]} component={Events} />
          <Route path="/event/:id" component={Detail} />
          <Route path="/booking" component={Booking} />
          <Route path="/checkout" component={Checkout} />
          <Route path="/thankyou" component={Thankyou} exact />
          <Route path="/payment-success" component={PaymentSuccess} />
          <Route path="/payment-canceled" component={PaymentCanceled} />
          <Route path="/payment-failed" component={PaymentFailed} />


          <Route path="/galleries" component={Galleries} />
          <Route path="/gallery/:id" component={Details} />

          <Route path="/blog" component={Blog} />
          <Route path="/post/:id" component={DetailsBlog} />

          <Route path="/faqs" component={FQA} />
          <Route path="/cgu" component={CGU} />
          <Route path="/contact" component={Contact} />
          <Route path="/become-partner" component={Partners} />


          <Route component={NotFound} />
        </Switch>
        <Footer />
        </ScrollToTop>
      </Router>
    </SnackbarProvider>


    </>
  );
};

export default App;
