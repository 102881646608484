import React, { useEffect } from "react";
import {
  RoomOutlined,
  ScheduleOutlined,
  CalendarTodayOutlined,
  FilterList,
} from "@material-ui/icons";
import "../style.scss";
import Carpool from "../../UI/Carpool";
import DepartCity from "../../UI/DepartCity";
import Badge from "../../UI/Badge";
import WOW from "wow.js";

const Event = (props) => {
  useEffect(() => {
    const wow = new WOW();
    wow.init();
  }, []);
  return (
    <div
      className="card--event  wow zoomIn"
      data-wow-offset="50"
      data-wow-delay="0.5s"
    >
      {/* {props.data.type == "Activitie" ? (
        <div className="card--event--type bg--yellow">
          <p>Activitie</p>
        </div>
      ) : (
        <div className="card--event--type bg--green">
          <p>Voyage</p>
        </div>
      )} */}

      <div className="card--event--image">
        {props.type}
        <img src={props.data.image} alt={props.data.title} />
        <div className="card--event--image--overlay" />

        <div className="card--event--image--top">
          <div className="m-top-10"></div>
          {props.data.type == "Activitie" ? (
            // Activitie
            <>{props.data.expire ? <Badge specificBackgroundColor="#FFA500" title={"Expiré"} /> : null}</>
          ) : (
            // Event
            <div className="m-top-10">
              {props.data.expire ? (
                <Badge specificBackgroundColor="#FFA500" title={"Expiré"} />
              ) : (
                <>
                  {parseInt(props.data.places_available) < 1 ? (
                    <Badge specificBackgroundColor="#D9534F" title={"Sold out"} />
                  ) : null}
                </>
              )}
            </div>
          )}

          {/* <div><DepartCity title={`Départ de ${props.cityDepart}`} /></div> */}
        </div>

        <div className="card--event--image--bottom">
          <div className="card--event--image--bottom--date">
            <div className="card--event--image--bottom--date--item">
              {props.data.type == "Event" ? (
                <>
                  <CalendarTodayOutlined />
                  <span>{props.data.date_depart}</span>
                </>
              ) : (
                <></>
              )}
            </div>

            <div className="card--event--image--bottom--date--item">
              {props.data.type == "Activitie" || props.data.type == "Sejour" ? (
                // Activitie
                ""
              ) : (
                // Event
                <div className="card--event--image--bottom--count">
                  <ScheduleOutlined />
                  <span>{`${props.data.total_days} Jour(s)`}</span>
                </div>
              )}
            </div>
          </div>

          {props.data.covoiturage == 1 && props.data.type != "Sejour" ? (
            <Carpool title={"Covoiturage"} />
          ) : (
            " "
          )}
        </div>
      </div>

      <div
        className={`card--event--content ${
          props.data.type == "Sejour" ? "card--event--content--sejour" : ""
        }`}
        style={{ paddingTop: "40px" }}
      >
        <div className="card--event--content--head">
          <h1>{props.data.title}</h1>
          <div style={{ display: "flex" }}>
            <h3>{props.price}</h3>
            <h2 style={{ paddingLeft: "8px" }}>{props.priceDescription}</h2>
          </div>
        </div>

        {props.data.type === "Sejour" ? (
          <p style={{ lineHeight: "2em", paddingBottom: "4px" }}>
            {props.data.equipments && props.data.equipments.length > 0 ? (
              <div className="card--event--content--circuit">
                <p>
                  {props.data.equipments.map((item, index) => {
                    return (
                      <span key={index}>
                        {item.nbr_properties > 0 ? item.nbr_properties : ""}
                        { " " } {item.name}
                        {index < props.data.equipments.length - 1 ? ", " : ""}
                      </span>
                    );
                  })}
                </p>
              </div>
            ) : null}
          </p>
        ) : // Your content when the condition is false
        null}

        {props.data.type == "Activitie" || props.data.type == "Sejour" ? (
          // Activitie
          <div className="card--event--content--circuit">
            <span className="name">
              <RoomOutlined /> <span>Localisation: </span>
            </span>
            <p style={{ marginLeft: 6 }}> {props.data.citie}</p>
          </div>
        ) : (
          // Event
          <div className="card--event--content--circuit">
            <span className="name">
              <RoomOutlined /> <span>Départ: </span>
            </span>
            <p> {props.cityDepart}</p>
          </div>
        )}

        {props.data.type == "Activitie" || props.data.type == "Sejour" ? (
          // Activitie
          ""
        ) : (
          // Event
          <div className="card--event--content--circuit">
            <span className="name">
              <RoomOutlined /> <span>Circuit: </span>{" "}
            </span>
            <p> {props.circuit}</p>
          </div>
        )}

        {/* <div className="card--event--content--circuit">
          <span className="name">
            <FilterList /> <span>Type: </span>{" "}
          </span>
          <p> {props.data.type}</p>
        </div> */}
      </div>
    </div>
  );
};
export default Event;
