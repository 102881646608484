
import React from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Banner from "../../shared/components/UI/Banner";
import Card from "../../shared/components/UI/Card";
import banner02 from "../../shared/assets/images/banner04.jpg";
import TransactionDetail from "../../shared/components/TransactionDetail";
import Success from "../../shared/assets/images/payment-success.svg";
import not_found_image from '../../shared/assets/images/page_not_found_su7k.svg'

import { useHistory } from "react-router-dom";

import * as API from "../../api";
import { CircularProgress } from "@material-ui/core";

const PaymentSuccess = () => {
    const history = useHistory();
    const order = JSON.parse(localStorage.getItem("order_response")) || {};
    const auth = API.AuthStorage;
    const [totalPrice, setTotalPrice] = React.useState(0);
    const [totalPriceReduc, setTotalPriceReduc] = React.useState(0);
    const [totalPriceRest, setTotalPriceRest] = React.useState(0);
    const [loading, seLoading] = React.useState(true);
    const [totalPricePay, setTotalPricePay] = React.useState(0);
    const [transactionDetail, setTransactionDetail] = React.useState(null);
    const [road, setRoad] = React.useState(null);


    const calcPrices = (order) => {
        let _totalPrice = 0;
        order.prices.forEach((price) => {
            let p = parseInt(price.price) * parseInt(price.qt ?? price.quantities);
            _totalPrice = _totalPrice + p;
        });

        let _percentageSettings = 100 - order.percentage_paid;
        let _total = _totalPrice - (_totalPrice / 100) * (parseInt(order.coupon_value) ? parseInt(order.coupon_value) : parseInt(order.customer_discount));
        let _forPay = _total - (_total / 100) * _percentageSettings;
        let _forRest = _total - _forPay;
        setTotalPrice(_totalPrice);
        setTotalPriceReduc(_total);
        setTotalPriceRest(_forRest);
    };

    const roaders = [
        { id: 1, value: 0, name: "Je ne suis pas motorisé" },
        { id: 2, value: 3, name: "Je suis motorisé, j’ai 3 places libres" },
        { id: 3, value: 2, name: "Je suis motorisé, j’ai 2 places libres" },
        { id: 4, value: 1, name: "Je suis motorisé, j’ai une place libres" },
        { id: 5, value: 0, name: "Je suis motorisé, je n’ai aucune place libre" },
      ];

      const statuses = {
        'AUTHORIZE_PENDING': 'En attente',
        'AUTHORIZED': 'Autorisé',
        'AUTH_REVERSED': 'Inversement',
        'CANCELLED': 'Annulée',
        'DECLINED': 'Refusé',
        'CHARGE_PENDING': 'Charge en cours',
        'CHARGED': 'Facturé',
        'CHARGED_BACK': 'Remboursé',
        'CHARGEBACK_REVERSED': 'Inversement remboursé',
        'CHARGEBACK_PENDING': 'Remboursement en attente',
        'REFUND_PENDING': 'Remboursement en attente',
        'REFUNDED': 'Remboursé',
        'ERROR': 'Erreur',
        'CREDITED_PENDING': 'Crédit en attente',
        'CREDITED': 'Crédité'
    }; // Outputs: "Autorisé"


    const totalPlace = (_prices) => {
        let places = 0;
        _prices.forEach((price) => {
            places = parseInt(places) + parseInt(price.qt ?? price.quantities);
        });
        return places;
    };

    React.useEffect(() => {
        if (!Object.keys(order).length || !Object.keys(auth).length) {
            history.replace('/')
        }
        calcPrices(order);
        if (roaders.some((r) => r.id == parseInt(order.roader))) {
            setRoad(roaders.find((r) => r.id == parseInt(order.roader)).name);
          }

        if(order && order.id && !transactionDetail) {
            API.TransactionDetail(order.id).then((res) => {
                let transaction = res.data;
                setTransactionDetail(transaction);
                // format the transaction.total_amount tow number after the decimal point
                setTotalPricePay(parseFloat(transaction.total_amount).toFixed(2));
              }).catch((err) => {
                console.log(err);
            }).finally(() => {
                seLoading(false);
            });
        }

    }, []);

    return (
            loading ? (
                <Grid container justifyContent="center">
                    <div style={{height:"100px", paddingTop:"20px"}}>
                        <CircularProgress />
                    </div>
                </Grid>
            ): (
                !transactionDetail || !order ? (
                        <Grid container justifyContent="center">
                            <Card>
                            <div className="thankyou--wrapper">
                                <div className="thankyou--wrapper--image">
                                <img src={not_found_image} alt="" />
                                <h1 style={{paddingTop:"20px"}}>Aucun détail de transaction trouvé.</h1>
                                </div>
                                </div>
                            </Card>
                        </Grid>
                    ): (
                        <div className="section--booking">
                            <Banner size={"240px"} bg={banner02}>
                                <Container maxWidth="lg" alignItems="center" justify="center">
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        sm={12}
                                        alignItems="center"
                                        justifyContent="center"
                                        className="h-100-100"
                                    >
                                        <div className="tafwija--banner--content">
                                            <h1>Paiement réussi.</h1>
                                        </div>
                                    </Grid>
                                </Container>
                            </Banner>

                            <Container maxWidth="lg" className="p-top-40 p-bottom-40">
                                {
                                    (!Object.keys(order).length || !Object.keys(auth).length) ?
                                        <>
                                            <div> no order </div>
                                        </>
                                        :
                                        <>
                                            <Grid justifyContent="center" container spacing={4}>
                                                <Grid item xs={12} md={10} container>
                                                    <Card>
                                                        <div className="thankyou--wrapper">

                                                            <div className="thankyou--wrapper--image">
                                                                <img src={Success} alt="" style={{width:"300px  ", height:"300px"}}/>
                                                            </div>


                                                            <div className="thankyou--wrapper--title">
                                                                <h2>
                                                                    Merci! Votre paiement a été effectué avec succès.
                                                                </h2>
                                                            </div>

                                                            <div className="thankyou--wrapper--infos">
                                                                <div className="thankyou--wrapper--infos--item">
                                                                    <h6 className="thankyou--wrapper--infos--item--head">
                                                                        NUMÉRO DE TRANSACTION :
                                                                    </h6>
                                                                    <h6 className="thankyou--wrapper--infos--item--value">
                                                                        {transactionDetail?.payment_details?.id ?? order.id ?? "-"}
                                                                    </h6>
                                                                </div>
                                                                <div className="thankyou--wrapper--infos--item">
                                                                    <h6 className="thankyou--wrapper--infos--item--head">
                                                                        E-MAIL :
                                                                    </h6>
                                                                    <h6 className="thankyou--wrapper--infos--item--value">
                                                                        {API.AuthStorage.user.email}
                                                                    </h6>
                                                                </div>
                                                                <div className="thankyou--wrapper--infos--item">
                                                                    <h6 className="thankyou--wrapper--infos--item--head">
                                                                    Total Payé :
                                                                    </h6>
                                                                    <h6 className="thankyou--wrapper--infos--item--value">
                                                                        {API.priceFormat(totalPricePay)}
                                                                    </h6>
                                                                </div>
                                                                <div className="thankyou--wrapper--infos--item">
                                                                    <h6 className="thankyou--wrapper--infos--item--head">
                                                                        Status :
                                                                    </h6>
                                                                    <h6 className="thankyou--wrapper--infos--item--value">
                                                                        {statuses[transactionDetail?.status] ?? "en attente"}
                                                                    </h6>
                                                                </div>

                                                                <div className="thankyou--wrapper--infos--item">
                                                                    <h6 className="thankyou--wrapper--infos--item--head">
                                                                        MOYEN DE PAIEMENT :
                                                                    </h6>
                                                                    <h6 className="thankyou--wrapper--infos--item--value">
                                                                    Carte de crédit{(transactionDetail?.payment_details?.paymentMethod ? ", "+transactionDetail?.payment_details?.paymentMethod : "")}
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Card>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={4} justifyContent="center">
                                                <Grid item xs={12} md={10} container>
                                                    <TransactionDetail
                                                        transactionDetail={transactionDetail}
                                                        orderInfo={ order }
                                                        title={"Montant de la commande"}
                                                        price={API.priceFormat(totalPrice)}
                                                        departCity={order.depart_city}
                                                        carpool={road}
                                                        qte={totalPlace(order.prices)}
                                                        total={API.priceFormat(totalPriceReduc)}
                                                        totalPay={totalPricePay}
                                                        theRest={API.priceFormat(totalPriceRest)}
                                                        zeroPaid={ API.priceFormat( totalPricePay ) === "0,00 MAD" ?  false : true }
                                                        prices={false}
                                                        userDiscount={
                                                            parseInt(order.customer_discount) &&
                                                            { name : 'user' , value : order.customer_discount , price : API.priceFormat( totalPriceReduc - totalPrice ) }
                                                        }
                                                        coupon={
                                                            parseInt( order.coupon_value ) &&
                                                            { value: order.coupon_value , name: order.coupon_name , price : API.priceFormat( totalPriceReduc - totalPrice  ) }
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                        </>
                                }

                            </Container>
                        </div>

                    )
    )
)
}
export default PaymentSuccess;