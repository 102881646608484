import React from "react";
import Button from "../../shared/components/UI/Button/Button";
import Modal from "../../shared/components/UI/Modal";
import Input from "../../shared/components/UI/Input";
import Card from "../../shared/components/UI/Card";
import {
  Container,
  Grid,
  Radio,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@material-ui/core";

import * as API from "../../api";
import { useHistory } from "react-router-dom";
import { Done } from "@material-ui/icons";
//import DateFnsUtils from '@date-io/date-fns';
import moment from "moment";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import "moment/locale/fr";
import { Alert } from "@material-ui/lab";


const ModalEditCancel = ({ shown, close, toMore , order }) => {
  const [ event , setEvent ] = React.useState({});


  const [placesAvailable, setPlacesAvailable] = React.useState( 0 );
  const [prices, setPrices] = React.useState([]);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [selectedTime, setSelectedTime] = React.useState("");

  const [cityStart, setCityStart] = React.useState(null);
  const [roader, setRoader] = React.useState(null);

  const [loadingSettings, setLoadingSettings] = React.useState(false);
  const [loadingUpdating, setLoadingUpdating] = React.useState(false);
  const [settings, setSettings] = React.useState({});
  const [roaders, setRoaders] = React.useState([]);

  const [ orderHelper  , setOrderHelper  ] = React.useState({ type : '' /* Extent 'severity' props from material ui component */ , message : '' });


  const totalPrice = (_prices, undescount = false) => {
    let total = 0;
    let _total = total;
    _prices.forEach((price) => {
      total = total + price.total_price;
    });
    if (undescount) {
      let _coupon =  0;
      _total = total - (total / 100) * _coupon;
    } else {
      _total = total;
    }
    return _total;
  };

  const handleChangeCheckbox = (item) => {
    let exept = prices.filter((p) => p.id != item.id);
    item.status = !item.status;
    item.qt = item.status ? 1 : 0;
    item.total_price = item.price * item.qt;
    setPrices([item, ...exept]);
  };

  const handleChangeQt = (event, item) => {
    //setValueCheckbox(event.target.value);
    let nQt = event.target.value;
    let exept = prices.filter((p) => p.id != item.id);
    item.qt = nQt < 1 ? "" : nQt;
    item.total_price = item.price * item.qt;
    setPrices([item, ...exept]);
  };

  const submitBooking = async () => {
    setOrderHelper( { type : '' , message : '' } );
    setLoadingUpdating(true);

    let form = {
      reservation_id : order.id,
      prices : prices.filter( p => p.qt > 0 )
    };
    let _form;
    if( event.type == "Activitie" ){
      _form = Object.assign( form , { date_activity : selectedDate , time_activity : selectedTime } );
    }
    if( event.type == "Event" ){
      _form = Object.assign( form , { depart_city : cityStart, roader : roader } );
    }

    API.updateOrder( _form )
      .then((res)=>{
        setOrderHelper( { type : 'success' , message : "votre commande a été mise à jour avec succès" } );
        console.log( res );
      })
      .catch((err)=>{ 
        console.log(err);
      })
      .then(()=>{
        setLoadingUpdating(false);
        window.location.reload();
      });

  }


  React.useEffect(()=>{
    if( Object.keys(order).length ){
      
      let event = order.event;
      setEvent( event );
      //setPrices( event.prices );
      //console.log( order );


      setSelectedDate( order.date_depart_activity  );
      setSelectedTime( order.time_activity );

      let pricesList = [];
      event.prices.forEach(ep => {
        let old = order.prices.find(p => p.name === ep.name );  
        if( old ){
          pricesList.push({ ...old , id: ep.id  });
        }else{
          pricesList.push({ ...ep , qt : 0 });
        }
      });
      setPrices( pricesList );

      // do calculat place available
    
      setPlacesAvailable( event.places_available );
      if( !parseInt(order.etat) ){
        let defaultPlaceAvailable = event.places_available ;
        pricesList.forEach(pr => {
          defaultPlaceAvailable -= parseInt(pr.qt) ; 
        });
        setPlacesAvailable( defaultPlaceAvailable );
      }

      //


      setCityStart( order.depart_city );


      // Get Settings Data with Roaders
      setLoadingSettings(true);
      API.Settings()
        .then((res) => {
          //console.log( res );
          setSettings(res.settings);
          setRoaders(res.roaders);
        })
        .then(() => {
          setLoadingSettings(false);
        });
      setRoader( order.roader );


    }
  },[order]);

  React.useEffect(() => {
    //console.log( prices );
    let total_places = 0;
    prices.forEach((price) => {
      if( !parseInt(order.etat) ){
        total_places = parseInt(total_places) + parseInt(price.qt);
      }

    });
    setPlacesAvailable(event.places_available - total_places);
  }, [prices]);

  return (
    <>
      <Modal
        shown={shown}
        close={close}
        size={"modal--large"}
        title="Gérer votre résevation"
        subtitle="Annulez avant le 20 October à 11:00 pour 
        remboursement intégral."
      >
        {orderHelper.message && (
          <div className="auth--wrapper--form--content--item">
            <Alert severity={orderHelper.type}>
              <div
                dangerouslySetInnerHTML={{ __html: orderHelper.message }}
              ></div>
            </Alert>
          </div>
        )}

        <div className="tafwija--modal--card--body--edit section--booking">
        {Object.keys(event).length && (
        <Container maxWidth="lg" className="p-top-40 p-bottom-40" >
          <Grid container spacing={4}>
            <Grid item xs={12} md={8} container>
              <Card>
                <div className="booking--wrapper">
                  <h2> {event.title} </h2>
                  <Grid container spacing={3}>
                    {event.type == "Activitie" ? (
                      <MuiPickersUtilsProvider
                        libInstance={moment}
                        utils={MomentUtils}
                        locale={"fr"}
                      >
                        <Grid item xs={12} sm={ parseInt( event.hour_status ) ? 8 : 12 } className="m-bottom-20">
                          <KeyboardDatePicker
                            disablePast
                            variant={"inline"}
                            disableToolbar
                            inputVariant="outlined"
                            format="DD/MM/yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            label="Choisissez une date"
                            minDate={new Date()}
                            value={selectedDate}
                            onChange={(date) => {
                              setSelectedDate(date);
                            }}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            style={{ width: "100%", marginTop: "0px" , marginBottom: 20}}
                          />
                        </Grid>
                        { parseInt( event.hour_status ) ? (
                        <Grid item xs={12} sm={4} className="m-bottom-20">
                          <FormControl
                            variant="outlined"
                            style={{ width: "100%", marginBottom: 20 }}
                          >
                            <InputLabel id="demo-multiple-name-label">Heure *</InputLabel>
                            <Select
                              labelId="demo-multiple-name-label"
                              id="demo-simple-select-outlined"
                              defaultValue={ order.time_activity }
                              onChange={(event) => {
                                setSelectedTime(event.target.value);
                              }}
                              label="Heure"
                              required
                            >
                              {[
                                "08h00 : 10h00",
                                "10h00 : 12h00",
                                "12h00 : 14h00",
                                "14h00 : 16h00",
                                "16h00 : 18h00",
                                "18h00 : 20h00",
                                "20h00 : 22h00",
                              ].map((item, index) => (
                                <MenuItem key={index} value={item} >
                                  {item}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        )
                        : null 
                        }
                      </MuiPickersUtilsProvider>
                    ) : null}
                    {event.type == "Event" ? (
                      <>
                        <Grid item xs={6} sm={6} md={4}>
                          <div className="booking--wrapper--item">
                            <h1 className="titledark"> Date de départ : </h1>
                            <h2 className="title"> { new Date( event.date_depart ).toLocaleDateString('fr-FR') } </h2>
                          </div>
                        </Grid>
                        <Grid item xs={6} sm={6} md={4}>
                          <div className="booking--wrapper--item">
                            <h1 className="titledark"> Date de fin : </h1>
                            <h2 className="title"> { new Date( event.date_end ).toLocaleDateString('fr-FR') } </h2>
                          </div>
                        </Grid>
                        <Grid item xs={6} sm={6} md={4}>
                          <div className="booking--wrapper--item">
                            <h1 className="titledark">
                              {" "}
                              Places disponibles :{" "}
                            </h1>
                            <h2
                              className="title"
                              style={
                                placesAvailable > 0 ? {} : { color: "red" }
                              }
                            >
                              {placesAvailable}
                            </h2>
                          </div>
                        </Grid>
                      </>
                    ) : null}
                  </Grid>

                  <div className="booking--wrapper--item">
                    <div className="booking--wrapper--item--prices">
                      {prices
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((item) => {
                          //console.log( item )
                          item.total_price = parseFloat( item.price ) * parseInt( item.qt  );
                          return (
                            <div className="booking--wrapper--item--prices--price">
                              <div className="booking--wrapper--item--prices--price--head">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      onChange={() =>
                                        handleChangeCheckbox(item)
                                      }
                                      name="checked"
                                      color="primary"
                                      checked={ item.qt  }
                                    />
                                  }
                                  label={item.name}
                                />
                              </div>
                              <div className="booking--wrapper--item--prices--price--body">
                                <h2 className="price">
                                  <span className="price-label">Prix: </span>
                                  {API.priceFormat(item.price)}
                                </h2>

                                <TextField
                                  type="number"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  variant="outlined"
                                  value={item.qt  }
                                  onChange={(e) => handleChangeQt(e, item)}
                                  disabled={ !item.qt }
                                  InputProps={{ inputProps: { min: 1} }}
                                />

                                <h2 className="price">
                                  <span className="price-label">
                                    Prix Total:{" "}
                                  </span>
                                  {API.priceFormat(item.total_price)}
                                </h2>
                              </div>
                              <div className="booking--wrapper--item--prices--price--footer"></div>
                            </div>
                          );
                        })}
                    </div>
                  </div>

                  {event.type == "Event" ? (
                    <>
                      <div className="booking--wrapper--item">
                        <h2>Ville de départ * </h2>
                        <label>Précisez la ville de votre départ</label>
                        <FormControl
                          variant="filled"
                          className="selectbox--item"
                        >
                          <Select
                            defaultValue={ cityStart }
                            onChange={(e) => {
                              setCityStart(e.target.value);
                            }}
                          >
                            {event.gathering.map((item, index) => {
                              return (
                                <MenuItem key={index} value={item.gathering}   >
                                  {item.gathering}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </div>

                      {event.covoiturage == "1" && (
                        <>
                          {loadingSettings ? (
                            <CircularProgress />
                          ) : (
                            <div className="booking--wrapper--item">
                              <h2>Roader ( Co-voiturage ) * </h2>
                              <label>
                                Précisez si vous êtes motorisé ou pas
                              </label>
                              <FormControl
                                variant="filled"
                                className="selectbox--item"
                              >
                                <Select
                                  defaultValue={ parseInt(order.roader) }
                                  onChange={(e) => {
                                    setRoader(e.target.value);
                                  }}
                                >
                                  {roaders.map((item) => {
                                    return (
                                      <MenuItem key={item.id} value={item.id}>
                                        {item.name}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  ) : null}
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} md={4} container>
              <div className="recap--booking">
                <div className="recap--booking--body">
                  <div className="recap--booking--body--p1">
                    <h3>{event.title}</h3>
                    {/* <h3 className="price">
                      {API.priceFormat(totalPrice(prices))}
                    </h3> */}
                  </div>
                  <div className="recap--booking--body--p2">
                    {cityStart && (
                      <div className="recap--booking--body--p2--line">
                        <h6>Ville de départ:</h6>
                        <p> {cityStart} </p>
                      </div>
                    )}

                    <div className="recap--booking--body--p2--line">
                      <ul>
                        {prices.map((item, index) => {
                          if ( (item.qt  )  > 0)
                            return (
                              <li key={index}>
                                <strong>
                                  {item.qt } {item.name}
                                </strong>
                              </li>
                            );
                        })}
                      </ul>
                    </div>

                    {event.covoiturage == "1" && !loadingSettings && roader ? (
                      <div className="recap--booking--body--p2--line">
                        <h6>Roader ( Co-voiturage ): </h6>
                        <p> {roaders.find((r) => r.id == roader).name} </p>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="recap--booking--body--p3">
                    <h3>Total</h3>
                    <h3 className="price">
                      {API.priceFormat(totalPrice(prices, true))}
                    </h3>
                  </div>
                  
                  
                  {
                  
                  event.percentage_paid != 0 ?  

                    // 100 - event.percentage_paid === 0 ? (
                    //   ""
                    // ) : 
                    loadingSettings ? (
                      <div className="recap--booking--body--p4">
                        <CircularProgress />
                      </div>
                    ) : (
                      <div className="recap--booking--body--p4">
                        <div className="recap--booking--body--p4--total">
                          <p>
                            Avance à payer de{" "}
                            <span style={{ fontSize: 14 }}>
                              {API.priceFormat(
                                totalPrice(prices, true) -
                                  (totalPrice(prices, true) / 100) *
                                    (100 - event.percentage_paid)
                              )}
                            </span>{" "}
                            dans 48h pour finaliser la réservation.
                          </p>
                        </div>
                      </div>
                    )
                  : <></>
                  }

                  {/* {loadingSettings ? (
                    <div className="recap--booking--body--p4">
                      <CircularProgress />
                    </div>
                  ) : (
                    <div className="recap--booking--body--p4">
                      <div className="recap--booking--body--p4--total">
                        <p>
                          Payer un acompte de{" "}
                          <span>
                            {API.priceFormat( totalPrice(prices,true) - (totalPrice(prices,true) / 100) * ( 100 - event.percentage_paid)
                            )}
                          </span>{" "}
                          pour valider cette réservation.
                        </p>
                      </div>
                    </div>
                  )} */}
                </div>
                <div className="recap--booking--footer">
                  <Button
                    buttonStyle={"btn--second"}
                    buttonSize={"btn--large"}
                    onClick={submitBooking}
                    disabled={
                      (placesAvailable < 0 && event.type == "Event") ||
                      (event.type == "Event" && !cityStart) ||
                      ( event.type !== "Event" && parseInt( event.hour_status ) && !selectedTime ) ||
                      !totalPrice(prices) ||
                      (event.covoiturage == "1" && !roader) ||
                      loadingUpdating
                    }
                  > 
                  {
                    loadingUpdating ? (
                      <div className="recap--booking--body--p4">
                        <CircularProgress />
                      </div>
                    ):
                    <>
                      Enregistrer
                    </>
                  }
                    
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
        )}
        </div>
      </Modal>
    </>
  );
};
export default ModalEditCancel;
