import React, { useEffect } from "react";
import Button from "../UI/Button/Button";
import "./style.scss";

const TransactionDetail = ({
  orderInfo = null,
  transactionDetail=null,
  title,
  price,
  departCity,
  carpool,
  date,
  qte,
  total,
  totalPay,
  theRest,
  cardStyle,
  prices = true,
  coupon = {},
  userDiscount = {},
  zeroPaid = true,
}) => {
  const event = orderInfo.event_infos ?? orderInfo.event;
  const [transaction, setTransactionDetail] = React.useState(null);
  function formatDateWithTime(timestamp) {
    const dateObj = new Date(timestamp);
    const date = dateObj.toDateString();
    const hour = String(dateObj.getHours()).padStart(2, '0');
    const minute = String(dateObj.getMinutes()).padStart(2, '0');

    return `${date} ${hour}:${minute}`;
}

  useEffect(() => {
    if (transactionDetail && transactionDetail?.payment_details?.transactions?.length) {
        setTransactionDetail(transactionDetail?.payment_details?.transactions?.[transactionDetail?.payment_details?.transactions?.length - 1]);
    }
    }, []);
  return (
    <div className={`tafwija--checkout--card ${cardStyle}`}>
      <div className="tafwija--checkout--card--body">
        <div className="tafwija--checkout--card--body--p1">
          <h3>{title}</h3>
          <h3 className="price">{price}</h3>
        </div>
        <div className="tafwija--checkout--card--body--p2">
          <div className="tafwija--checkout--card--body--p2--line">
            <h6>Ville de départ:</h6>
            <p>{departCity}</p>
          </div>
          {event.type == "Activitie" ? (
            <></>
          ) : (
            <div className="tafwija--checkout--card--body--p2--line">
              <h6>Roader ( Co-voiturage ): </h6>
              <p>{carpool}</p>
            </div>
          )}

          <div className="tafwija--checkout--card--body--p2--line">
            <h6>Personne(s): </h6>
            <p>{qte}</p>
          </div>
          {event.type == "Activitie" ? (
            <>
              <div className="tafwija--checkout--card--body--p2--line">
                <h6>Date de reservation: </h6>
                <p>{orderInfo.date_activity}</p>
              </div>
              {parseInt(orderInfo.hour_status || event.hour_status) ? (
                <div className="tafwija--checkout--card--body--p2--line">
                  <h6>Créneau: </h6>
                  <p>{orderInfo.time_activity}</p>
                </div>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}

          {event.type == "Event" ? (
            <>
              <div className="tafwija--checkout--card--body--p2--line">
                <h6> Date de départ : </h6>
                <p>{new Date(event.date_depart).toLocaleDateString("fr-FR")}</p>
              </div>
              <div className="tafwija--checkout--card--body--p2--line">
                <h6> Heure de départ : </h6>
                <p>{event.hour_depart}</p>
              </div>
            </>
          ) : (
            ""
          )}

          {prices && (
            <div className="tafwija--checkout--card--body--p2--line">
              <h6>Prix: </h6>
              <p>{prices}</p>
            </div>
          )}
        </div>
        {Object.keys(userDiscount).length && parseInt(userDiscount.value) ? (
          <div className="tafwija--checkout--card--body--p3">
            <h3>Réduc offre bienvenue -{userDiscount.value}% : </h3>
            <h3 className="price"> {userDiscount.price} </h3>
          </div>
        ) : null}

        {Object.keys(coupon).length &&
        parseInt(coupon.value) &&
        !parseInt(userDiscount.value) ? (
          <div className="tafwija--checkout--card--body--p3">
            <h3>Réduc du code promo -{coupon.value}% : </h3>
            <h3 className="price"> {coupon.price} </h3>
          </div>
        ) : null}
        <div className="tafwija--checkout--card--body--p3">
          <h3>Montant Total</h3>
          <h3 className="price">{total}</h3>
        </div>
        {zeroPaid ? (
          <>
            <div className="tafwija--checkout--card--body--p3">
              <h3>à payer sur place</h3>
              <h3 className="price">{theRest}</h3>
            </div>

            <div className="tafwija--checkout--card--body--p3">
              <h3>Total Payé</h3>
              <h3 className="price">{totalPay} {transaction?.currency ?? "MAD"}</h3>
            </div>
          </>
        ) : (
          <>
            <div className="tafwija--checkout--card--body--p3 p-bottom-20">
              <h3>à payer sur place</h3>
              <h3 className="price">{theRest}</h3>
            </div>
          </>
        )}

        { transaction ? (
               <>
               <div className="tafwija--checkout--card--body--p3 p-bottom-20">
                   <h3>la date de transaction</h3>
                   <h3 >{ formatDateWithTime(transaction?.timestamp)}</h3>
               </div>

           </> 
        ) : null
        }

      </div>
    </div>
  );
};

export default TransactionDetail;

// title,price,departCity,carpool,date,qte,total,totalPay
