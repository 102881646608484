import { CheckOutlined, CloseOutlined } from "@material-ui/icons";
import { render } from "@testing-library/react";
import React from "react";

const Before = ({ event }) => {
  return (
    <>

      {event.before_description ? (
        <div className="detail--event--wrapper--features--short">
          <h4> { event.before_title } </h4>
          <div className="valuee detail--event--wrapper--features--short--content">
            <p className="detail--event--wrapper--features--short--content--text">
              { event.before_description }
            </p>
          </div>
        </div>
      ) : (
        ""
      )}

     
    </>
  );
};
export default Before;
