import React from "react";
import { FiX } from "react-icons/fi";

const SIZES = ["modal--small", "modal--medium","modal--large"];

const Modal = ({
  children,
  shown,
  size,
  close,
  modalStyle,
  title,
  subtitle,
  footer,
}) => {
  const modalSize = SIZES.includes(size) ? size : SIZES[1];

  return shown ? (
    <div
      className="tafwija--modal"
      onClick={() => {
        close();
      }}
    >
      <div
        className={`tafwija--modal--card ${modalStyle} ${modalSize}`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="tafwija--modal--card--head">
          <h2 className="tafwija--modal--card--head--title">{title}</h2>
          <p className="tafwija--modal--card--head--subtitle">{subtitle}</p>
          <FiX
            size={20}
            onClick={close}
            className="tafwija--modal--card--head--close"
          />
        </div>
        <div className="tafwija--modal--card--body">{children}</div>
        {footer && 
        <div className="tafwija--modal--card--footer">{footer}</div>
        }
      </div>
    </div>
  ) : null;
};
export default Modal;
