import React from 'react'
import '../style.scss'


const Item = (props) => {
    return (
        <div className="card--item--gal">
            <div className="card--item--gal--image">
                <img src={ props.data.images.find( i => i.default === '1' ).image} alt={props.data.title} />
                <div className="card--item--gal--image--overlay" />
                <div className="card--item--gal--image--bottom">
                    <div className="card--item--gal--image--bottom--title">
                        <h2>{props.data.title}</h2>
                    </div>
                </div>
            </div>
        </div>

    )
}
export default Item