import React from "react";
import { Grid, Container, CircularProgress, Collapse } from "@material-ui/core";

import BookingCard from "../../shared/components/BookingCard";
import CheckoutCard from "../../shared/components/CheckoutCard";
import Title from "../../shared/components/UI/Title";
import Type from "../../shared/components/UI/Type";
import Button from "../../shared/components/UI/Button/Button";
import Status from "../../shared/components/UI/Status";
import ModalEditCancel from "./ModalEditCancel";
import RatingModal from "./RatingModal";
import InfoEventModal from "./InfoEventModal";

import * as API from "../../api";
import { FiClock, FiCheckCircle, FiXOctagon } from "react-icons/fi";
import PaymentContainer from "../../components/PaymentContainer";
import { Alert } from "@material-ui/lab";

const Booking = (props) => {
  const [modalShowInfo, toggleModalShowInfo] = React.useState(false);
  const [modalShowEditCancel, toggleModalShowEditCancel] =
    React.useState(false);
  const [modalShowRating, toggleModalShowRating] = React.useState(false);
  const [loadingUnconfirmedOrders, setLoadingUnconfirmedOrders] =
    React.useState(false);
  const [ordersUnconfirmed, setOrdersUnconfirmed] = React.useState([]);
  const [loadingConfirmedOrders, setLoadingConfirmedOrders] =
    React.useState(false);
  const [ordersConfirmed, setOrdersConfirmed] = React.useState([]);
  const [order, setOrder] = React.useState({});
  const [closeAlert, setCloseAlert] = React.useState([]);

  const loadModalInfo = (order) => {
    setOrder(order);
    toggleModalShowInfo(true);
  };

  const downloadBarcode = async (url) => {
    API.downloadBarcodeImage(url)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "barcode.png"); //
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        link.remove();

        console.log("Download successful");
      })
      .catch((err) => {
        console.error("Error in downloading the image: ", err);
      });
  };

  const loadModalUpdating = (order) => {
    setOrder(order);
    toggleModalShowEditCancel(true);
  };
  const loadModalReview = (order) => {
    setOrder(order);
    toggleModalShowRating(true);
    //console.log( order );
  };

  const closeModalInfo = () => {
    setOrder({});
    toggleModalShowInfo(false);
  };
  const closeModalUpdating = (order) => {
    //setOrder({});
    toggleModalShowEditCancel(false);
  };
  const closeModalReview = (order) => {
    setOrder({});
    toggleModalShowRating(false);
    //console.log( order );
  };

  React.useEffect(() => {
    // Prochaines réservations -> Commond
    setLoadingUnconfirmedOrders(true);
    API.UserOrdersUnconfirmed()
      .then((res) => {
        //console.log( res.data );
        setOrdersUnconfirmed(res.data);
      })
      .catch((err) => {
        //console.log(err);
      })
      .then(() => {
        setLoadingUnconfirmedOrders(false);
      });

    // Réservations passées et annulées
    setLoadingConfirmedOrders(true);
    API.UserOrdersConfirmed()
      .then((res) => {
        //console.log( res.data );
        setOrdersConfirmed(res.data);
      })
      .catch((err) => {
        //console.log(err);
      })
      .then(() => {
        setLoadingConfirmedOrders(false);
      });
  }, [0]);

  const checkPayment = (order) => {
    const style = { marginLeft: "10px" };
    const statuses = {
      AUTHORIZE_PENDING: "En attente",
      AUTHORIZED: "Autorisé",
      AUTH_REVERSED: "Inversement",
      CANCELLED: "Annulée",
      DECLINED: "Refusé",
      CHARGE_PENDING: "Charge en cours",
      CHARGED: "Facturé",
      CHARGED_BACK: "Remboursé",
      CHARGEBACK_REVERSED: "Inversement remboursé",
      CHARGEBACK_PENDING: "Remboursement en attente",
      REFUND_PENDING: "Remboursement en attente",
      REFUNDED: "Remboursé",
      ERROR: "Erreur",
      CREDITED_PENDING: "Crédit en attente",
      CREDITED: "Crédité",
    };

    if (order?.etat === 1) {
      return null; // Hide the button if order.etat is 1
    }

    if (order?.payment_history) {
      const paymentStatus = order.payment_history.status;
      const buttonText = statuses[paymentStatus] || "Payer maintenant";
      const isPaymentProcessed = paymentStatus === "CHARGED";

      return (
        <PaymentContainer
          buttonText={buttonText}
          style={style}
          reservation={order}
          disabled={isPaymentProcessed}
        />
      );
    }

    // If there's no payment history, display the default "Payer maintenant" button
    return (
      <PaymentContainer
        buttonText="Payer maintenant"
        style={style}
        reservation={order}
        disabled={false}
      />
    );
  };

  return (
    <>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12} md={12} container className="p-bottom-20">
          <Title title="Prochaines réservations" mode="medium" />
        </Grid>
        <Grid item xs={12} md={12} container>
          {loadingUnconfirmedOrders ? (
            <CircularProgress />
          ) : (
            <>
              <Collapse in={closeAlert}>
                <Alert
                  severity="info"
                  onClose={() => {
                    setCloseAlert(false);
                  }}
                >
                  Les transactions récentes par carte bancaire peuvent prendre
                  jusqu'à 2 min pour s'afficher correctement.
                </Alert>
              </Collapse>
              {ordersUnconfirmed.length ? (
                ordersUnconfirmed.map((order, index) => {
                  const totalPlaces = API.OrderInfo(order).totalPlaces;
                  const roader = API.OrderInfo(order).roader;
                  const totalPrice = API.OrderInfo(order).totalPrice;
                  const totalPriceByDiscount =
                    API.OrderInfo(order).totalPriceByDiscount;
                  const totalPricePay = API.OrderInfo(order).totalPricePay;
                  const totalPriceRest = API.OrderInfo(order).totalPriceRest;

                  const start = new Date().getTime();
                  const end = new Date(
                    (order.date ?? order.date_depart_activity) + " 12:00 "
                  ).getTime();
                  const diff = end - start;
                  const seconds = Math.floor(diff / 1000);
                  const diff_days = Math.floor(seconds / (24 * (60 * 60)));
                  // let statusTitle = parseInt(order.percentage_paid) > 0 ? "En attente d'avancement" :  "En attente de paiement";
                  let statusTitle = "En attente de paiement";
                  let statusIcon = <FiClock />;
                  let statusType = "pending";
                  if (order.etat == 1) {
                    statusTitle = "Confirmée";
                    statusIcon = <FiCheckCircle />;
                    statusType = "confirmed";
                  }

                  return (
                    <>
                      <BookingCard
                        data={order}
                        key={index}
                        status={
                          <Status
                            title={statusTitle}
                            type={statusType}
                            icon={statusIcon}
                          />
                        }
                        actions={
                          <>
                            <Button
                              disabled={diff_days <= 2 ? true : false}
                              type="submit"
                              buttonStyle={"btn--outline"}
                              onClick={() => {
                                loadModalUpdating(order);
                              }}
                            >
                              Modifier la réservation
                            </Button>
                            {checkPayment(order)}
                          </>
                        }
                        eventType={
                          <Type
                            typeEvent={order.event.type}
                            title={
                              order.event.type === "Activitie"
                                ? "Activité"
                                : "Voyage"
                            }
                          />
                        }
                        infos={
                          <>
                            <CheckoutCard
                              orderInfo={order}
                              coupon={
                                parseInt(order.coupon_value) && {
                                  name: order.coupon_name,
                                  value: order.coupon_value,
                                  price: API.priceFormat(
                                    totalPriceByDiscount - totalPrice
                                  ),
                                }
                              }
                              userDiscount={
                                parseInt(order.customer_discount) && {
                                  name: "user",
                                  value: order.customer_discount,
                                  price: API.priceFormat(
                                    totalPriceByDiscount - totalPrice
                                  ),
                                }
                              }
                              title={order.event.title}
                              price={API.priceFormat(totalPrice)}
                              departCity={order.depart_city}
                              carpool={roader}
                              date={new Date(order.date).toDateString()}
                              qte={totalPlaces}
                              total={API.priceFormat(totalPriceByDiscount)}
                              totalPay={API.priceFormat(totalPricePay)}
                              theRest={API.priceFormat(totalPriceRest)}
                              modePay={parseInt(order.etat) == 1}
                              zeroPaid={
                                API.priceFormat(totalPricePay) === "0,00 MAD"
                                  ? false
                                  : true
                              }
                              checkoutDescription={false}
                              prices={
                                <span>
                                  {order.prices.map((item, index) => {
                                    const total =
                                      parseFloat(item.price) *
                                      (item.qt ?? item.quantities);
                                    return (
                                      <p key={index}>
                                        <span style={{ color: "#999" }}>
                                          {item.name} - &#62;{" "}
                                          {API.priceFormat(item.price)} x{" "}
                                          {item.qt ?? item.quantities}{" "}
                                        </span>{" "}
                                        : {API.priceFormat(total)}
                                      </p>
                                    );
                                  })}
                                </span>
                              }
                            />
                            <div className="p-top-20 text-center">
                              {order.bar_code_image ? (
                                <Button
                                  type="button"
                                  buttonStyle={"btn--primary"}
                                  buttonSize={"btn--small"}
                                  onClick={() => {
                                    downloadBarcode(order.bar_code_image);
                                  }}
                                >
                                    <i className="fa fa-download"></i>
                                  Télécharger votre Ticket
                                </Button>
                              ) : null}

                              <Button
                                style={{ marginLeft: "10px" }}
                                type="button"
                                buttonStyle={"btn--second"}
                                buttonSize={"btn--small"}
                                onClick={() => {
                                  loadModalInfo(order);
                                }}
                              >
                                Afficher Tout
                              </Button>
                            </div>
                          </>
                        }
                      />
                    </>
                  );
                })
              ) : (
                <p>Il n'y a pas encore de données</p>
              )}
            </>
          )}
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        className="p-top-30"
      >
        <Grid item xs={12} md={12} container className="p-bottom-20">
          <Title title="Réservations passées et annulées" mode="medium" />
        </Grid>
        <Grid item xs={12} md={12} container>
          {loadingConfirmedOrders ? (
            <CircularProgress />
          ) : (
            <>
              {ordersConfirmed.length ? (
                ordersConfirmed.map((order) => {
                  const totalPlaces = API.OrderInfo(order).totalPlaces;
                  const roader = API.OrderInfo(order).roader;
                  const totalPrice = API.OrderInfo(order).totalPrice;
                  const totalPriceByDiscount =
                    API.OrderInfo(order).totalPriceByDiscount;
                  const totalPricePay = API.OrderInfo(order).totalPricePay;
                  const totalPriceRest = API.OrderInfo(order).totalPriceRest;

                  return (
                    <>
                      <BookingCard
                        data={order}
                        key={order.id}
                        status={
                          <Status
                            title={
                              parseInt(order.cancel) ? "Annulée" : "Confirmée"
                            }
                            type={
                              parseInt(order.cancel) ? "canceled" : "confirmed"
                            }
                            icon={
                              parseInt(order.cancel) ? (
                                <FiXOctagon />
                              ) : (
                                <FiCheckCircle />
                              )
                            }
                          />
                        }
                        actions={
                          <Button
                            disabled={parseInt(order.cancel)}
                            type="submit"
                            buttonStyle={"btn--outline"}
                            onClick={() => {
                              loadModalReview(order);
                            }}
                          >
                            Donner un avis
                          </Button>
                        }
                        eventType={
                          <Type
                            typeEvent={order.event.type}
                            title={
                              order.event.type === "Activitie"
                                ? "Activité"
                                : "Voyage"
                            }
                          />
                        }
                        infos={
                          <>
                            <CheckoutCard
                              orderInfo={order}
                              coupon={
                                parseInt(order.coupon_value) && {
                                  name: order.coupon_name,
                                  value: order.coupon_value,
                                  price: API.priceFormat(
                                    totalPriceByDiscount - totalPrice
                                  ),
                                }
                              }
                              userDiscount={
                                parseInt(order.customer_discount) && {
                                  name: "user",
                                  value: order.customer_discount,
                                  price: API.priceFormat(
                                    totalPriceByDiscount - totalPrice
                                  ),
                                }
                              }
                              title={order.event.title}
                              price={API.priceFormat(totalPrice)}
                              departCity={order.depart_city}
                              carpool={roader}
                              date={new Date(order.date).toDateString()}
                              qte={totalPlaces}
                              total={API.priceFormat(totalPriceByDiscount)}
                              totalPay={API.priceFormat(totalPricePay)}
                              theRest={API.priceFormat(totalPriceRest)}
                              modePay={parseInt(order.etat) == 1}
                              zeroPaid={
                                API.priceFormat(totalPricePay) === "0,00 MAD"
                                  ? false
                                  : true
                              }
                              checkoutDescription={false}
                              prices={
                                <span>
                                  {order.prices.map((item, index) => {
                                    const total =
                                      parseFloat(item.price) *
                                      (item.qt ?? item.quantities);
                                    return (
                                      <p key={index}>
                                        <span style={{ color: "#999" }}>
                                          {item.name} - &#62;{" "}
                                          {API.priceFormat(item.price)} x{" "}
                                          {item.qt ?? item.quantities}{" "}
                                        </span>{" "}
                                        : {API.priceFormat(total)}
                                      </p>
                                    );
                                  })}
                                </span>
                              }
                              check_in={(order.check_in)}
                              check_out={(order.check_out)}
                            />
                            <div className="p-top-20 text-center">
                              {order.bar_code_image ? (

                                <Button
                                  type="button"
                                  buttonStyle={"btn--primary"}
                                  buttonSize={"btn--small"}
                                  onClick={() => {
                                    downloadBarcode(order.bar_code_image);
                                  }}
                                >
                                    <i className="fa fa-download"></i>
                                  Télécharger votre Ticket
                                </Button>
                              ) : null}

                              <Button
                                style={{ marginLeft: "10px" }}
                                type="button"
                                buttonStyle={"btn--second"}
                                buttonSize={"btn--small"}
                                onClick={() => {
                                  loadModalInfo(order);
                                }}
                              >
                                Afficher Tout
                              </Button>
                            </div>
                          </>
                        }
                      />
                    </>
                  );
                })
              ) : (
                <p>Il n'y a pas encore de données</p>
              )}
            </>
          )}
        </Grid>
      </Grid>

      <InfoEventModal
        order={order}
        shown={modalShowInfo}
        close={closeModalInfo}
      />

      <RatingModal
        order={order}
        shown={modalShowRating}
        close={closeModalReview}
      />
      <ModalEditCancel
        order={order}
        shown={modalShowEditCancel}
        close={closeModalUpdating}
      />
    </>
  );
};
export default Booking;
