import React from "react";

import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "../../UI/Input";
import Button from "../../UI/Button/Button";
import Modal from "../../UI/Modal";
import Link from "../../UI/Link";
import { FiLock, FiMail, FiPhone, FiUser } from "react-icons/fi";
import { FcGoogle } from "react-icons/fc";
import { FaFacebookF } from "react-icons/fa";

import { CircularProgress } from "@material-ui/core";

import * as CONFIG from "../../../../api/config.json";
import * as API from "../../../../api";
import { Alert } from "@material-ui/lab";
import { NavLink, useHistory } from "react-router-dom";

const Register = ({ shown, close, toLogin }) => {
  // const route = useRouteMatch();
  // const provider = route.params.provider;

  const [showForm, setShowForm] = React.useState(false);
  const onClickForm = () => setShowForm(!showForm);

  // const [value, setValue] = React.useState(0);
  const [valueRadio, setValueRadio] = React.useState(null);
  const [valueCheckbox, setValueCheckbox] = React.useState(false);

  // const history = useHistory();
  const [loading, setLoading] = React.useState(false);

  const [loginStatus, setLoginStatus] = React.useState(false);
  const [registerStatus, setRegisterStatus] = React.useState(false);

  const [loginHelper, setLoginHelper] = React.useState({
    type: "" /* Extent 'severity' props from material ui component */,
    message: "",
  });
  const [registerHelper, setRegisterHelper] = React.useState({
    type: "" /* Extent 'severity' props from material ui component */,
    message: "",
  });

  const [nameHelper, setNameHelper] = React.useState("");
  const [emailHelper, setEmailHelper] = React.useState("");
  const [phoneHelper, setPhoneHelper] = React.useState("");
  const [passwordHelper, setPasswordHelper] = React.useState("");
  const [password_confirmationHelper, setPasswordConfirmationHelper] =
    React.useState("");
  const [typeHelper, setTypeHelper] = React.useState("");

  const [ facebookLoginUrl   , setFacebookLoginUrl  ] = React.useState( "" );
  // const [ googleLoginUrl     , setGoogleLoginUrl    ] = React.useState( "" );


  const sublitRegister = (event) => {
    event.preventDefault();
    setLoading(true);
    setLoginHelper({ type: "", message: "" });
    setNameHelper("");
    setEmailHelper("");
    setPhoneHelper("");
    setPasswordHelper("");
    setPasswordConfirmationHelper("");
    setTypeHelper("");
    // setPrivacyHelper("");

    const formData = new FormData(event.target);

    const form = {
      name: formData.get("name"),
      email: formData.get("email"),
      phone: formData.get("phone"),
      password: formData.get("password"),
      password_confirmation: formData.get("password_confirmation"),
      type: "user",
      //privacy_policy          : valueCheckbox ? 'on' : null,
    };
    if (form.password && form.password !== form.password_confirmation) {
      // client hase tiped wrong confirmation password
      setPasswordConfirmationHelper("please check the correct password");
    }
    API.SignUp(form)
      .then((res) => {
        setRegisterStatus(true);
        setRegisterHelper({
          type: "success",
          message:
            "Bienvenue 👋<b>" +
            res.data.user.name +
            "</b> <br/><br/> Vous serez dirigé dans un instant 😃... ",
        });
        event.target.reset();
        setValueRadio(null);
        setValueCheckbox(false);

        setTimeout(async () => {
          // Redirecting after 4 seconds by user type
          //close();
          window.location.href = '/';
        }, 4000);
      })
      .catch((err) => {
        //console.log(err);
        // Validation
        if (err.status === 422) {
          setRegisterHelper({ type: "error", message: err.data.message });
          if (err.data.errors.name) {
            setNameHelper(err.data.errors.name);
          }
          if (err.data.errors.email) {
            setEmailHelper(err.data.errors.email);
          }
          if (err.data.errors.phone) {
            setPhoneHelper(err.data.errors.phone);
          }
          if (err.data.errors.password) {
            setPasswordHelper(err.data.errors.password);
          }
          if (err.data.errors.password_confirmation) {
            setPasswordConfirmationHelper(
              err.data.errors.password_confirmation
            );
          }
          if (err.data.errors.type) {
            setTypeHelper(err.data.errors.type);
          }
        }
        // Unauthorized or user not found
        if (err.status === 401) {
          setRegisterHelper({ type: "success", message: err.data.errors });
          event.target.reset();
          setValueRadio(null);
          setValueCheckbox(false);
        }
        // Server error
        if (err.status === 500) {
          setRegisterHelper({ type: "error", message: "Erreur du serveur" });
          event.target.reset();
          setValueRadio(null);
          setValueCheckbox(false);
        }
      })
      .then(() => {
        // when connection is finish
        setLoading(false);
      });
  };


  React.useEffect(() => {
       
    // get Facebook Link 
    API.socialLoginLink('facebook')
        .then( res => {
            setFacebookLoginUrl( res.url );
        });
    
    // get Google link
    // API.socialLoginLink('google')
    //     .then( res =>{
    //         setGoogleLoginUrl( res.url );
    //     });      
}, []);
  return (
    <>
      <Modal
        shown={shown}
        close={close}
        size={"modal--small"}
        title="S'inscrire à TAFWIJA"
        subtitle="Accéder à notre meilleure sélection d'activités et de voyages"
        footer={
          <>
            <span style={{paddingRight: 8}}>{"Déja client ?  "}</span>{" "}
            <Link
              className="link--btn--one--noPadding"
              to="#"
              onClick={toLogin}
            >
              {" "}
              Connectez-vous
            </Link>
          </>
        }
      >
        <div>
         <a href={facebookLoginUrl} className="btn--facebook m-bottom-10">
            <FaFacebookF size={24} />
            <span>Continuer avec Facebook</span>
          </a> 
             {/*   <a href={googleLoginUrl} className="btn--google">
            <FcGoogle size={24} />
            <span>Continuer avec Google</span>
          </a>
          */}
          <div className="divider--or">Ou</div>  

          <div className="">
          {!showForm && (
              <NavLink
                to="#"
                className="btn--mail m-bottom-10"
                onClick={onClickForm}
              >
                <FiMail size={24} />
                <span>Continuer avec Email inscription</span>
              </NavLink>
            )}  
            { showForm && (
              <form onSubmit={sublitRegister} method="POST" >
              <div>
                {registerHelper.message && (
                  <div className="auth--wrapper--form--content--item">
                    <Alert severity={registerHelper.type}>
                      {" "}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: registerHelper.message,
                        }}
                      ></div>{" "}
                    </Alert>
                  </div>
                )}
                <div className="auth--wrapper--form--content--item">
                  <Input
                    placeholder={"Nom et prénom"}
                    helperText={nameHelper}
                    error
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <FiUser style={{ color: "#B9BFC6" }} />
                        </InputAdornment>
                      ),
                    }}
                    name="name"
                    disabled={loading || registerStatus}
                  />
                </div>
                <div className="auth--wrapper--form--content--item">
                  <Input
                    placeholder={"Votre Email"}
                    helperText={emailHelper}
                    error
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <FiMail style={{ color: "#B9BFC6" }} />
                        </InputAdornment>
                      ),
                    }}
                    type={"email"}
                    name="email"
                    disabled={loading || registerStatus}
                  />
                </div>
                <div className="auth--wrapper--form--content--item">
                  <Input
                    placeholder={"Téléphone"}
                    helperText={phoneHelper}
                    error
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <FiPhone style={{ color: "#B9BFC6" }} />
                        </InputAdornment>
                      ),
                    }}
                    type={"phone"}
                    name="phone"
                    disabled={loading || registerStatus}
                  />
                </div>
                <div className="auth--wrapper--form--content--item">
                  <Input
                    placeholder={"Mot de passe"}
                    type={"password"}
                    helperText={passwordHelper}
                    error
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <FiLock style={{ color: "#B9BFC6" }} />
                        </InputAdornment>
                      ),
                    }}
                    name="password"
                    disabled={loading || registerStatus}
                  />
                </div>
                <div className="auth--wrapper--form--content--item">
                  <Input
                    placeholder={"Confirmer votre mot de passe"}
                    type={"password"}
                    helperText={password_confirmationHelper}
                    error
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <FiLock style={{ color: "#B9BFC6" }} />
                        </InputAdornment>
                      ),
                    }}
                    name="password_confirmation"
                    disabled={loading || registerStatus}
                  />
                </div>
                <div className="auth--wrapper--form--content--item">
                  <Button type="submit" buttonSize={"btn--large"} disabled={loading || loginStatus} >
                    {loading ? (
                      <>
                        <CircularProgress color="white" />
                      </>
                      ) : ( 
                      <> S'inscrire </>
                    )} 
                  </Button>
                </div>
              </div>
              </form>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};
export default Register;
