import React from "react";
import { CircularProgress, Container, Grid } from "@material-ui/core";
import Banner from "../../shared/components/UI/Banner";
import banner02 from "../../shared/assets/images/banner04.jpg";
import not_found from "../../shared/assets/images/not_found_60pq.svg";
// import Carousels from "../../shared/components/UI/Carousel";
import PostsCard from "../../shared/components/PostsCard";
import Title from "../../shared/components/UI/Title";

import * as API from '../../api';
import { useParams } from "react-router-dom";

function Details() {
  const [ post        , setPost        ] = React.useState({});
  const [ loadingPost , setLoadingPost ] = React.useState(false);
  const { id } = useParams();

  React.useEffect(()=>{
    setLoadingPost(true);
    API.Post( id )
      .then((res)=>{
        //console.clear();  
        //console.log(res.data);
        setPost( res.data );

      })
      .catch((err)=>{
        //console.log(err);
      })
      .then(()=>{
        setLoadingPost(false);
      });
  },[0,id]);

  return (
    <div className="section--blog">
      <Banner size={"240px"} bg={banner02}>
        <Container maxWidth="lg" alignItems="center" justify="center">
          <Grid
            container
            item
            xs={12}
            sm={12}
            alignItems="center"
            justifyContent="center"
            className="h-100-100"
          >
            <div className="tafwija--banner--content">
              <h1> {post.title || '' } </h1>
            </div>
          </Grid>
        </Container>
      </Banner>

      <div className="p-top-40">
        {
          loadingPost ?
          <>
            <Container maxWidth="lg">
              <Grid container spacing={2} justifyContent="center" alignItems="center">
                <CircularProgress />
              </Grid>
            </Container>
          </>
          :
          <>
            {
              Object.keys(post).length ?
              <>
                <Container maxWidth="lg">
                  <Grid container spacing={2} justifyContent="center" alignItems="center">
                    <Grid item xs={12} md={12}>
                      <div className="section--blog--image">
                        <img src={post.image} alt="" />
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} justifyContent="center" alignItems="center">
                    <Grid item xs={12} md={12}>
                      <div className="section--blog--content">
                        <div className="section--blog--content--head">
                          <h2> {post.title} </h2>
                          
                        </div>
                        <div className="section--blog--content--description">
                          <div className="content" dangerouslySetInnerHTML={{__html: post.description }}></div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Container>
              </>
              :
              <>
                  <Grid item lg={12} container >
                      <img src={not_found} style={{maxHeight:'400px',margin:'auto'}}  alt=""/>
                  </Grid>
                  <Grid item lg={12}>
                    <Title title={"Oups, élément non trouvé"} />
                    <br />
                    <hr />
                  </Grid>
              </>
            }
          </>
        }
      </div>

      <div className="p-top-40">
        <PostsCard titleSection="Autre articles" />
       </div>
    </div>
  );
}

export default Details;
