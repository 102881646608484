import React from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Button from "../../shared/components/UI/Button/Button";
import Input from "../../shared/components/UI/Input";
import Collapse from "../../shared/components/UI/Collapse";
import Banner from "../../shared/components/UI/Banner";
import banner02 from "../../shared/assets/images/banner04.jpg";
import { useEffect } from "react";
//import Card from '../../shared/components/UI/Card';
//import SelectBox from '../../shared/components/UI/SelectBox';
import {
  // Checkbox,
  CircularProgress,
  // FormControl,
  // FormControlLabel,
  // FormHelperText,
  // IconButton,
  InputAdornment,
  // InputLabel,
  // OutlinedInput,
} from "@material-ui/core";
import {
  EmailOutlined,
  Call,
  LockOutlined,
  PermIdentityOutlined,
  // Search,
} from "@material-ui/icons";
import CheckoutCard from "../../shared/components/CheckoutCard";
import { FaFacebookF } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";

import * as API from "../../api";
import * as CONFIG from "../../api/config.json";
import { Alert } from "@material-ui/lab";
import { Link, useHistory } from "react-router-dom";

const Checkout = () => {
  localStorage.setItem("intended", "/checkout");
  const history = useHistory();
  const [gift] = React.useState(JSON.parse(localStorage.getItem("gift")) || {});
  const [orderBooking] = React.useState(
    JSON.parse(localStorage.getItem("order_booking")) || {}
  );
  //const [ priceForPay  , setPriceForPay   ] = React.useState( parseInt(orderBooking.price_for_pay || 0 )  );
  const [totalPrice, setTotalPrice] = React.useState(orderBooking.price_total);
  const [totalPricePay, setTotalPricePay] = React.useState(
    orderBooking.price_for_pay
  );
  const [totalPriceRest, setTotalPriceRest] = React.useState(
    orderBooking.price_total - orderBooking.price_for_pay
  );

  const [authStatus, setAuthStatus] = React.useState(false);
  const [auth, setAuth] = React.useState({});
  const [loginFormStatus, setLoginFormStatus] = React.useState(false);
  const [signupFormStatus, setSignupFormStatus] = React.useState(false);

  const [loadingLoginStatus, setLoadingLoginStatus] = React.useState(false);
  const [loadingRegisterStatus, setLoadingRegisterStatus] =
    React.useState(false);

  const [loadingOrderingStatus, setLoadingOrderingStatus] =
    React.useState(false);

  const [loginHelper, setLoginHelper] = React.useState({
    type: "" /* Extent 'severity' props from material ui component */,
    message: "",
  });
  const [registerHelper, setRegisterHelper] = React.useState({
    type: "" /* Extent 'severity' props from material ui component */,
    message: "",
  });
  const [orderHelper, setOrderHelper] = React.useState({
    type: "" /* Extent 'severity' props from material ui component */,
    message: "",
  });

  const [nameHelper, setNameHelper] = React.useState("");
  const [phoneHelper, setPhoneHelper] = React.useState("");
  const [emailHelper, setEmailHelper] = React.useState("");
  const [passwordHelper, setPasswordHelper] = React.useState("");
  const [password_confirmationHelper, setPasswordConfirmationHelper] =
    React.useState("");

  const [buttonStatus] = React.useState(true);
  const [loadingAuthCheck, setLoadingAuthCheck] = React.useState(false);
  const [facebookLoginUrl, setFacebookLoginUrl] = React.useState("");
  // const [ googleLoginUrl     , setGoogleLoginUrl    ] = React.useState( "" );
  const [check_in, setCheckIn] = React.useState(
    orderBooking?.sejour_dates?.check_in ?? new Date()
  );
  const [check_out, setCheckOut] = React.useState(
    orderBooking?.sejour_dates?.check_out ?? new Date()
  );

  const changeDiscount = (user = {}) => {
    let _percentageSettings = 100 - orderBooking.event.percentage_paid;
    let _discount =
      Object.keys(user).length && parseInt(user.customer_discount)
        ? parseInt(user.customer_discount)
        : orderBooking.coupon.value ?? 0;
    let _total = totalPrice - (totalPrice / 100) * _discount;
    let _forPay = _total - (_total / 100) * _percentageSettings;
    let _forRest = _total - _forPay;

    setTotalPrice(_total);
    setTotalPricePay(_forPay);
    setTotalPriceRest(_forRest);
  };

  const totalPlace = (_prices) => {
    let places = 0;
    _prices.forEach((price) => {
      places = parseInt(places) + parseInt(price.qt);
    });
    return places;
  };
  const submitLogin = (event) => {
    setLoadingLoginStatus(true);
    setLoginHelper({ type: "", message: "" });
    setEmailHelper("");
    setPasswordHelper("");

    const formData = new FormData(event.target);
    const form = {
      email: formData.get("email"),
      password: formData.get("password"),
    };

    API.SignIn(form)
      .then((res) => {
        setAuthStatus(true);
        setLoginHelper({
          type: "success",
          message: "Bienvenue à nouveau <b>" + res.data.user.name + "</b>",
        });
        event.target.reset();

        setAuth(res.data.user);

        // do change discount if authorized and the have some
        changeDiscount(res.data.user);
        window.location.reload();
      })
      .catch((err) => {
        // Validation
        if (err.status === 422) {
          setLoginHelper({ type: "error", message: err.data.message });
          if (err.data.errors.email) {
            setEmailHelper(err.data.errors.email);
          }
          if (err.data.errors.password) {
            setPasswordHelper(err.data.errors.password);
          }
        }
        // Unauthorized or user not found
        if (err.status === 401) {
          setLoginHelper({ type: "error", message: err.data.errors });
        }
        // Server error
        if (err.status === 500) {
          setLoginHelper({ type: "error", message: "Erreur du serveur" });
        }
      })
      .then(() => {
        // when connection is finish
        setLoadingLoginStatus(false);
      });

    event.preventDefault();
  };

  const submitRegister = (event) => {
    setLoadingRegisterStatus(true);

    setRegisterHelper({ type: "", message: "" });
    setNameHelper("");
    setEmailHelper("");
    setPhoneHelper("");
    setPasswordHelper("");
    setPasswordConfirmationHelper("");

    const formData = new FormData(event.target);

    const form = {
      name: formData.get("name"),
      email: formData.get("email"),
      phone: formData.get("phone"),
      password: formData.get("password"),
      password_confirmation: formData.get("password_confirmation"),
      type: "user",
      privacy_policy: true,
    };

    if (form.password && form.password !== form.password_confirmation) {
      // client hase tiped wrong confirmation password
      setPasswordConfirmationHelper("please check the correct password");
    }

    API.SignUp(form)
      .then((res) => {
        setAuthStatus(true);
        setRegisterHelper({
          type: "success",
          message:
            "Bienvenue <b>" +
            res.data.user.name +
            "</b> <br/><br/> Vous serez dirigé dans un instant ... ",
        });

        event.target.reset();

        setAuth(res.data.user);

        // do change discount if authorized and the have some
        changeDiscount(res.data.user);
        window.location.reload();
      })
      .catch((err) => {
        // Validation
        if (err.status === 422) {
          setRegisterHelper({ type: "error", message: err.data.message });
          if (err.data.errors.name) {
            setNameHelper(err.data.errors.name);
          }
          if (err.data.errors.email) {
            setEmailHelper(err.data.errors.email);
          }
          if (err.data.errors.phone) {
            setPhoneHelper(err.data.errors.phone);
          }
          if (err.data.errors.password) {
            setPasswordHelper(err.data.errors.password);
          }
          if (err.data.errors.password_confirmation) {
            setPasswordConfirmationHelper(
              err.data.errors.password_confirmation
            );
          }
          //if( err.data.errors.type        ){ setTypeHelper(err.data.errors.type) }
        }
        // Unauthorized or user not found
        if (err.status === 401) {
          setRegisterHelper({ type: "error", message: err.data.errors });
        }
        // Server error
        if (err.status === 500) {
          setRegisterHelper({ type: "error", message: "Erreur du serveur" });
        }
      })
      .then(() => {
        // when connection is finish
        setLoadingRegisterStatus(false);
      });

    event.preventDefault();
  };

  const submitOrder = () => {
    setLoadingOrderingStatus(true);
    //history.push('/thankyou');
    let _event = orderBooking.event;
    let _form = {};
    if (_event.type === "Activitie") {
      _form = {
        event_id: _event.id,
        depart_city: "1",
        date_activity: orderBooking.date_activity,
        time_activity: orderBooking.time_activity,
      };
    } else if(_event.type === "Event") {
      _form = {
        event_id: _event.id,
        depart_city: orderBooking.city.trim(),
      };

      if (orderBooking.roader && orderBooking.roader !== 1) {
        Object.assign(_form, { roader: orderBooking.roader });
      } else {
        Object.assign(_form, { roader: 0 });
      }
    } else if (_event.type === "Sejour") {
      _form = {
        event_id: _event.id,
        depart_city: "1",
        check_in: orderBooking?.sejour_dates?.check_in ?? new Date(),
        check_out: orderBooking?.sejour_dates?.check_out ?? new Date(),
      };
    } else {
      _form = {
        event_id: _event.id,
        depart_city: "1",
      };
    }

    orderBooking.prices.forEach((price) => {
      if (price.qt > 0) {
        let _key = `qty_${price.id}_${_event.id}`;
        Object.assign(_form, { [_key]: parseInt(price.qt) });
      }
    });
    if (
      Object.keys(orderBooking.coupon).length &&
      !parseInt(auth.customer_discount)
    ) {
      Object.assign(_form, {
        coupon_name: orderBooking.coupon.name,
        coupon_value: orderBooking.coupon.value,
      });
    }

    if (Object.keys(gift).length && gift.gift_email) {
      Object.assign(_form, gift);
    }

    //console.log( _form );
    //return false;

    API.Order(_form)
      .then((res) => {
        setOrderHelper({ type: "success", message: res.data.message });

        localStorage.removeItem("intended");
        localStorage.removeItem("gift");
        localStorage.removeItem("order");
        localStorage.removeItem("order_booking");
        localStorage.setItem("order_response", JSON.stringify(res.data.data));
        history.push("/thankyou");
      })
      .catch((err) => {
        //setOrderHelper( { type : 'error' ,  message : err.data.message } );
      })
      .then(() => {
        setLoadingOrderingStatus(false);
      });
  };

  React.useEffect(() => {
    //console.log( gift );
    //console.log( orderBooking );

    API.socialLoginLink("facebook").then((res) => {
      setFacebookLoginUrl(res.url);
    });

    // // get Google link
    // API.socialLoginLink('google')
    //     .then( res =>{
    //         setGoogleLoginUrl( res.url );
    //     });

    // if not storage order
    if (!Object.keys(orderBooking).length) {
      history.replace("/");
    }

    // Set title page
    document.title = API.APP_NAME + " - Checkout ";

    setLoadingAuthCheck(true);
    API.Auth()
      .then((res) => {
        setAuthStatus(true);

        // Disable forms cuze the user are authorized
        setLoginFormStatus(false);
        setSignupFormStatus(false);

        // Enable Button submit
        //setButtonStatus(true);

        setAuth(res.user);

        // do change discount if authorized and the have some
        changeDiscount(res.user);
      })
      .catch((err) => {
        // Enable forms cuze the user are not authorized
        setLoginFormStatus(true);
        setSignupFormStatus(true);

        // Disable Button submit cuze the user are not authorized
        //setButtonStatus(false);

        changeDiscount();
      })
      .then(() => {
        setLoadingAuthCheck(false);
      });
  }, [0]);

  return (
    <div className="section--booking">
      <Banner size={"240px"} bg={banner02}>
        <Container maxWidth="lg" alignItems="center" justify="center">
          <Grid
            container
            item
            xs={12}
            sm={12}
            alignItems="center"
            justifyContent="center"
            className="h-100-100"
          >
            <div className="tafwija--banner--content">
              <h1>Finalisation de votre réservation</h1>
              {/* <p>Réservez en deux clicks des expériences inoubliables</p> */}
            </div>
          </Grid>
        </Container>
      </Banner>
      {Object.keys(orderBooking).length && (
        <Container maxWidth="lg" className="p-top-40 m-bottom-40">
          <Grid justifyContent="center" container spacing={4}>
            <Grid
              item
              xs={12}
              md={loginFormStatus && !authStatus ? 6 : 12}
              container
            >
              {(loginFormStatus || signupFormStatus) && !authStatus && (
                <div style={{ width: "inherit" }}>
                  <a
                    href={facebookLoginUrl}
                    className="btn--facebook m-bottom-10"
                  >
                    <FaFacebookF size={24} />
                    <span>Continuer avec Facebook</span>
                  </a>
                  {/* <a
                    href={googleLoginUrl}
                    className="btn--google"
                  >
                    <FcGoogle size={24} />
                    <span>Continuer avec Google</span>
                  </a>*/}
                  <div className="divider--or">Ou</div>
                </div>
              )}
              {loginFormStatus && !authStatus && (
                <>
                  <Collapse
                    onClick={(e) => setSignupFormStatus(!e)}
                    header={
                      <h4 className="">
                        Déjà client ? Cliquez ici pour vous connecter
                      </h4>
                    }
                    children={
                      <Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <div className="auth--wrapper--form">
                            <form
                              onSubmit={submitLogin}
                              method="POST"
                              className="auth--wrapper--form--content"
                            >
                              {loginHelper.message && (
                                <div className="auth--wrapper--form--content--item">
                                  <Alert severity={loginHelper.type}>
                                    {" "}
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: loginHelper.message,
                                      }}
                                    ></div>{" "}
                                  </Alert>
                                </div>
                              )}

                              <div className="auth--wrapper--form--content--item">
                                <Input
                                  placeholder={"Votre Email"}
                                  helperText={emailHelper}
                                  error
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="start">
                                        <EmailOutlined
                                          style={{ color: "#B9BFC6" }}
                                        />
                                      </InputAdornment>
                                    ),
                                  }}
                                  type={"email"}
                                  name="email"
                                  disabled={loadingLoginStatus || authStatus}
                                />
                              </div>
                              <div className="auth--wrapper--form--content--item">
                                <Input
                                  placeholder={"Mot de passe"}
                                  type={"password"}
                                  helperText={passwordHelper}
                                  error
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="start">
                                        <LockOutlined
                                          style={{ color: "#B9BFC6" }}
                                        />
                                      </InputAdornment>
                                    ),
                                  }}
                                  name="password"
                                  disabled={loadingLoginStatus || authStatus}
                                />
                              </div>

                              <div className="auth--wrapper--form--content--item">
                                <div className="auth--wrapper--form--content--item--resetpassword">
                                  <Link to="/reset">
                                    {" "}
                                    Réinitialiser mon mot de passe{" "}
                                  </Link>
                                </div>
                              </div>

                              <div className="auth--wrapper--form--content--item">
                                <Button
                                  type="submit"
                                  buttonSize={"btn--large"}
                                  disabled={loadingLoginStatus || authStatus}
                                >
                                  {loadingLoginStatus ? (
                                    <>
                                      {" "}
                                      <CircularProgress color="white" />{" "}
                                    </>
                                  ) : (
                                    <> Login </>
                                  )}
                                </Button>
                              </div>
                            </form>
                          </div>
                        </Grid>
                      </Grid>
                    }
                  />

                  <div className="divider--or">Inscrition</div>

                  <Collapse
                    onClick={(e) => setSignupFormStatus(!e)}
                    header={
                      <h4 className="">
                        Nouveau ? Cliquez ici pour vous s'inscrire
                      </h4>
                    }
                    children={
                      <div className="auth--wrapper--form">
                        <form
                          onSubmit={submitRegister}
                          method="POST"
                          className="auth--wrapper--form--content"
                        >
                          {registerHelper.message && (
                            <div className="auth--wrapper--form--content--item">
                              <Alert severity={registerHelper.type}>
                                {" "}
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: registerHelper.message,
                                  }}
                                ></div>{" "}
                              </Alert>
                            </div>
                          )}
                          <div className="auth--wrapper--form--content--item">
                            <Input
                              placeholder={"Nom et prénom"}
                              helperText={nameHelper}
                              error
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="start">
                                    <PermIdentityOutlined
                                      style={{ color: "#B9BFC6" }}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                              name="name"
                              disabled={loadingRegisterStatus || authStatus}
                            />
                          </div>
                          <div className="auth--wrapper--form--content--item">
                            <Input
                              placeholder={"Votre Email"}
                              helperText={emailHelper}
                              error
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="start">
                                    <EmailOutlined
                                      style={{ color: "#B9BFC6" }}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                              type={"email"}
                              name="email"
                              disabled={loadingRegisterStatus || authStatus}
                            />
                          </div>
                          <div className="auth--wrapper--form--content--item">
                            <Input
                              placeholder={"Téléphone"}
                              helperText={phoneHelper}
                              error
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="start">
                                    <Call style={{ color: "#B9BFC6" }} />
                                  </InputAdornment>
                                ),
                              }}
                              type={"phone"}
                              name="phone"
                              disabled={loadingRegisterStatus || authStatus}
                            />
                          </div>
                          <div className="auth--wrapper--form--content--item">
                            <Input
                              placeholder={"Mot de passe"}
                              type={"password"}
                              helperText={passwordHelper}
                              error
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="start">
                                    <LockOutlined
                                      style={{ color: "#B9BFC6" }}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                              name="password"
                              disabled={loadingRegisterStatus || authStatus}
                            />
                          </div>
                          <div className="auth--wrapper--form--content--item">
                            <Input
                              placeholder={"Confirmer votre mot de passe"}
                              type={"password"}
                              helperText={password_confirmationHelper}
                              error
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="start">
                                    <LockOutlined
                                      style={{ color: "#B9BFC6" }}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                              name="password_confirmation"
                              disabled={loadingRegisterStatus || authStatus}
                            />
                          </div>
                          <div className="auth--wrapper--form--content--item">
                            <Button
                              type="submit"
                              buttonSize={"btn--large"}
                              disabled={loadingRegisterStatus || authStatus}
                            >
                              {loadingRegisterStatus ? (
                                <>
                                  {" "}
                                  <CircularProgress color="white" />{" "}
                                </>
                              ) : (
                                <> S'inscrire </>
                              )}
                            </Button>
                          </div>
                        </form>
                      </div>
                    }
                  />
                </>
              )}
            </Grid>

            <Grid
              item
              xs={12}
              md={loginFormStatus && !authStatus ? 6 : 8}
              container
            >
              <CheckoutCard
                orderInfo={orderBooking}
                title={orderBooking.event.title}
                // price={ API.priceFormat( orderBooking.event.prices.find(p => p.default === '1' ).price ) }
                price={API.priceFormat(orderBooking.price_total)}
                departCity={orderBooking.city}
                carpool={
                  orderBooking.roader &&
                  orderBooking.roaders.find((r) => r.id === orderBooking.roader)
                    .name
                }
                date={new Date().toLocaleDateString("fr-FR")}
                qte={totalPlace(orderBooking.prices)}
                total={API.priceFormat(totalPrice)}
                totalPay={API.priceFormat(totalPricePay)}
                theRest={API.priceFormat(totalPriceRest)}
                zeroPaid={
                  API.priceFormat(totalPricePay) === "0,00 MAD" ? false : true
                }
                prices={false}
                userDiscount={
                  parseInt(auth.customer_discount) && {
                    name: "user",
                    value: auth.customer_discount,
                    price: API.priceFormat(
                      totalPrice - orderBooking.price_total
                    ),
                  }
                }
                coupon={
                  Object.keys(orderBooking.coupon).length && {
                    value: orderBooking.coupon.value,
                    name: orderBooking.coupon.name,
                    price: API.priceFormat(
                      totalPrice - orderBooking.price_total
                    ),
                  }
                }
                check_in={check_in}
                check_out={check_out}
              >
                <Grid item xs={12} container>
                  {loadingAuthCheck ? (
                    <Grid container justifyContent="center">
                      <CircularProgress />
                    </Grid>
                  ) : (
                    <>
                      {authStatus && buttonStatus ? (
                        <>
                          <div className="tafwija--checkout--button">
                            <Button
                              buttonStyle={"btn--second"}
                              buttonSize={"btn--large"}
                              onClick={submitOrder}
                            >
                              {loadingOrderingStatus ? (
                                <CircularProgress size={28} color="white" />
                              ) : (
                                <> Réserver </>
                              )}
                            </Button>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </Grid>
              </CheckoutCard>

              {!loadingAuthCheck && authStatus && buttonStatus && (
                <span
                  style={{
                    marginTop: "12px",
                    lineHeight: "22px",
                    marginLeft: "19px",
                  }}
                >
                  Merci de patienter et ne pas rafraîchir cette page ça peut
                  prendre jusqu'à 30 sec pour terminer votre commande.
                </span>
              )}

              {Object.keys(orderBooking.coupon).length &&
              orderBooking.coupon.value &&
              !parseInt(auth.customer_discount) ? (
                <Grid container justifyContent="center">
                  <div
                    style={{ width: "100%", margin: "10px 0" }}
                    className="auth--wrapper--form--content--item"
                  >
                    <Alert severity="success">
                      Vous bénéficiez d'une réduction de -{" "}
                      {orderBooking.coupon.value}% pour cette réservation à
                      partir du code promo " {orderBooking.coupon.name} "
                    </Alert>
                  </div>
                </Grid>
              ) : null}

              {authStatus &&
              Object.keys(auth).length &&
              auth.customer_discount &&
              parseInt(auth.customer_discount) > 0 ? (
                <Grid container justifyContent="center">
                  <div
                    style={{ width: "100%", margin: "10px 0" }}
                    className="auth--wrapper--form--content--item"
                  >
                    <Alert severity="success">
                      Félicitations, vous avez une réduction de -
                      {auth.customer_discount}% pour votre première inscription
                      {Object.keys(orderBooking.coupon).length ? (
                        <>
                          {" "}
                          <br /> Et ce code promo ne sera pas une réduction
                          cette fois
                        </>
                      ) : null}
                    </Alert>
                  </div>
                </Grid>
              ) : null}
              {orderHelper.message ? (
                <Grid container justifyContent="center">
                  <div
                    style={{ width: "100%", margin: "10px 0" }}
                    className="auth--wrapper--form--content--item"
                  >
                    <Alert severity={orderHelper.type}>
                      {" "}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: orderHelper.message,
                        }}
                      ></div>{" "}
                    </Alert>
                  </div>
                </Grid>
              ) : null}
            </Grid>
          </Grid>

          <Grid
            container
            spacing={4}
            justifyContent="center"
            className={"p-bottom-40"}
            style={{ display: "none" }}
          >
            <Grid item xs={12} md={8} container>
              <CheckoutCard
                orderInfo={orderBooking}
                title={orderBooking.event.title}
                // price={ API.priceFormat( orderBooking.event.prices.find(p => p.default === '1' ).price ) }
                price={API.priceFormat(orderBooking.price_total)}
                departCity={orderBooking.city}
                carpool={
                  orderBooking.roader &&
                  orderBooking.roaders.find((r) => r.id === orderBooking.roader)
                    .name
                }
                date={new Date().toLocaleDateString("fr-FR")}
                qte={totalPlace(orderBooking.prices)}
                total={API.priceFormat(totalPrice)}
                totalPay={API.priceFormat(totalPricePay)}
                theRest={API.priceFormat(totalPriceRest)}
                zeroPaid={
                  API.priceFormat(totalPricePay) === "0,00 MAD" ? false : true
                }
                prices={false}
                userDiscount={
                  parseInt(auth.customer_discount) && {
                    name: "user",
                    value: auth.customer_discount,
                    price: API.priceFormat(
                      totalPrice - orderBooking.price_total
                    ),
                  }
                }
                coupon={
                  Object.keys(orderBooking.coupon).length && {
                    value: orderBooking.coupon.value,
                    name: orderBooking.coupon.name,
                    price: API.priceFormat(
                      totalPrice - orderBooking.price_total
                    ),
                  }
                }
                check_in={check_in}
                check_out={check_out}
              >
                <Grid item xs={12} container>
                  {loadingAuthCheck ? (
                    <Grid container justifyContent="center">
                      <CircularProgress />
                    </Grid>
                  ) : (
                    <>
                      {authStatus && buttonStatus ? (
                        <>
                          <div className="tafwija--checkout--button">
                            <Button
                              buttonStyle={"btn--second"}
                              buttonSize={"btn--large"}
                              onClick={submitOrder}
                            >
                              {loadingOrderingStatus ? (
                                <CircularProgress size={28} color="white" />
                              ) : (
                                <> Réserver </>
                              )}
                            </Button>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </Grid>
              </CheckoutCard>

              {!loadingAuthCheck && authStatus && buttonStatus && (
                <span
                  style={{
                    marginTop: "12px",
                    lineHeight: "22px",
                    marginLeft: "19px",
                  }}
                >
                  Merci de patienter et ne pas rafraîchir cette page ça peut
                  prendre jusqu'à 30 sec pour terminer votre commande.
                </span>
              )}

              {Object.keys(orderBooking.coupon).length &&
              orderBooking.coupon.value &&
              !parseInt(auth.customer_discount) ? (
                <Grid container justifyContent="center">
                  <div
                    style={{ width: "100%", margin: "10px 0" }}
                    className="auth--wrapper--form--content--item"
                  >
                    <Alert severity="success">
                      Vous bénéficiez d'une réduction de -{" "}
                      {orderBooking.coupon.value}% pour cette réservation à
                      partir du code promo " {orderBooking.coupon.name} "
                    </Alert>
                  </div>
                </Grid>
              ) : null}

              {authStatus &&
              Object.keys(auth).length &&
              auth.customer_discount &&
              parseInt(auth.customer_discount) > 0 ? (
                <Grid container justifyContent="center">
                  <div
                    style={{ width: "100%", margin: "10px 0" }}
                    className="auth--wrapper--form--content--item"
                  >
                    <Alert severity="success">
                      Félicitations, vous avez une réduction de -
                      {auth.customer_discount}% pour votre première inscription
                      {Object.keys(orderBooking.coupon).length ? (
                        <>
                          {" "}
                          <br /> Et ce code promo ne sera pas une réduction
                          cette fois
                        </>
                      ) : null}
                    </Alert>
                  </div>
                </Grid>
              ) : null}
              {orderHelper.message ? (
                <Grid container justifyContent="center">
                  <div
                    style={{ width: "100%", margin: "10px 0" }}
                    className="auth--wrapper--form--content--item"
                  >
                    <Alert severity={orderHelper.type}>
                      {" "}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: orderHelper.message,
                        }}
                      ></div>{" "}
                    </Alert>
                  </div>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Container>
      )}
    </div>
  );
};
export default Checkout;
