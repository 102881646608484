import React from "react";
// import {
//     MuiPickersUtilsProvider,
//     KeyboardTimePicker,
//     KeyboardDatePicker,
// } from '@material-ui/pickers';
import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

// import Container from "@material-ui/core/Container";
import "./style.scss";
import Button from "../UI/Button/Button";
import {
  CircularProgress,
  // FormControl,
  // InputLabel,
  // MenuItem,
  // Select,
} from "@material-ui/core";
// import SearchIcon from "@material-ui/icons/Search";
import * as API from "../../../api";
import { useHistory, useLocation } from "react-router-dom";

const SearchBar = ({ onClick }) => {
  const location = useLocation();
  const history = useHistory();
  //const DATE = new Date();
  //const selectedDate = DATE.getUTCFullYear() +'-'+ DATE.getUTCDay() +'-'+ DATE.getUTCMonth() ;
  // const selectedDate = "2021-01-01";

  // const refCeties = React.createRef();
  // const refCategories = React.createRef();

  const [cities, setCities] = React.useState([]);
  // const [categories, setCategories] = React.useState([]);

  const [loadingCities, setLoadingCities] = React.useState(false);
  // const [loadingCategories, setLoadingCategories] = React.useState(false);
  const [loadingSearch] = React.useState(false);

  const [city, setCity] = React.useState("all");
  // const [category] = React.useState(0);
  // const [dateStart] = React.useState(selectedDate);
  // const [dateEnd] = React.useState(selectedDate);
  const searchSubmit = (event) => {
    //setLoadingSearch(true);

    // let formSearch = {
    //   citie_id: city,
    //   categorie_id: category,
    //   from: dateStart,
    //   to: dateEnd,
    // };

    //history.push(`/search?city=${( city == 'all' ? null : city )}&category=${category}&date_start=${dateStart}&date_end=${dateEnd}`);
    history.push(`/search?city=${city === "all" ? "all" : city}`);
    window.location.reload();
    /*
        API.Search()
            .then((res)=>{
                console.log(res.data);
                
            })
            .catch((err)=>{
                console.log(err);
            })
            .then(()=>{
                setLoadingSearch(false);
            })
        */
    event.preventDefault();
  };

  React.useEffect(() => {
    setLoadingCities(true);
    //setLoadingCategories(true);

    API.CitiesDepart()
      .then((res) => {
        //console.log( res.data );
        setCities(res.data);
      })
      .then(() => {
        setLoadingCities(false);
      });
      /*
    API.Categories()
      .then((res) => {
        //console.log( res.data );
        setCategories(res.data);
      })
      .then(() => {
        setLoadingCategories(false);
      });
      */

    if (location.pathname === "/search" && location.search) {
      const query = new URLSearchParams(location.search);
      setCity(query.get("city"));
      //setCategory(query.get("category"));
      //setDateStart(query.get("date_start"));
      //setDateEnd(query.get("date_end"));
    }
  }, []);

  return (
    <div className="tafwija--searchbar">
      {/* <Container maxWidth="lg"> */}
      <form onSubmit={searchSubmit}>
        <Grid container m={2} alignItems="center">
          <Grid item xs={12} sm={8} md={9} lg={10}>
            {/* <div className="item"> */}
            {/* <label>Villes</label> */}
             <Autocomplete
                  // defaultValue={}
                  // value={city}
                  disabled={loadingCities || loadingSearch}
                  id="combo-box-city"
                  options={cities}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                <TextField
                  {...params}
                  label={"Activités ou départs de cette ville" }
                  variant="outlined"
                  value={city}
                  // ref={refCeties}
                  onChange={(e) => {
                    setCity(e.target.value);
                  }}
                />
              )}
              onChange={(e, options) => {
                if( options ){
                  setCity(options.name);
                }
              }}
            /> 
            {/*
            <FormControl
              variant="filled"
              className="selectbox--item"
              disabled={loadingCities || loadingSearch}
            >
              <InputLabel id="filled-label-cities">
                {loadingCities ? <> Loding ... </> : ""}
              </InputLabel>
              <Select
                ref={refCeties}
                value={city}
                onChange={(e) => {
                  setCity(e.target.value);
                }}
              >
                <MenuItem value="all">
                  {" "}
                  Activités ou départs de cette ville{" "}
                </MenuItem>
                {cities.map((item, i) => (
                  <MenuItem key={i} value={item.name}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            */}
          </Grid>

          <Grid item xs={12} sm={4} md={3} lg={2}>
            <Button
              buttonStyle={
                `button-search ` + (loadingSearch && "btn--disabled")
              }
              buttonSize={'btn--large'}
              style={{ width: '100%'}}
              disabled={loadingSearch}
            >
              {loadingSearch ? (
                <CircularProgress color="white" />
              ) : (
                <>
                  {"Rechercher"}
                  {/* <SearchIcon />{" "} */}
                </>
              )}
            </Button>
            {/* <Button
                  
                  type="submit"
                  disabled={loadingSearch}
                  className={
                    `button-search ` + (loadingSearch && "btn--disabled")
                  }
                >
                  {loadingSearch ? (
                    <CircularProgress color="white" />
                  ) : (
                    <>
                      {"Rechercher"}
                      <SearchIcon />{" "}
                    </>
                  )}
                </Button> */}
            {/* </div> */}
          </Grid>
        </Grid>
      </form>
      {/* </Container> */}
    </div>
  );
};
export default SearchBar;

// {
  /*
    <Grid item xs={12} sm={3} md={3} lg={3} >
        <div className="item date--calendar">
            <label>Date Départ</label>
            <input type="date" value={dateStart} onChange={(e)=>{ setDateStart(e.target.value) }} className="date--calendar--input" disabled={loadingSearch} />
        </div>

    </Grid>
    <Grid item xs={12} sm={3} md={3} lg={3} >
        <div className="item date--calendar">
            <label>Date Fin</label>
            <input type="date" value={dateEnd} onChange={(e)=>{ setDateEnd(e.target.value) }} className="date--calendar--input" disabled={loadingSearch} />
        </div>
    </Grid>
    <Grid item xs={12} sm={3} md={3} lg={2} >
        <div className="item">
        <label>Catégories</label>
            <FormControl variant="filled" className="selectbox--item" disabled={loadingCategories || loadingSearch} >
                <InputLabel id="filled-label-categories">{loadingCategories ? <> Loding ... </> : '' }</InputLabel>
                <Select
                    ref={refCategories}
                    value={category}
                    onChange={(e)=>{ setCategory(e.target.value) }}
                >
                    <MenuItem value='0' > Toutes les catégories </MenuItem>
                    {
                    categories.map((item,i) =>
                    <MenuItem key={i} value={item.id}>{item.name}</MenuItem>
                    )
                    }
                </Select>
            </FormControl>
        </div>
    </Grid>
    */
// }
