import React from "react";
import { useHistory } from "react-router-dom";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import iconBooking from "../../../shared/assets/images/bookicon.png";
import Button from "../../../shared/components/UI/Button/Button";
import * as API from "../../../api";

const Booking = (props) => {
  const history = useHistory();
  const handleBooking = async () => {
    const event = props.event;
    await localStorage.removeItem("gift");
    await localStorage.setItem("order", JSON.stringify(event));
    history.push("/booking");
  };
  return (
    <>
      {/* <div className={"right--box"}> */}
      {/* <div className="right--box--fixed box--sticky"> */}
      <div className="card--booking">
        <div className="card--booking--heading">
          <h6>Meilleur prix garanti</h6>
          <h2>
            <span>À partir de</span> {props.price} <span>Par personne</span>
            {props.priceDescription ? (
              <span>/ {props.priceDescription}</span>
            ) : null}
          </h2>
        </div>
        <div className="card--booking--body">
          <div className="card--booking--body--item">
            <AccessTimeIcon style={{ color: "#053D7F" }} />
            <span>Réservez maintenant, profitez aujourd’hui</span>
          </div>
          <div className="card--booking--body--item">
            <FlashOnIcon style={{ color: "#ffb62c" }} />
            <span>Confirmation immédiate</span>
          </div>
        </div>
        <div className="card--booking--footer">
          <Button
            buttonStyle={"btn--second"}
            buttonSize={"btn--large"}
            onClick={handleBooking}
            disabled={
              (Object.keys(API.AuthStorage).length &&
                API.AuthStorage.user.type === "partner") ||
              props.event.expire
            }
          >
            {props.event.expire ? (
              <>Expired</>
            ) : props.event.type != "Sejour" ? (
              <>Réserver !</>
            ) : (
              <>Choisir mes dates !</>
            )}
          </Button>
        </div>
      </div>
      {/* </div> */}
      {/* </div> */}

      <div className="card--booking--mobile">
        <div className="card--booking--mobile--heading">
          <h6>Meilleur prix garanti</h6>
          <h2>
            <span>À partir de</span> {props.price} <span>Par personne</span>
          </h2>
        </div>
        <div className="card--booking--mobile--body">
          <div className="card--booking--mobile--body--item">
            <AccessTimeIcon style={{ color: "#053D7F" }} />
            <span>Réservez maintenant, profitez aujourd’hui</span>
          </div>
          <div className="card--booking--mobile--body--item">
            <FlashOnIcon style={{ color: "#ffb62c" }} />
            <span>Confirmation immédiate</span>
          </div>
        </div>
      </div>

      <div className="card--booking--mobilebtn">
        <Button
          buttonStyle={"btn--second"}
          buttonSize={"btn--large"}
          onClick={handleBooking}
          disabled={
            (Object.keys(API.AuthStorage).length &&
              API.AuthStorage.user.type === "partner") ||
            props.event.expire
          }
        >
          {props.event.expire ? (
            <>Expired</>
          ) : props.event.type != "Sejour" ? (
            <>Réserver !</>
          ) : (
            <>Choisir mes dates !</>
          )}
        </Button>
      </div>
    </>
  );
};
export default Booking;
