import React from "react";
import { CircularProgress, Container, Grid } from "@material-ui/core";
import Banner from "../../shared/components/UI/Banner";
import banner02 from "../../shared/assets/images/banner04.jpg";
import not_found from "../../shared/assets/images/not_found_60pq.svg";
import Carousels from "../../shared/components/UI/Carousel";
import GalleryCard from "../../shared/components/GalleryCard";
import Title from "../../shared/components/UI/Title";

import * as API from '../../api';
import { useParams } from "react-router-dom";

function Details() {
  const [ gallery        , setGallery        ] = React.useState({});
  const [ loadingGallery , setLoadingGallery ] = React.useState(false);
  const { id } = useParams();

  const youtube_parser = (url) => {
    let regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    let match = url.match(regExp);
    return (match&&match[7].length==11)? match[7] : false;
  }

  React.useEffect(()=>{
    setLoadingGallery(true);
    API.Gallery( id )
      .then((res)=>{
        //console.clear();  
        //console.log(res.data);
        setGallery( res.data );

      })
      .catch((err)=>{
        //console.log(err);
      })
      .then(()=>{
        setLoadingGallery(false);
      });
  },[0,id]);
  return (
    <div className="section--galleries">
      <Banner size={"240px"} bg={banner02}>
        <Container maxWidth="lg" alignItems="center" justify="center">
          <Grid
           container
            item
            xs={12}
            sm={12}
            alignItems="center"
            justifyContent="center"
            className="h-100-100"
          >
            <div className="tafwija--banner--content">
              <h1> { gallery.title || '' }  </h1>
            </div>
          </Grid>
        </Container>
      </Banner>

      <div className="p-top-40">
        {
          loadingGallery ?
          <>
          <Container maxWidth="lg">
              <Grid container spacing={2} justifyContent="center" alignItems="center">
              <CircularProgress />
            </Grid>
          </Container>
          </>
          :
          <>
            {
              Object.keys(gallery).length ?
              <>
                <Container maxWidth="lg">
                  <Grid container spacing={2} justifyContent="center" alignItems="center">
                    { 
                      parseInt(gallery.images_count) ?
                      <Grid item xs={12} md={6}>
                        <div className="section--galleries--images">
                          <Carousels
                            items={gallery.images.map((item, index) => {
                              return (
                                <div key={index} className="section--galleries--images--img">
                                  <img src={item.image} alt="" />
                                </div>
                              );
                            })}
                            NumberOfDesktop={1}
                            showDots={false}
                          />
                        </div>
                      </Grid>
                      :<></>
                    }
                    { 
                      parseInt(gallery.vedios_count) ?
                      <Grid item xs={12} md={6}>
                        <div className="section--galleries--videos">
                          <Carousels
                            items={gallery.vedios.map((video, index) => {
                              return (
                                <div className="section--galleries--videos--img">
                                  <iframe
                                    src={ `https://www.youtube.com/embed/${youtube_parser(video.url)}`}
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen
                                  ></iframe>
                                </div>
                              );
                            })}
                            NumberOfDesktop={1}
                            showDots={false}
                          />
                        </div>
                      </Grid>
                      :<></>
                    }
                  </Grid>

                  <Grid container spacing={2} justifyContent="center" alignItems="center">
                    <Grid item xs={12} md={12}>
                      <div className="section--galleries--content">
                        <div className="section--galleries--content--head">
                          <h2> { gallery.title } </h2>

                          <div className="section--galleries--content--head--category">
                            <h3> { gallery.categorie && gallery.categorie.name } </h3>
                          </div>
                        </div>
                        <div className="section--galleries--content--description" >
                          <div className="content" dangerouslySetInnerHTML={{__html: gallery.description }}></div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Container>
              </>
              :
              <>
                <Grid item lg={12} container >
                      <img src={not_found} style={{maxHeight:'400px',margin:'auto'}} />
                </Grid>
                <Grid item lg={12}>
                  <Title title={"Oups, élément non trouvé"} />
                  <br />
                  <hr />
                </Grid>
              </>
            }
                
          </> 
        }
      </div>


      <div className="p-top-40">
        <GalleryCard titleSection="Autre Albums" />
       </div>
    </div>
  );
}

export default Details;
