import React from "react";
import Button from "../UI/Button/Button";
import "./style.scss";
import * as Api from "../../../api";
const CheckoutCard = ({
  children,
  orderInfo = null,
  title,
  price,
  departCity,
  carpool,
  date,
  qte,
  total,
  totalPay,
  theRest,
  cardStyle,
  checkoutDescription = true,
  modePay = false,
  prices = true,
  coupon = {},
  userDiscount = {},
  zeroPaid = true,
  check_in = false,
  check_out = false,
}) => {
  const event = orderInfo.event_infos ?? orderInfo.event;
  return (
    <div className={`tafwija--checkout--card ${cardStyle}`}>
      <div className="tafwija--checkout--card--body">
        <div className="tafwija--checkout--card--body--p1">
          <h3>{title}</h3>
          <h3 className="price">{price}</h3>
        </div>
        <div className="tafwija--checkout--card--body--p2">
          {event.type != "Sejour" ? (
            <div className="tafwija--checkout--card--body--p2--line">
              <h6>Ville de départ:</h6>
              <p>{departCity}</p>
            </div>
          ) : null}

          {event.type == "Activitie" || event.type == "Sejour" ? (
            <></>
          ) : (
            <div className="tafwija--checkout--card--body--p2--line">
              <h6>Roader ( Co-voiturage ): </h6>
              <p>{carpool}</p>
            </div>
          )}
          {event.type != "Sejour" ? (
            <div className="tafwija--checkout--card--body--p2--line">
              <h6>Personne(s): </h6>
              <p>{qte}</p>
            </div>
          ) : null}

          {event.type == "Activitie" ? (
            <>
              <div className="tafwija--checkout--card--body--p2--line">
                <h6>Date de reservation: </h6>
                <p>{orderInfo.date_activity}</p>
              </div>
              {parseInt(orderInfo.hour_status || event.hour_status) ? (
                <div className="tafwija--checkout--card--body--p2--line">
                  <h6>Créneau: </h6>
                  <p>{orderInfo.time_activity}</p>
                </div>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}

          {event.type == "Event" ? (
            <>
              <div className="tafwija--checkout--card--body--p2--line">
                <h6> Date de départ : </h6>
                <p>{new Date(event.date_depart).toLocaleDateString("fr-FR")}</p>
              </div>
              <div className="tafwija--checkout--card--body--p2--line">
                <h6> Heure de départ : </h6>
                <p>{event.hour_depart}</p>
              </div>
            </>
          ) : (
            ""
          )}

          {event.type == "Sejour" ? (
            <>
              <div className="tafwija--checkout--card--body--p2--line">
                <h6>Check in  :</h6>
                <p>{Api.formatDate(check_in)}</p>
              </div>

              <div className="tafwija--checkout--card--body--p2--line">
                <h6>Check Out :</h6>
                <p>{Api.formatDate(check_out)}</p>
              </div>

              <div className="tafwija--checkout--card--body--p2--line">
                <h6> max {event.capacitie} nombre de guest </h6>
              </div>
            </>
          ) : null}

          {prices && (
            <div className="tafwija--checkout--card--body--p2--line">
              <h6>Prix: </h6>
              <p>{prices}</p>
            </div>
          )}
        </div>
        {Object.keys(userDiscount).length && parseInt(userDiscount.value) ? (
          <div className="tafwija--checkout--card--body--p3">
            <h3>Réduc offre bienvenue -{userDiscount.value}% : </h3>
            <h3 className="price"> {userDiscount.price} </h3>
          </div>
        ) : null}

        {Object.keys(coupon).length &&
        parseInt(coupon.value) &&
        !parseInt(userDiscount.value) ? (
          <div className="tafwija--checkout--card--body--p3">
            <h3>Réduc du code promo -{coupon.value}% : </h3>
            <h3 className="price"> {coupon.price} </h3>
          </div>
        ) : null}
        <div className="tafwija--checkout--card--body--p3">
          <h3>Montant Total</h3>
          <h3 className="price">{total}</h3>
        </div>
        {zeroPaid ? (
          <>
            <div className="tafwija--checkout--card--body--p3">
              <h3>Avance à payer sous 48h</h3>
              <h3 className="price">{totalPay}</h3>
            </div>
            <div className="tafwija--checkout--card--body--p3">
              <h3>à payer sur place</h3>
              <h3 className="price">{theRest}</h3>
            </div>
          </>
        ) : (
          <>
            <div className="tafwija--checkout--card--body--p3 p-bottom-20">
              <h3>à payer sur place</h3>
              <h3 className="price">{theRest}</h3>
            </div>
          </>
        )}

        {zeroPaid
          ? modePay && (
              <div className="tafwija--checkout--card--body--p3">
                <h3>Moyen de paiement</h3>
                <h3 className="price">Virement bancaire</h3>
              </div>
            )
          : ""}

        {children}
        {zeroPaid
          ? checkoutDescription && (
              <div className="tafwija--checkout--card--body--p4">
                <div className="tafwija--checkout--card--body--p4--total">
                  <p>
                    Le paiement de l'avance est nécessaire pour confirmer votre
                    réservation, vous disposez d'un délai maximum de 48 h pour
                    effectuer ce dernier . Au-delà de ce délai votre réservation
                    sera automatiquement annulée.
                    <br />
                    En plus du paiement en ligne, d'autres possibilités de
                    paiement sont détaillées dans le mail de confirmation envoyé
                    à votre adresse mail.
                  </p>
                </div>
              </div>
            )
          : ""}
      </div>
    </div>
  );
};

export default CheckoutCard;

// title,price,departCity,carpool,date,qte,total,totalPay
