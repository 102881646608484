import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './styles.scss';

const getResponsive = ({
  NumberOfMobile,
  NumberOfTablet,
  NumberOfDesktop
}) => ({
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: NumberOfDesktop
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: NumberOfTablet
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: NumberOfMobile
  }
});


const Carousels = ({
  items = [],
  NumberOfMobile = 1,
  NumberOfTablet = 2,
  NumberOfDesktop = 3,
  showDots= true
}) => {
  const config = getResponsive({
    NumberOfMobile,
    NumberOfTablet,
    NumberOfDesktop
  });
  return (
    <Carousel
      NumberOfMobile={NumberOfMobile}
      NumberOfTablet={NumberOfTablet}
      NumberOfDesktop={NumberOfDesktop}
      responsive={config}
      renderButtonGroupOutside={true}
      swipeable={true}
      draggable={true}
      showDots={showDots}
      infinite={true}
      autoPlaySpeed={1000}
      keyBoardControl={true}
      customTransition="all .5"
      transitionDuration={500}
      containerclassName="carousel-container"
      // removeArrowOnDeviceType={['tablet', 'mobile']}
      dotListclassName="custom-dot-list-style"
      itemclassName="carousel-item-padding-40-px"
      arrows
    >
      {items.map((item, index) => (
        <div key={`carousel-${index}`} className="carousel_item ">
          {item}
        </div>
      ))}
    </Carousel>
  );
};
export default Carousels;
