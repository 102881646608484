import React from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "../../UI/Input";
import Button from "../../UI/Button/Button";
import Modal from "../../UI/Modal";
import Link from "../../UI/Link";
import { FiLock , FiMail } from "react-icons/fi";
import { FcGoogle } from "react-icons/fc";
import { FaFacebookF } from "react-icons/fa";

import {
  CircularProgress,
} from "@material-ui/core";

import * as CONFIG from "../../../../api/config.json";
import * as API from "../../../../api";
import { Alert } from "@material-ui/lab";
import { useHistory , useRouteMatch } from "react-router-dom";



const Login = ({shown,close,toRegister}) => {
  
  const route = useRouteMatch();
   const provider = route.params.provider;
  // const [modalShownLogin, toggleModalLogin] = React.useState(false);

  // const [value, setValue] = React.useState(0);
  // const [valueRadio, setValueRadio] = React.useState(null);
  // const [valueCheckbox, setValueCheckbox] = React.useState(false);

  // const history = useHistory();
  const [loading, setLoading] = React.useState(false);

  const [loginStatus, setLoginStatus] = React.useState(false);

  const [loginHelper, setLoginHelper] = React.useState({
    type: "" /* Extent 'severity' props from material ui component */,
    message: "",
  });

  const [emailHelper, setEmailHelper] = React.useState("");
  const [passwordHelper, setPasswordHelper] = React.useState("");

   const [ facebookLoginUrl   , setFacebookLoginUrl  ] = React.useState( "" );
  //  const [ googleLoginUrl     , setGoogleLoginUrl    ] = React.useState( "" );
   const [ loadingLoginSocial , setLoadingLoginSocial] = React.useState( false );


  const submitLogin = (event) => {
    setLoading(true);
    setLoginHelper({ type: "", message: "" });
    setEmailHelper("");
    setPasswordHelper("");

    const formData = new FormData(event.target);
    const form = {
      email: formData.get("email"),
      password: formData.get("password"),
    };

    API.SignIn(form)
      .then((res) => {
        setLoginStatus(true);
        setLoginHelper({
          type: "success",
          message:
            "Bienvenue 👋 <b>" +
            res.data.user.name +
            "</b> <br/><br/> Vous serez dirigé dans un instant 😃...  ",
        });
        event.target.reset();
        
        setTimeout(async () => {
          // Redirecting after 4 seconds by user type
          close();
          window.location.href = '/';
        }, 4000);
        
      })
      .catch((err) => {
        // Validation
        if (err.status === 422) {
          setLoginHelper({ type: "error", message: err.data.message });
          if (err.data.errors.email) {
            setEmailHelper(err.data.errors.email);
          }
          if (err.data.errors.password) {
            setPasswordHelper(err.data.errors.password);
          }
        }
        // Unauthorized or user not found
        if (err.status === 401) {
          setLoginHelper({ type: "error", message: err.data.errors });
          event.target.reset();
        }
        // Server error
        if (err.status === 500) {
          setLoginHelper({ type: "error", message: "Erreur du serveur" });
          event.target.reset();
        }
      })
      .then(() => {
        // when connection is finish
        setLoading(false);
      });

    event.preventDefault();
  };



  
  React.useEffect(() => {
       
        // get Facebook Link 
        API.socialLoginLink('facebook')
            .then( res => {
                setFacebookLoginUrl( res.url );
            });
        
        // get Google link
        // API.socialLoginLink('google')
        //     .then( res =>{
        //         setGoogleLoginUrl( res.url );
        //     });      
  }, []);
  
  return (
    <>
       <Modal
        shown={shown}
        close={close}
        size={"modal--small"}
        title="Se connecter à TAFWIJA"
        subtitle="Se connecter pour accéder à notre meilleure sélection d'activités et de voyages"
        footer={
          <>
           <span style={{paddingRight: 8}}>{"Nouveau ?"}</span> <Link className="link--btn--one--noPadding" to="#" onClick={toRegister}> Créer un compte ?</Link>
          </>
        }
      >
        <div>
         <a href={facebookLoginUrl} className="btn--facebook m-bottom-10">
            <FaFacebookF size={24} />
            <span>Continuer avec Facebook</span>
          </a>
           {/* <a href={googleLoginUrl} className="btn--google">
            <FcGoogle size={24} />
            <span>Continuer avec Google</span>
          </a>
          */}

          <div className="divider--or">Ou</div> 
          <form onSubmit={submitLogin} method="POST" >
            <div className="">
              {loginHelper.message && (
                <div className="auth--wrapper--form--content--item">
                  <Alert severity={loginHelper.type}>
                    <div dangerouslySetInnerHTML={{ __html: loginHelper.message }} ></div>
                  </Alert>
                </div>
              )}
              <div className="auth--wrapper--form--content--item">
                <Input  
                  placeholder={"Votre Email"}
                  helperText={emailHelper}
                  error
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <FiMail style={{ color: "#B9BFC6" }} />
                      </InputAdornment>
                    ),
                  }}
                  type={"email"}
                  name="email"
                  disabled={loading || loginStatus}
                />
              </div>
              <div className="auth--wrapper--form--content--item">
                <Input
                  placeholder={"Mot de passe"}
                  type={"password"}
                  helperText={passwordHelper}
                  error
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <FiLock style={{ color: "#B9BFC6" }} />
                      </InputAdornment>
                    ),
                  }}
                  name="password"
                  disabled={loading || loginStatus}
                />
              </div>
              <div className="auth--wrapper--form--content--item">
                <div className="auth--wrapper--form--content--item--resetpassword">
                  <a href="/reset"> Réinitialiser mon mot de passe ? </a>
                </div>
              </div>
              <div className="auth--wrapper--form--content--item">
                <Button type="submit" buttonSize={"btn--large"} disabled={loading || loginStatus} >
                  {loading ? (
                    <>
                      <CircularProgress color="white" />
                    </>
                    ) : ( 
                    <> S'identifier </>
                  )} 
                </Button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}
export default Login;
