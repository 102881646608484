import React from "react";
import { FiSlash, FiUser } from "react-icons/fi";
import Button from "../../shared/components/UI/Button/Button";
import Modal from "../../shared/components/UI/Modal";
import Radio from "@material-ui/core/Radio";
import { CircularProgress, FormControlLabel } from "@material-ui/core";
import Input from "../../shared/components/UI/Input";
import * as API from "../../api";
import { Alert } from "@material-ui/lab";
import moment from "moment";

const DATA = [
  {
    id: 1,
    title: "Mon séjour est annulé",
  },
  {
    id: 2,
    title: "J’ai trouvé moins cher",
  },
  {
    id: 3,
    title: "Je n’ai pas reçu la confirmation à temps",
  },
  {
    id: 4,
    title: "Mes préférences n’étaient pas disponibles",
  },
  {
    id: 5,
    title: "Je n’ai pas réservé pour la bonne date",
  },
  {
    id: 6,
    title: "Je n’ai pas réservé pour le bon nombre de participants",
  },
];

const CancelModal = ({ shown, close, toMore , order }) => {
  const [showDetails, setShowDetails] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState([0]);

  const [ cancelHelper  , setCancelHelper  ] = React.useState({ type : '' /* Extent 'severity' props from material ui component */ , message : '' });
  const [ loadingCancel , setLoadingCancel ] = React.useState(false);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const submitCanceling = (event) =>{
    event.preventDefault(); 
    
    let formData = new FormData( document.getElementById("form-cancel-order") );
    let _form = {
      reservation_id : order.id,
      cancel : 1 ,
      reason_cancel : formData.get('whyCancel')
    }

    setLoadingCancel(true);
    API.CancelOrder( _form )
        .then((res)=>{
            setCancelHelper( { type : 'success' , message : "votre commande a été annulée avec succès" } );
        })
        .catch((err)=>{
            setCancelHelper( { type : 'error' , message : err.data.errors } );
            console.log(err);
        })  
        .then(()=>{
            setLoadingCancel(false);
            setTimeout(()=>{
              window.location.reload();
            },2000);
        });
  }

  return (
    <>
      <Modal
        shown={shown}
        close={close}
        size={"modal--small"}
        title="Gérer votre résevation"
        subtitle={`Annulez avant le ${moment( (order.date ?? order.date_depart_activity)+' 12:00' ).format('LL à LT')} pour remboursement intégral.`}
        
      >
        {cancelHelper.message && (
          <div className="auth--wrapper--form--content--item">
            <Alert severity={cancelHelper.type}>
              <div
                dangerouslySetInnerHTML={{ __html: cancelHelper.message }}
              ></div>
            </Alert>
          </div>
        )}
        <form onSubmit={submitCanceling} method="POST" id="form-cancel-order" >
          <div className="tafwija--modal--card--body--cancel">
            {DATA.map((value) => {
              const labelId = `checkbox-list-label-${value.id}`;
              return (
                <FormControlLabel
                  value={value.title}
                  control={
                    <Radio
                      checked={selectedValue === value.title}
                      onChange={handleChange}
                      value={value.title}
                      name="whyCancel"
                      inputProps={{ "aria-labelledby": labelId }}
                      onClick={() => setShowDetails(false)}
                      disabled={loadingCancel}
                    />
                  }
                  label={value.title}
                />
              );
            })}
            <FormControlLabel
              value={"Autre raison"}
              control={
                <Radio
                  checked={selectedValue === "Autre raison"}
                  onChange={handleChange}
                  value={"Autre raison"}
                  inputProps={{ "aria-labelledby": 7 }}
                  onClick={() => setShowDetails(!showDetails)}
                  disabled={loadingCancel}
                />
              }
              label={"Autre raison"}
              
            />

            {showDetails && (
              <div className="auth--wrapper--form--content--item">
                <Input
                  placeholder={"Autre raison"}
                  helperText={""}
                  error
                  name="whyCancel"
                  disabled={loadingCancel}
                  required={ showDetails }
                />
              </div>
            )}
          </div>

          <Button
            type="submit"
            buttonStyle={"btn--outline"}
            buttonSize="btn--medium--fullwidth"
            custom="btn--custom"
            disabled={loadingCancel}
          >
              {loadingCancel ? (
                <>
                  <CircularProgress color="white" />
                </>
              ) : (
                <>
                  <span className="btn--custom--icon">
                    <FiSlash size={22} />
                  </span>
                  Annuler ma réservation
                </>
              )}
          </Button>
          
        </form>
      </Modal>
    </>
  );
};
export default CancelModal;
