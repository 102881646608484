import React from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Button from "../../shared/components/UI/Button/Button";
import Banner from "../../shared/components/UI/Banner";
import Card from "../../shared/components/UI/Card";
import banner02 from "../../shared/assets/images/banner04.jpg";
import CheckoutCard from "../../shared/components/CheckoutCard";
import Success from "../../shared/assets/images/success-t.svg";

import * as API from "../../api";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import PaymentContainer from "../../components/PaymentContainer";
const Thankyou = () => {
  const history = useHistory();

  const [settings, setSettings] = React.useState({});

  const order = JSON.parse(localStorage.getItem("order_response")) || {};
  const auth  = API.AuthStorage ;

  const [totalPrice, setTotalPrice] = React.useState(0);
  const [totalPriceReduc, setTotalPriceReduc] = React.useState(0);
  const [totalPricePay, setTotalPricePay] = React.useState(0);
  const [totalPriceRest, setTotalPriceRest] = React.useState(0);
  const [preparingPayment, setPreparingPayment] =
  React.useState(false);

  const [road, setRoad] = React.useState(null);

  const totalPlace = (_prices) => {
    let places = 0;
    _prices.forEach((price) => {
      places = parseInt(places) + parseInt( price.qt ?? price.quantities);
    });
    return places;
  };

  const calcPrices = (order) => {
    let _totalPrice = 0;
    order.prices.forEach((price) => {
      let p = parseInt(price.price) * parseInt( price.qt ?? price.quantities);
      _totalPrice = _totalPrice + p;
    });

    let _percentageSettings = 100 - order.percentage_paid;
    let _total   = _totalPrice - (_totalPrice / 100) * ( parseInt(order.coupon_value) ? parseInt(order.coupon_value) : parseInt(order.customer_discount) ) ;
    let _forPay  = _total - (_total / 100) * _percentageSettings;
    let _forRest = _total - _forPay;
    setTotalPrice(_totalPrice);
    setTotalPriceReduc(_total);
    setTotalPricePay(_forPay);
    setTotalPriceRest(_forRest);
  };

  const roaders = [
    { id: 1, value: 0, name: "Je ne suis pas motorisé" },
    { id: 2, value: 3, name: "Je suis motorisé, j’ai 3 places libres" },
    { id: 3, value: 2, name: "Je suis motorisé, j’ai 2 places libres" },
    { id: 4, value: 1, name: "Je suis motorisé, j’ai une place libres" },
    { id: 5, value: 0, name: "Je suis motorisé, je n’ai aucune place libre" },
  ];
  const goHome = () => {
    history.push("/user/edit");
  };




  React.useEffect(() => {

    // if not storage order or auth
    if( !Object.keys(order).length || !Object.keys(auth).length ){
      history.replace('/')
    }

    API.Settings().then((res) => {
      setSettings(res.settings);
      // console.log(res.settings);
    });

    calcPrices(order);
    if (roaders.some((r) => r.id == parseInt(order.roader))) {
      setRoad(roaders.find((r) => r.id == parseInt(order.roader)).name);
    }
    localStorage.removeItem('intended');
  }, [0]);
  return (
    <div className="section--booking">
      <Banner size={"240px"} bg={banner02}>
        <Container maxWidth="lg" alignItems="center" justify="center">
          <Grid
            container
            item
            xs={12}
            sm={12}
            alignItems="center"
            justifyContent="center"
            className="h-100-100"
          >
            <div className="tafwija--banner--content">
              <h1>Finalisation de votre réservation</h1>
              {/* <p>
                Réservez en deux clicks des expériences inoubliables
              </p> */}
            </div>
          </Grid>
        </Container>
      </Banner>

      <Container maxWidth="lg" className="p-top-40 p-bottom-40">
        {
          ( !Object.keys(order).length || !Object.keys(auth).length ) ?
          <>
            <div> no order </div>
          </>
          :
          <>
            <Grid justifyContent="center" container spacing={4}>
              <Grid item xs={12} md={10} container>
                <Card>
                  <div className="thankyou--wrapper">

                    <div className="thankyou--wrapper--image">
                      <img src={Success} alt="" />
                    </div>

                      {API.priceFormat( totalPricePay ) !== "0,00 MAD" ?
                      // order.type === "Event" ?
                        <div className="thankyou--wrapper--title">
                          <h2>
                            Merci. Réservation effectuée - Paiement d'avance nécessaire.
                          </h2>
                          <p>
                            Un e-mail récapitulatif envoyé à {" "}
                            <a href={API.AuthStorage.user.email}>{API.AuthStorage.user.email}</a>
                          </p>
                        </div>
                      :
                      <div className="thankyou--wrapper--title">
                        <h2>
                          Merci. Votre réservation a été effectuée.
                        </h2>
                        <p>
                          Un e-mail récapitulatif envoyé à {" "}
                          <a href={API.AuthStorage.user.email}>{API.AuthStorage.user.email}</a>
                        </p>
                      </div>
                      }

              {API.priceFormat( totalPricePay ) !== "0,00 MAD" ? (
                 <div className="tafwija--checkout--button">
                   <Grid container justifyContent="center">
                      <PaymentContainer  buttonText="Payer maintenant"/>
                    </Grid>
                </div>
                ): null}

                    <div className="thankyou--wrapper--infos">
                      <div className="thankyou--wrapper--infos--item">
                        <h6 className="thankyou--wrapper--infos--item--head">
                          NUMÉRO DE COMMANDE :
                        </h6>
                        <h6 className="thankyou--wrapper--infos--item--value">
                          {order.id}
                        </h6>
                      </div>
                      {/* <div className="thankyou--wrapper--infos--item">
                        <h6 className="thankyou--wrapper--infos--item--head">
                          DATE :
                        </h6>
                        <h6 className="thankyou--wrapper--infos--item--value">
                          {new Date(order.created_at).toDateString()}
                        </h6>
                      </div> */}
                      <div className="thankyou--wrapper--infos--item">
                        <h6 className="thankyou--wrapper--infos--item--head">
                          E-MAIL :
                        </h6>
                        <h6 className="thankyou--wrapper--infos--item--value">
                          {API.AuthStorage.user.email}
                        </h6>
                      </div>
                      <div className="thankyou--wrapper--infos--item">
                        <h6 className="thankyou--wrapper--infos--item--head">
                          TOTAL :
                        </h6>
                        <h6 className="thankyou--wrapper--infos--item--value">
                          {API.priceFormat(totalPrice)}
                        </h6>
                      </div>
                      <div className="thankyou--wrapper--infos--item">
                        <h6 className="thankyou--wrapper--infos--item--head">
                          MOYEN DE PAIEMENT :
                        </h6>
                        <h6 className="thankyou--wrapper--infos--item--value">
                          { API.priceFormat( totalPricePay ) == 0 ? "Virement bancaire" : "à Payer sur Place"}
                        </h6>
                      </div>
                    </div>
                  </div>
                </Card>
              </Grid>
            </Grid>
            <Grid container spacing={4} justifyContent="center">
              <Grid item xs={12} md={10} container>
                <CheckoutCard
                  orderInfo={ order }
                  title={"Montant de la commande"}
                  price={API.priceFormat(totalPrice)}
                  departCity={order.depart_city}
                  carpool={road}
                  date={new Date(order.created_at).toDateString()}
                  qte={totalPlace(order.prices)}
                  total={API.priceFormat(totalPriceReduc)}
                  totalPay={API.priceFormat(totalPricePay)}
                  theRest={API.priceFormat(totalPriceRest)}
                  zeroPaid={ API.priceFormat( totalPricePay ) === "0,00 MAD" ?  false : true }
                  prices={false}
                  check_in={order.check_in}
                  check_out={order.check_out}


                  userDiscount={
                    parseInt(order.customer_discount) &&
                    { name : 'user' , value : order.customer_discount , price : API.priceFormat( totalPriceReduc - totalPrice ) }
                  }
                  coupon={
                      parseInt( order.coupon_value ) &&
                      { value: order.coupon_value , name: order.coupon_name , price : API.priceFormat( totalPriceReduc - totalPrice  ) }
                  }


                />
              {API.priceFormat( totalPricePay ) == 0 ?
                <div className="p-top-30 p-bottom-30 w-100-100 info--bank">
                  <Card>
                      <h2>Nos coordonnées bancaires</h2>
                      <h3><span>Nom de la banque:</span> {settings.bank_name}</h3>
                      <h3><span>Titulaire du compte:</span> {settings.full_name}</h3>
                      <h3><span>RIB:</span> {settings.rib}</h3>
                  </Card>
                </div>
                : '' }
                {/* <div className="tafwija--checkout--button">
                  <Button
                    buttonStyle={"btn--second"}
                    buttonSize={"btn--max"}
                    onClick={goHome}
                  >
                    Compléter mes informations !
                  </Button>
                </div> */}
              </Grid>
            </Grid>
          </>
        }

      </Container>
    </div>
  );
};
export default Thankyou;
