import { Card, Grid } from "@material-ui/core";
import Canceled from "../../shared/assets/images/payment-canceled.svg";
import Link from "../../shared/components/UI/Link";



const PaymentCanceled = () => {
    return (
        <Grid container justifyContent="center">
        <div className="thankyou--wrapper" style={{ height: "400px", paddingTop: "20px" }}>
            <div className="thankyou--wrapper--image">
            <img src={Canceled} alt="" style={{width : "30vh", height : "30vh"}}/>
            <h1 style={{paddingTop:"20px"}}>Le paiement a été annulé. Veuillez réessayer.</h1>
            <div className="tafwija--checkout--button">
                                    <Grid container justifyContent="center">
                                        <Link
                                            linkStyle={"link--btn--three"}
                                            linkSize={"link--btn--medium"}
                                            to={`/account/orders`}
                                            style={{ width: "58vh" }}
                                        >
                                            <span>Mes Réservations</span>
                                        </Link>
                                    </Grid>
                                </div>
            </div>
            </div>
    </Grid>
    )
}

export default PaymentCanceled;