import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Button from '../../shared/components/UI/Button/Button';
import MaskedInput from 'react-text-mask';

import { Card, CardContent, CircularProgress, FormControl, FormHelperText, Grid, Input, InputAdornment, InputLabel, TextField } from "@material-ui/core";

import * as API from "../../api";
import list from "../../api/list.json";

import { useSnackbar } from 'notistack';
import { useHistory, useLocation } from "react-router-dom";
import { Autocomplete } from "@material-ui/lab";
import { keys } from "lodash";
import { AccountCircle } from "@material-ui/icons";


function TextPhoneMaskCustom(props) {
    const { inputRef, ...other } = props;
    console.log( props );
    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={['(','+', /\d/ , /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/,/\d/,/\d/]}
        //mask={['(','+', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/,/\d/,/\d/]}
        //mask={['(',/[0]/, /[6|7]/, ')', ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ' , /\d/, /\d/, ' ' ,/\d/,/\d/]}
        placeholderChar={'\u2000'}
        //placeholderChar={''}
        // placeholder="Entrez un numéro de téléphone avec le code pays"
        showMask={true}
        guide={true}
      />
    );
}

function TextCodeMaskCustom(props) {
    const { inputRef, ...other } = props;
  
    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[/\d/,' ','-',' ', /\d/,' ','-',' ', /\d/,' ','-',' ', /\d/,]}
        placeholderChar={'\u2000'}
        //placeholderChar={''}
        placeholder="Entrez le code de verification"
        // showMask={true}
        // guide={true}
      />
    );
}
  

const PhoneConferm = (props) => {
    const location = useLocation();
    const history = useHistory();
    const redirection = new URLSearchParams(location.search).get("redirect");
    const { enqueueSnackbar } = useSnackbar();
    const   maxRequest        = 4 ;
    const [ countPhoneSend  , setCountPhoneSend ] = React.useState(0);
    const [ countCodeSend   , setCountCodeSend  ] = React.useState(0);
    
    const [ page            , setPage           ] = React.useState('phone'); // 'phone' or 'conferm'
    const [ loading         , setLoading        ] = React.useState(false);
    const [ phoneNumber     , setPhoneNumber    ] = React.useState(null);
    const [ phoneAccepted   , setPhoneAccepted  ] = React.useState(false);
    const [ phoneHelper     , setPhoneHelper    ] = React.useState('');
    const [ codeHelper      , setCodeHelper     ] = React.useState('');
    const [ countdown       , setCountdown      ] = React.useState( 0 );
    const [ resetCode       , setResetCode      ] = React.useState( false );

    const [ isoCode         , setIsoCode        ] = React.useState( '+212' );


    const handleChange = (event) => {
        /*
        setValues({
          ...values,
          [event.target.name]: event.target.value,
        });
        */
      };
    
    const submitPhone = (event) =>{
        event.preventDefault();
        setPhoneHelper('');


        const formData = new FormData( event.target );
        //formData.set('phone', '000' );
        formData.set('phone', formData.get('phone').replace(/\(|\)|-|\s/g,'') );
        setPhoneNumber( formData.get('phone') );

        setCountPhoneSend( countPhoneSend + 1 );
        if( countPhoneSend > maxRequest ){
            setPhoneHelper("Vous avez dépassé la limite d'opérations, réessayez plus tard");
        }else{
            setLoading(true);
            API.SignNumber( formData )
                .then((res)=>{
                    //console.log( res );
                    setPage( 'conferm' );
                    event.target.reset();
                })
                .catch((err)=>{
                    if( err.status == 422 ){ // error validation
                        setPhoneHelper( err.data.errors.phone );
                    }else if( err.status == 500 ){ // error validation
                        setPhoneHelper( "Erreur du serveur, vous pouvez réessayer plus tard" );
                    }else{
                        setPhoneHelper( err.data.errors );
                    }
                })
                .then(()=>{
                    setLoading(false);
                })
        }
        
    }

    const submitCode = (event) =>{
        event.preventDefault();
        setCodeHelper('');


        const formData = new FormData( event.target );
        //formData.set('phone', '000' );
        //formData.set('phone', formData.get('phone').replace(/\(|\)|-|\s/g,'') );
        formData.set('verification_code', formData.get('verification_code').replace(/\(|\)|-|\s/g,'') );
        
        setCountCodeSend( countCodeSend + 1 );
        if( countCodeSend > maxRequest ){
            setCodeHelper("Vous avez dépassé la limite d'opérations, réessayez plus tard");
        }else{
            setLoading(true);
            API.ConfermNumber( formData )
                .then((res)=>{
                    //console.log( res );
                    history.push( redirection ? `/${redirection}` : 'edit' );
                    setPage( 'phone' );
                    event.target.reset();
                    enqueueSnackbar('Le numéro de téléphone a été mis à jour avec succès', { variant : 'success' });
                    
                })
                .catch((err)=>{
                    
                    if( err.status == 422 ){ // error validation
                        setCodeHelper( err.data.errors.verification_code );
                    }else if( err.status == 500 ){ // error validation
                        setCodeHelper( "Erreur du serveur, vous pouvez réessayer plus tard" );
                    }else{
                        setCodeHelper( err.data.errors );
                    }
                    
                    //console.log(err);
                })
                .then(()=>{
                    setLoading(false);
                })
        }
        
    }

    const ProgressCountdown = (timeleft) => {
        setResetCode(false);
        return new Promise((resolve, reject) => {
          var countdownTimer = setInterval(() => {
            timeleft--;
            
            setCountdown( timeleft );
            //document.getElementById(bar).value = timeleft;
            //document.getElementById(text).textContent = timeleft;
      
            if (timeleft <= 0) {
              clearInterval(countdownTimer);
              resolve(true);
            }
          }, 1000);
        });
    }

    React.useEffect(()=>{
        // enqueueSnackbar('Vos informations ont été mises à jour avec succès', { variant : 'success' });
        if( page == 'conferm' && !resetCode ){
            ProgressCountdown(20)
                .then( value => {
                    setResetCode(true);
                });
        }
        // console.log( redirection );
    },[page,0]);
    return (
        <div maxWidth="lg" >
        <Card>
            <CardContent>
                <Grid container spacing={2} alignItems="center" justifyContent="center" >
                    <Grid item xs={12} md={12} lg={12}>
                        <div className="account--edit--infos--title p-top-10">
                        <h3>Confirmation votre numéro de téléphone</h3>
                        </div>
                    </Grid>
                    {
                        page == 'phone' &&
                        <Grid item  xs={12} md={12} lg={12}  >
                            <form onSubmit={submitPhone} method="POST" >
                                <Grid container spacing={3}> 
                                    
                                        <Grid item xs={12} sm={3} md={3}>
                                            <Autocomplete
                                                defaultValue={
                                                    list[
                                                        list.findIndex(
                                                            (c) => c.dialCode === isoCode
                                                        )
                                                    ]
                                                }
                                                options={list}
                                                autoHighlight
                                                getOptionLabel={(option) => option.name}
                                                renderOption={(option) => (
                                                    <>
                                                    <span style={{width:'2rem'}}>
                                                        <img src={process.env.PUBLIC_URL + `/flags/${option.isoCode.toLowerCase()}.svg`} height={'10'} style={{textAlign:'right'}} />
                                                    </span>
                                                    {option.name}
                                                    </>
                                                )}
                                                renderInput={(params) => <TextField {...params} label={ "Choisissez le pays" } /> }
                                                onChange={(e, option) => {
                                                    if( option ){
                                                        setIsoCode( option.dialCode );
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={9} md={9}>
                                            <FormControl fullWidth >
                                                <InputLabel >Votre numéro de téléphone</InputLabel>
                                                <Input
                                                defaultValue={''}
                                                //onChange={handleChange}
                                                onFocus={event => { event.target.select()}}
                                                name="phone"
                                                inputComponent={(props)=>{
                                                    const { inputRef, ...other } = props;
                                                    const listFilter = [];
                                                    listFilter.push('('); isoCode.split("").forEach((v)=>{ listFilter.push(v); }); listFilter.push(')');
                                                    const completFilter = [' ', /\d/, /\d/, /\d/, '-', /\d/,'-', /\d/, /\d/,'-', /\d/, /\d/, /\d/] ;
                                                    completFilter.forEach((v)=>{ listFilter.push(v); })
                                                    return (
                                                    <MaskedInput
                                                        {...other}
                                                        ref={(ref) => {
                                                            inputRef(ref ? ref.inputElement : null);
                                                        }}
                                                        mask={listFilter}
                                                        // placeholderChar={'\u2000'}
                                                        // showMask={true}
                                                        // guide={true}
                                                    />
                                                    )}
                                                }
                                                disabled={loading}
                                                />
                                            </FormControl>
                                        </Grid>
                                        { phoneHelper ? <FormHelperText error > {phoneHelper} </FormHelperText> : null}
                                        
                                    <Grid item xs={12} >
                                        <div className="link--resetpassword">
                                            <a href="#" onClick={ ()=>{ setPage('conferm'); setResetCode(true); } } > J'ai un code </a>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button buttonSize={"btn--large"} type="submit" disabled={loading}  >
                                            {
                                                loading ? <CircularProgress color="while" size={25} /> : <> Confirmer </>
                                            }
                                        </Button>
                                        <FormHelperText className="p-top-10"> Vous recevrez un SMS contenant le code de vérification </FormHelperText>
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>
                    }

                    {
                        page == 'conferm' &&
                        <Grid item  xs={12} md={12} lg={12}  >
                            <form onSubmit={submitCode} method="POST" >
                                <Grid container spacing={3}> 
                                    <Grid item xs={12} >
                                        <FormControl fullWidth >
                                            <InputLabel >Code</InputLabel>
                                            <Input
                                            //value={''}
                                            //defaultValue={''}
                                            //onChange={handleChange}
                                            
                                            onFocus={event => { event.target.select()}}
                                            name="verification_code"
                                            inputComponent={TextCodeMaskCustom}
                                            disabled={loading}
                                            />
                                            
                                        </FormControl>
                                        { codeHelper ? <FormHelperText error > {codeHelper} </FormHelperText> : null}
                                        
                                    </Grid>
                                    <Grid item xs={12} >
                                        
                                        {
                                            resetCode ? 
                                            <div className="link--resetpassword">
                                                <a href="#" onClick={ ()=>{ setPage('phone') } } > je n'ai reçu aucun code </a>
                                            </div>
                                            :
                                            <>
                                                Le code est valide pendant {countdown} secondes
                                            </>
                                        }
                                        
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button buttonSize={"btn--large"} type="submit" disabled={loading}  >
                                            {
                                                loading ? <CircularProgress color="while" size={25} /> : <> Confirmer </>
                                            }
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>
                    }   
                    
                </Grid>
            </CardContent>
        </Card>
        </div>
    );
};
export default PhoneConferm;
