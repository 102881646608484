import React from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "../../../shared/components/UI/Input";
import Button from "../../../shared/components/UI/Button/Button";
import Modal from "../../../shared/components/UI/Modal";

import { FiMail } from "react-icons/fi";
import { useHistory } from "react-router";


const Gift = ({shown,close,event}) => {

    const history = useHistory();
    const submitAsGift = async ( e ) => {
        e.preventDefault();

        const formData = new FormData(e.target);
        let _formGift = {
            gift_email : formData.get("email") ,
            gift_name  : formData.get("name") ,
            gift_phone : formData.get("phone") 
        };

        await localStorage.setItem("gift", JSON.stringify( _formGift ));
        await localStorage.setItem("order", JSON.stringify(event));
        history.push("/booking");
        
        close();
    }
    return(
        <>
        <Modal
            shown={shown}
            close={close}
            size={"modal--small"}
            title="L'offrir en cadeau"
            subtitle="Des cadeaux inoubliables sans complications. Renseignez les détails de le personne à qui vous souhaiter offrir ce cadeau"
            >
            <div>
                <form method="POST" onSubmit={ submitAsGift } >
                    <div>
                        <div className="auth--wrapper--form--content--item">
                            <Input
                            placeholder={"Nom et Prénom"}
                            error
                            InputProps={{
                                endAdornment: (
                                <InputAdornment position="start">
                                    <FiMail style={{ color: "#B9BFC6" }} />
                                </InputAdornment>
                                ),
                            }}
                            type={"text"}
                            name="name"
                            required
                            />
                        </div>

                        <div className="auth--wrapper--form--content--item">
                            <Input
                            placeholder={"E-mail"}
                            error
                            InputProps={{
                                endAdornment: (
                                <InputAdornment position="start">
                                    <FiMail style={{ color: "#B9BFC6" }} />
                                </InputAdornment>
                                ),
                            }}
                            type={"email"}
                            name="email"
                            required
                            />
                        </div>

                        <div className="auth--wrapper--form--content--item">
                            <Input
                            placeholder={"Téléphone"}
                            error
                            InputProps={{
                                endAdornment: (
                                <InputAdornment position="start">
                                    <FiMail style={{ color: "#B9BFC6" }} />
                                </InputAdornment>
                                ),
                            }}
                            type={"text"}
                            name="phone"
                            required
                            />
                        </div>
                        <div className="auth--wrapper--form--content--item">
                            <Button type="submit" buttonSize={"btn--large"}  >
                                Réserver !
                            </Button>
                        </div>

                    </div>
                </form>
            </div>
        </Modal>
        </>
    );
};

export default Gift;