import axios from "axios";
import { parseInt } from "lodash";

export const APP_NAME = "Tafwija";
// export const URL = "http://localhost";
export const URL = ( window.location.protocol + "//" + window.location.host ) ?? 'https://tafwija.com';
//export const URL = 'http://travel.ultraphenix.com';
// export const URL = 'https://tafwija.com';
const API = URL + "/api";

export const ROADERS = [
  { id: 1, value: 0, name: "Je ne suis pas motorisé" },
  { id: 2, value: 3, name: "Je suis motorisé, j’ai 3 places libres" },
  { id: 3, value: 2, name: "Je suis motorisé, j’ai 2 places libres" },
  { id: 4, value: 1, name: "Je suis motorisé, j’ai une place libres" },
  { id: 5, value: 0, name: "Je suis motorisé, je n’ai aucune place libre" },
];

export const priceFormat = (price) => {
  return new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "MAD",
  }).format(price);
};

/**
 * Return full data like total prices of event and much more ...
 * @param {order} order = order information
 */
export function OrderInfo(order) {
  let info = {
    totalPlaces: 0,
    roader: "",
    totalPrice: 0,
    totalPriceByDiscount: 0,
    totalPricePay: 0,
    totalPriceRest: 0,
  };

  order.prices.forEach((item) => {
    let qt = parseInt(item.qt ?? item.quantities);
    info.totalPrice = info.totalPrice + parseInt(item.price) * qt;
    info.totalPlaces = info.totalPlaces + qt;
  });
  // if the customer has a discount
  info.totalPriceByDiscount =
    info.totalPrice -
    (info.totalPrice / 100) *
      (parseInt(order.coupon_value)
        ? parseInt(order.coupon_value)
        : parseInt(order.customer_discount));

  info.totalPricePay =
    info.totalPriceByDiscount -
    (info.totalPriceByDiscount / 100) * (100 - order.percentage_paid);

  info.totalPriceRest =
    info.totalPriceByDiscount -
    (info.totalPriceByDiscount / 100) * order.percentage_paid;
  if (ROADERS.some((r) => r.id === parseInt(order.roader))) {
    info.roader = ROADERS.find((r) => r.id === parseInt(order.roader)).name;
  }
  return info;
}

/**
 * Event
 * @id = event ID
 */

export async function Settings() {
  return await new Promise((resolve, reject) => {
    axios
      .get(API + "/settings")
      .then(async (res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
}

/**
 * Frequently Asked Questions
 */
export async function FAQ() {
  return await new Promise((resolve, reject) => {
    axios
      .get(API + "/faqs")
      .then(async (res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
}

/**
 * Condetion general
 */
export async function CGU() {
  return await new Promise((resolve, reject) => {
    axios
      .get(API + "/cgus")
      .then(async (res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
}

/**
 * get Social login link
 */
export async function socialLoginLink(provider) {
  return await new Promise((resolve, reject) => {
    axios
      .get(API + "/auth/" + provider)
      .then(async (res) => {
        await resolve(res.data);
      })
      .catch(async (err) => {
        await reject(err.response);
      });
  });
}

/**
 * get Social login callback
 */

export async function socialLoginCallback(provider, request = "") {
  return await new Promise((resolve, reject) => {
    axios
      .get(API + "/auth/" + provider + "/callback" + request)
      .then(async (res) => {
        await localStorage.setItem("auth", JSON.stringify(res.data));
        await resolve(res);
      })
      .catch(async (err) => {
        await reject(err.response);
      });
  });
}

/**
 * Auth
 * Get user from api
 */
export const AuthStorage = JSON.parse(localStorage.getItem("auth")) || {};
export async function Auth() {
  return await new Promise((resolve, reject) => {
    if (Object.keys(AuthStorage).length) {
      // There is some data

      axios
        .get(API + "/user", {
          headers: {
            Authorization: `Bearer ${AuthStorage.access_token}`,
          },
        })
        .then(async (res) => {
          if (res.data.user.status == 1) {
            // Auth user has no active status
            let updateAuthStorage = { ...AuthStorage, user: res.data.user };
            localStorage.setItem("auth", JSON.stringify(updateAuthStorage));
            resolve(updateAuthStorage);
          } else {
            reject({ errors: "Your acount is not active" });
          }
        })
        .catch((err) => {
          if (Object.keys(err.response).length) {
            reject(err.response.data);
          }
        });
    } else {
      reject({ errors: "Unauthorized" });
    }
  });
}
/**
 *
 * @param { email , name , phone , country , city , address , code_postal , oldPassword , password , password_confermation } FORM
 */
export async function ChangeUserInfo(FORM, token = null) {
  let AuthStorage = (await JSON.parse(localStorage.getItem("auth"))) || {};
  return await new Promise((resolve, reject) => {
    axios
      .put(API + "/user/update", FORM, {
        headers: {
          Authorization: `Bearer ${token ?? AuthStorage.access_token}`,
        },
      })
      .then(async (res) => {
        let updateAuthStorage = { ...AuthStorage, user: res.data.user };
        await localStorage.setItem("auth", JSON.stringify(updateAuthStorage));
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
}

/**
 *
 * @param { phone } FORM
 */
export async function SignNumber(FORM) {
  return await new Promise((resolve, reject) => {
    axios
      .post(API + "/verify/account/phone", FORM, {
        headers: {
          Authorization: `Bearer ${AuthStorage.access_token}`,
        },
      })
      .then(async (res) => {
        //let updateAuthStorage = { ...AuthStorage , user : res.data.user };
        //localStorage.setItem( 'auth' , JSON.stringify( updateAuthStorage ) );
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
}

/**
 *
 * @param { verification_code } FORM
 */
export async function ConfermNumber(FORM) {
  return await new Promise((resolve, reject) => {
    axios
      .post(API + "/verify/account", FORM, {
        headers: {
          Authorization: `Bearer ${AuthStorage.access_token}`,
        },
      })
      .then(async (res) => {
        //let updateAuthStorage = { ...AuthStorage , user : res.data.user };
        //localStorage.setItem( 'auth' , JSON.stringify( updateAuthStorage ) );
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
}

/**
 *
 *
 */
export async function ResendVerefecationEmail() {
  return await new Promise((resolve, reject) => {
    axios
      .post(
        API + "/email/resend",
        {},
        {
          headers: {
            Authorization: `Bearer ${AuthStorage.access_token}`,
          },
        }
      )
      .then(async (res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
}

/**
 *
 * @param { token }
 */
export async function ConfermVerefecationEmail(token) {
  return await new Promise((resolve, reject) => {
    axios
      .post(
        API + `/email/verify/${token}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${AuthStorage.access_token}`,
          },
        }
      )
      .then(async (res) => {
        let userrr = { ...AuthStorage.user, email_verified_at: new Date() };
        let updateAuthStorage = { ...AuthStorage, user: userrr };
        localStorage.setItem("auth", JSON.stringify(updateAuthStorage));

        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
}

/**
 *
 * @param { email , name , phone , country , city , address , code_postal , oldPassword , password , password_confermation } FORM
 */
export async function ChangePartnerInfo(FORM, myHeader = {}) {
  return await new Promise((resolve, reject) => {
    const _header = {
      Authorization: `Bearer ${AuthStorage.access_token}`,
    };
    const _headerAssign = Object.assign(_header, myHeader);
    axios
      .post(API + "/partner/update", FORM, {
        headers: _headerAssign,
      })
      .then(async (res) => {
        let updateAuthStorage = { ...AuthStorage, user: res.data.user };
        localStorage.setItem("auth", JSON.stringify(updateAuthStorage));
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
}

export async function Logout() {
  return await new Promise(async (resolve, reject) => {
    try {
      await localStorage.setItem("auth", "{}");
      await localStorage.removeItem("auth");
      setTimeout(() => {
        if (!Object.keys(AuthStorage).length) {
          resolve({});
        } else {
          reject({ message: "You cant clear storage session" });
        }
      }, 100);
    } catch (e) {
      reject(e);
    }
  });
}

/**
 * Sign in
 * make login connection and return a token key for authorization
 */
export async function SignIn(FORM) {
  await localStorage.setItem("auth", "{}");
  await localStorage.removeItem("auth");
  return await new Promise((resolve, reject) => {
    axios
      .post(API + "/login", FORM)
      .then(async (res) => {
        //console.log( res.data );
        localStorage.setItem("auth", JSON.stringify(res.data));
        resolve(res);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
}

/**
 * Sign up
 * registr new user or partner to the system
 */

export async function SignUp(FORM) {
  await localStorage.setItem("auth", "{}");
  await localStorage.removeItem("auth");
  return await new Promise((resolve, reject) => {
    axios
      .post(API + "/signup", FORM)
      .then(async (res) => {
        res.data.user.isVerified = false;
        localStorage.setItem("auth", JSON.stringify(res.data));
        resolve(res);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
}

export async function ForgotPassword(FORM) {
  return await new Promise((resolve, reject) => {
    axios
      .post(API + "/forgot", FORM)
      .then(async (res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
}

export async function ResetPassword(FORM) {
  return await new Promise((resolve, reject) => {
    axios
      .post(API + "/reset", FORM)
      .then(async (res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
}

/**
 * Galleries
 */
export async function Galleries(page = 1) {
  let per_page = 9;
  return await new Promise((resolve, reject) => {
    axios
      .get(API + "/galleries?per_page=" + per_page + "&page=" + page)
      .then(async (res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
}

/**
 * Galery
 * @galery_id { id }
 */
export async function Gallery(id) {
  return await new Promise((resolve, reject) => {
    axios
      .get(API + "/galleries/" + id)
      .then(async (res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
}

/**
 * Blogs
 */
export async function Posts(page = 1) {
  let per_page = 9;
  return await new Promise((resolve, reject) => {
    axios
      .get(API + "/blogs?per_page=" + per_page + "&page=" + page)
      .then(async (res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
}

/**
 * Post Blog
 * @article_id { id }
 */
export async function Post(id) {
  return await new Promise((resolve, reject) => {
    axios
      .get(API + "/blogs/" + id)
      .then(async (res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
}

/**
 * Categories
 */

export async function Categories() {
  return await new Promise((resolve, reject) => {
    axios
      .get(API + "/categories")
      .then(async (res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
}

/**
 * Cities
 */

export async function Cities() {
  return await new Promise((resolve, reject) => {
    axios
      .get(API + "/cities")
      .then(async (res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
}

/**
 * Start cities for events
 */

export async function CitiesDepart() {
  return await new Promise((resolve, reject) => {
    axios
      .get(API + "/cities-depart")
      .then(async (res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
}

/**
 * Search for events
 * @distination = city ID
 * @catigori    = category ID
 * @from        = date start
 * @to          = date end
 *
 * @per_page    = for pagination
 */

export async function Search(FORM, page = 1) {
  let per_page = 9;
  return await new Promise((resolve, reject) => {
    axios
      .post(API + "/events/search?per_page=" + per_page + "&page=" + page, FORM)
      .then(async (res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
}

/**
 * Events
 * @per_page    = for pagination
 */

export async function Events(type = null, page = 1, current_event = null) {
  let per_page = 9;
  let is_related = type === "Sejour" ? true : false;
  return await new Promise((resolve, reject) => {
    let eventsLink = type
      ? `/events/type/${type}?per_page=${per_page}&page=${page}&is_related=${is_related}&current_event=${current_event}`
      : `/events?per_page=${per_page}&page=${page}&is_related=${is_related}&current_event=${current_event}`;
    axios
      .get(API + eventsLink)
      .then(async (res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
}

/**
 * Event
 * @id  = event ID
 */

export async function Event(id) {
  return await new Promise((resolve, reject) => {
    axios
      .get(API + "/events/" + id)
      .then(async (res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
}

/**
 * Hote events
 * @per_page    = for pagination
 */

export async function HoteEvents(page = 1) {
  let per_page = 9;
  return await new Promise((resolve, reject) => {
    axios
      .get(API + "/hot-events?per_page=" + per_page + "&page=" + page)
      .then(async (res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
}

/**
 * Search for events
 * @event_id    = event ID
 * @depart_city = city
 * @roader      = roader > not required
 * @prices      = prices > multiple qty_{price_name}_{event_id}
 */

export async function Order(FORM) {
  return await new Promise((resolve, reject) => {
    if (Object.keys(AuthStorage).length) {
      // There is some data
      axios
        .post(API + "/reservation", FORM, {
          headers: {
            Authorization: `Bearer ${AuthStorage.access_token}`,
          },
        })
        .then(async (res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response);
        });
    } else {
      reject({ errors: "Unauthorized" });
    }
  });
}

// updating Order
export async function updateOrder(FORM) {
  return await new Promise((resolve, reject) => {
    if (Object.keys(AuthStorage).length) {
      // There is some data
      axios
        .put(API + "/reservation", FORM, {
          headers: {
            Authorization: `Bearer ${AuthStorage.access_token}`,
          },
        })
        .then(async (res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response);
        });
    } else {
      reject({ errors: "Unauthorized" });
    }
  });
}

/**
 * Get user commandes not confirmed
 * @auth
 */
export async function UserOrdersUnconfirmed() {
  return await new Promise((resolve, reject) => {
    if (Object.keys(AuthStorage).length) {
      // There is some data
      axios
        .get(API + "/user/commandes", {
          headers: {
            Authorization: `Bearer ${AuthStorage.access_token}`,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    } else {
      reject({ errors: "Unauthorized" });
    }
  });
}

/**
 * Get user commandes confirmed
 * @auth
 */
export async function UserOrdersConfirmed() {
  return await new Promise((resolve, reject) => {
    if (Object.keys(AuthStorage).length) {
      // There is some data
      axios
        .get(API + "/user/reservations", {
          headers: {
            Authorization: `Bearer ${AuthStorage.access_token}`,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    } else {
      reject({ errors: "Unauthorized" });
    }
  });
}

/**
 * Get user reviews for his orders
 *
 */

export async function UserReviews() {
  return await new Promise((resolve, reject) => {
    if (Object.keys(AuthStorage).length) {
      // There is some data
      axios
        .get(API + "/user/reviews", {
          headers: {
            Authorization: `Bearer ${AuthStorage.access_token}`,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    } else {
      reject({ errors: "Unauthorized" });
    }
  });
}

/**
 * Orders who can this user put a review
 *
 */

export async function EventsForReview() {
  return await new Promise((resolve, reject) => {
    if (Object.keys(AuthStorage).length) {
      // There is some data
      axios
        .get(API + "/user/reviews/events", {
          headers: {
            Authorization: `Bearer ${AuthStorage.access_token}`,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    } else {
      reject({ errors: "Unauthorized" });
    }
  });
}

/**
 *
 * @param {event_id,rating,comment} FORM
 */
export async function SendReview(FORM) {
  return await new Promise((resolve, reject) => {
    axios
      .post(API + "/user/review", FORM, {
        headers: {
          Authorization: `Bearer ${AuthStorage.access_token}`,
        },
      })
      .then(async (res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
}

/**
 *
 * @param {event_id,rating,comment} FORM
 */
export async function CancelOrder(FORM) {
  return await new Promise((resolve, reject) => {
    axios
      .put(API + "/cancel/reservation", FORM, {
        headers: {
          Authorization: `Bearer ${AuthStorage.access_token}`,
        },
      })
      .then(async (res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
}

/**
 * Get partner information
 */
export async function PartnerInformation(id) {
  return await new Promise((resolve, reject) => {
    axios
      .get(API + "/partner/" + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
}
/**
 * Get user analytics for partner
 */
export async function PartnerAnalytics() {
  return await new Promise((resolve, reject) => {
    if (Object.keys(AuthStorage).length) {
      // There is some data
      axios
        .get(API + "/partner/analytics", {
          headers: {
            Authorization: `Bearer ${AuthStorage.access_token}`,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    } else {
      reject({ errors: "Unauthorized" });
    }
  });
}

/**
 * Get user commandes not confirmed
 * @auth
 */
export async function PartnerOrders(page = 1) {
  let per_page = 10;
  return await new Promise((resolve, reject) => {
    if (Object.keys(AuthStorage).length) {
      // There is some data
      axios
        .get(
          API + "/partner/reservations?per_page=" + per_page + "&page=" + page,
          {
            headers: {
              Authorization: `Bearer ${AuthStorage.access_token}`,
            },
          }
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    } else {
      reject({ errors: "Unauthorized" });
    }
  });
}

/**
 *
 * @param {*} page
 */
export async function PartnerEvents(page = 1) {
  let per_page = 10;
  return await new Promise((resolve, reject) => {
    axios
      .get(API + "/partner/events?per_page=" + per_page + "&page=" + page, {
        headers: {
          Authorization: `Bearer ${AuthStorage.access_token}`,
        },
      })
      .then(async (res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
}

/**
 * Event
 * @id  = event ID
 */

export async function PartnerEvent(id) {
  return await new Promise((resolve, reject) => {
    axios
      .get(API + "/partner/event/" + id, {
        headers: {
          Authorization: `Bearer ${AuthStorage.access_token}`,
        },
      })
      .then(async (res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
}

/**
 *
 * @param {*} page
 */
export async function PartnerReviews(page = 1) {
  let per_page = 10;
  return await new Promise((resolve, reject) => {
    axios
      .get(API + "/partner/reviews?per_page=" + per_page + "&page=" + page, {
        headers: {
          Authorization: `Bearer ${AuthStorage.access_token}`,
        },
      })
      .then(async (res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
}

export async function AllCities() {
  return await new Promise((resolve, reject) => {
    axios
      .get(API + "/all-cities")
      .then(async (res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
}

export async function AllCategories() {
  return await new Promise((resolve, reject) => {
    axios
      .get(API + "/all-categories")
      .then(async (res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
}

export async function AllPricesCategories() {
  return await new Promise((resolve, reject) => {
    axios
      .get(API + "/categories-prices", {
        headers: {
          Authorization: `Bearer ${AuthStorage.access_token}`,
        },
      })
      .then(async (res) => {
        //console.log( res.data );
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
}

export async function PriceEventUnlisted(id) {
  return await new Promise((resolve, reject) => {
    axios
      .get(API + "/partner/events/pricesNotSaved/" + id, {
        headers: {
          Authorization: `Bearer ${AuthStorage.access_token}`,
        },
      })
      .then(async (res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
}

export async function AddCity(FORM) {
  return await new Promise((resolve, reject) => {
    const _header = {
      Authorization: `Bearer ${AuthStorage.access_token}`,
    };
    axios
      .post(API + "/cities/store", FORM, {
        headers: _header,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
}

export async function AddCategory(FORM) {
  return await new Promise((resolve, reject) => {
    const _header = {
      Authorization: `Bearer ${AuthStorage.access_token}`,
    };
    axios
      .post(API + "/categories/store", FORM, {
        headers: _header,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
}

export async function AddEvent(FORM, myHeader = {}) {
  return await new Promise((resolve, reject) => {
    const _header = {
      Authorization: `Bearer ${AuthStorage.access_token}`,
    };
    const _headerAssign = Object.assign(_header, myHeader);
    axios
      .post(API + "/partner/events/store", FORM, {
        headers: _headerAssign,
      })
      .then(async (res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
}

export async function UpdateEvent(id, FORM, myHeader = {}) {
  return await new Promise((resolve, reject) => {
    const _header = {
      Authorization: `Bearer ${AuthStorage.access_token}`,
    };
    const _headerAssign = Object.assign(_header, myHeader);
    axios
      .post(API + "/partner/events/update/" + id, FORM, {
        headers: _headerAssign,
      })
      .then(async (res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
}

export async function DeleteEvent(id) {
  return await new Promise((resolve, reject) => {
    const _header = {
      Authorization: `Bearer ${AuthStorage.access_token}`,
    };
    axios
      .delete(API + "/partner/events/delete/" + id, {
        headers: _header,
      })
      .then(async (res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
}

/**
 *
 * @param {*} page
 */
export async function PartnerPrices(page = 1) {
  let per_page = 10;
  return await new Promise((resolve, reject) => {
    axios
      .get(
        API + "/partner/fixed-prices?per_page=" + per_page + "&page=" + page,
        {
          headers: {
            Authorization: `Bearer ${AuthStorage.access_token}`,
          },
        }
      )
      .then(async (res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
}

/**
 * Event
 * @id  = event ID
 */

export async function PartnerPrice(id) {
  return await new Promise((resolve, reject) => {
    axios
      .get(API + "/partner/fixed-prices/" + id, {
        headers: {
          Authorization: `Bearer ${AuthStorage.access_token}`,
        },
      })
      .then(async (res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
}

export async function AddPrice(FORM, myHeader = {}) {
  return await new Promise((resolve, reject) => {
    const _header = {
      Authorization: `Bearer ${AuthStorage.access_token}`,
    };
    const _headerAssign = Object.assign(_header, myHeader);
    axios
      .post(API + "/partner/fixed-prices", FORM, {
        headers: _headerAssign,
      })
      .then(async (res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
}

export async function UpdatePrice(id, FORM, myHeader = {}) {
  return await new Promise((resolve, reject) => {
    const _header = {
      Authorization: `Bearer ${AuthStorage.access_token}`,
    };
    const _headerAssign = Object.assign(_header, myHeader);
    axios
      .post(API + "/partner/fixed-prices/update/" + id, FORM, {
        headers: _headerAssign,
      })
      .then(async (res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
}

export async function DeletePrice(id) {
  return await new Promise((resolve, reject) => {
    const _header = {
      Authorization: `Bearer ${AuthStorage.access_token}`,
    };
    axios
      .delete(API + "/partner/fixed-prices/" + id, {
        headers: _header,
      })
      .then(async (res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
}

export async function CheckCoupon(FORM) {
  return await new Promise((resolve, reject) => {
    axios
      .post(API + "/verify/coupon", FORM)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
}

/**
 *
 * @param {full_name , email, phone, number_people, message} FORM
 * @returns
 */
export async function Contact(FORM) {
  return await new Promise((resolve, reject) => {
    axios
      .post(API + "/contacts", FORM)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
}

// prepare payament
export async function preparePayment(reservationId) {
  return await new Promise((resolve, reject) => {
    if (Object.keys(AuthStorage).length) {
      // There is some data
      axios
        .get(API + "/preparePayment/" + reservationId, {
          headers: {
            Authorization: `Bearer ${AuthStorage.access_token}`,
          },
        })
        .then(async (res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response);
        });
    } else {
      reject({ errors: "Unauthorized" });
    }
  });
}

export async function TransactionDetail(reservationId) {
  return await new Promise((resolve, reject) => {
    if (Object.keys(AuthStorage).length) {
      // There is some data
      axios
        .get(API + "/getTransactionDetail/" + reservationId, {
          headers: {
            Authorization: `Bearer ${AuthStorage.access_token}`,
          },
        })
        .then(async (res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response);
        });
    } else {
      reject({ errors: "Unauthorized" });
    }
  });
}

export function downloadBarcodeImage(imageUrl) {
  if (!Object.keys(AuthStorage).length) {
    return Promise.reject({ errors: "Unauthorized" });
  }

  return axios
   .post(API + "/download-ticket", {
    image_url : imageUrl
  }, {
    headers: {
      Authorization: `Bearer ${AuthStorage.access_token}`,
      "Content-Type": "application/json",
      Accept: "application/octet-stream",
    },
    responseType: "blob",
  });
}


export function formatDateTime(dateTimeString) {
  const options = { year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit" };
  const formattedDateTime = new Date(dateTimeString).toLocaleString("en-US", options);
  return formattedDateTime;
}

export function formatDate(date) {
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  const formattedDate = new Date(date).toLocaleString("en-US", options);
  return formattedDate;
}
