import React from 'react'
import { NavLink } from 'react-router-dom'
import "./styles.scss";

const STYLE = ['link--btn--one', 'link--btn--two','link--btn--three', 'btn--disabled', 'link--btn--simple','link--btn--danger','link--btn--one--noPadding']

const SIZES = ['link--btn--medium', 'link--btn--max']


const Link = ({
    children,
    to,
    linkStyle,
    linkSize,
    disabled = false,
    custom = '',
    ...rest
}) => {

    const checkLinkStyle = STYLE.includes(linkStyle) ? linkStyle : STYLE[0]

    const checkLinkSize = SIZES.includes(linkSize) ? linkSize : SIZES[1]

    return (
            <NavLink
                className={`link--btn ${checkLinkSize} ${custom} `+ ( disabled ? ' btn--disabled ': checkLinkStyle ) }
                to={to}
                disabled={disabled}
                {...rest}
            >
                {children}
            </NavLink>
    )
}
export default Link