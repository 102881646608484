import React from "react";
import Carousels from '../../../shared/components/UI/Carousel'

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

const Gallery = ({data}) => {
  var Carousel = require("react-responsive-carousel").Carousel;
  const images = data ?? [] ;

  return (
    <>

    <Carousel
      //showArrows={true}
      // centerMode
      showStatus
      showIndicators
      infiniteLoop
      showThumbs={false}
      // useKeyboardArrows
      autoPlay
      // stopOnHover
      swipeable
      // dynamicHeight
      emulateTouch
      autoFocus
      renderArrowPrev={(onClickHandler, hasPrev, label) =>
        hasPrev && (
          <button
            type="button"
            onClick={onClickHandler}
            title={label}
            className="carousel--custom--arrow--left"
          >
           <FiChevronLeft  size={24} color={'#03387d'}/>
          </button>
        )
      }
      renderArrowNext={(onClickHandler, hasNext, label) =>
        hasNext && (
          <button
            type="button"
            onClick={onClickHandler}
            title={label}
            className="carousel--custom--arrow--right"
          >
           <FiChevronRight size={24} color={'#03387d'} />
          </button>
        )
      }
    >
      
      {
        images.length && images.map(( image , index )=>{
          return (
            <div key={ image.id } className="gallery--carousel">
              <img className="gallery--carousel--img" alt={ image.name } src={ image.pathImage } /> 
            </div>
          );
        })
      }
    </Carousel>
    </>
  );
};
export default Gallery;
