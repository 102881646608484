import React from 'react';
import "./style.scss";

const Banner = ({ size, bg, children }) => {
  return (
    <section
      className="hero--banner"
      style={{
        backgroundImage: `linear-gradient(0.25turn, rgba(5, 61, 127, .7), rgba(3, 56, 125, .8), rgba(255, 182, 44, .7)) ,url(${bg})`, height: `${size}`
        //  ,
      }}
    >
      <div className="overlay" />
      <div className="children">
        {children}
      </div>
    </section>
  );
}
export default Banner;
