import React from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { useParams, useHistory } from 'react-router-dom';

import Banner from '../../shared/components/UI/Banner';
import banner02 from '../../shared/assets/images/banner04.jpg'
import { Alert } from '@material-ui/lab';
import { CircularProgress } from '@material-ui/core';

import * as API from "../../api";

export default function Reset() {
    let { key = null  } = useParams();
    const history       = useHistory();
    const [loading      , setLoading ] = React.useState(false);
    const [verefyHelper , setVerefyHelper ] = React.useState({ type : '' /* Extent 'severity' props from material ui component */ , message : '' })
    
    const sendConfermation = () =>{
        setLoading(true);
        API.ConfermVerefecationEmail(key)
            .then((res)=>{
                //console.log(res);
                setVerefyHelper( { type : 'success' ,  message : res.message } );
                setTimeout(()=>{
                    history.push('/');
                    window.location.reload();
                },3000)
            })
            .catch((err)=>{
                if( err.status === 401 ){
                    history.push('/connection?target=login');
                }
                if( err.status === 422 ){
                    setVerefyHelper( { type : 'error' ,  message : err.data.message } );
                }else{
                    setVerefyHelper( { type : 'error' ,  message : err.data.errors } );
                }
            })
            .then(()=>{
                setLoading(false);
            })
    }
    React.useEffect(()=>{   
        if( !key ){
            history.push('/');
        }else{
            sendConfermation();
        }
    },[0])
    return (
        <div className="section--auth">

            <Banner
                size={"200px"}
                bg={banner02}
            >
                <Container maxWidth="lg"  alignItems="center" justify="center">
                    <Grid container item xs={12} sm={12} alignItems="center" justifyContent="center" className="h-100-100">
                        <div className="tafwija--banner--content">
                            <h1> Vérification de votre mail  </h1>
                        </div>
                    </Grid>
                </Container>
            </Banner>

            <Container maxWidth="lg"  >
                    <Grid container alignItems="center" justifyContent="center" className="p-top-40 p-bottom-40">
                        <Grid item xs={10}>
                            { loading && ( <CircularProgress /> ) }
                            {
                                verefyHelper.message && <Alert severity={verefyHelper.type} > { verefyHelper.message }  </Alert> 
                            }
                        </Grid>
                    </Grid>
            </Container>
        </div>
    );
}
