import React from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import {
  Switch,
  Route,
  NavLink,
  useLocation,
  useRouteMatch,
  useHistory,
  Link,
} from "react-router-dom";
import Banner from "../../shared/components/UI/Banner";
import Button from '../../shared/components/UI/Button/Button';
import banner02 from "../../shared/assets/images/banner04.jpg";
import shape001 from "../../shared/assets/images/shape001.svg";
import cancel from "../../shared/assets/images/cancel_u1it.svg";

import Booking from "./Booking";
import Command from "./Command";
import CommandConfirmed from "./CommandConfirmed";
import Edit from "./Edit";
import Review from "./Review";
import PhoneConferm from "./PhoneConferm";

import * as API from "../../api";
import { Alert } from "@material-ui/lab";
import { useSnackbar } from "notistack";
import { CircularProgress } from "@material-ui/core";


export default function User() {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const account = "/account";
  const route = useRouteMatch();
  const page = route.params.page;
  const AuthExist = Object.keys(API.AuthStorage).length;
  const [ emailVerification , setEmailVerification ] = React.useState(null);
  const [ loadingEmail , setLoadingEmail ] = React.useState(false);
  const [ emailSend    , setEmailSend    ] = React.useState(false);

  const sendEmailVerifecation = () => {
    setLoadingEmail(true);
    API.ResendVerefecationEmail()
      .then((res)=>{
        //console.log(res);
        enqueueSnackbar( res.message , { variant : 'success' });
        setEmailSend(true);
      })
      .catch((err)=>{
        //console.log(err.data.errors);
        enqueueSnackbar( err.data.errors , { variant : 'error' });
      })
      .then(()=>{
        setLoadingEmail(false);
      })
  }

  React.useEffect(() => {
    //console.log( API.AuthStorage );
    if (!AuthExist) {
      history.push("/");
    }else{
      setEmailVerification( API.AuthStorage.user.email_verified_at );
    }
  }, [0]);
  return (
    <div className="section--auth ">
      <img src={shape001} alt="" className="shape--01"/>
      <Banner size={"200px"} bg={banner02}>
        <Container maxWidth="lg" alignItems="center" justify="center">
          <Grid
          container
            item
            xs={12}
            sm={12}
            alignItems="center"
            justifyContent="center"
            className="h-100-100"
          >
            <div className="tafwija--banner--content">
              <h1>Mon compte Tafwija</h1>
            </div>
          </Grid>
        </Container>
      </Banner>
      { AuthExist &&
      <Container maxWidth="lg" alignItems="center" justify="center" className="p-top-40 p-bottom-40 container--user">
        {API.AuthStorage.user.type == "user" ? (
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
            <div className="tafwija--menu">
              <NavLink activeClassName={"active"} exact={true} to={`${account}/orders`}>Mes Réservations</NavLink>
              {/* <NavLink activeClassName={"active"} to={`${account}/points`}>Mes Crédits</NavLink> */}
              <NavLink activeClassName={"active"} to={`${account}/edit`}>Détails du compte</NavLink>
            </div>
            </Grid>
          
            {/* { !emailVerification &&
              (
              <Grid item xs={12} >
                <Alert severity="warning" >
                    <>
                      Votre email n'est pas encore vérifié
                      <Button
                        buttonStyle={'btn--outline btn--green'}
                        buttonSize={'btn--small'}
                        style={{padding:'.5rem',margin:' 0 .5rem'}}
                        disabled={loadingEmail || emailSend}
                        onClick={sendEmailVerifecation}
                        >
                          { loadingEmail ? <CircularProgress size={10} color="white" /> : <> { emailSend ? 'Vérifiez votre boîte de réception' : 'Vérifié' }  </> }
                      </Button>
                    </>
                </Alert>
              </Grid>
              )  
            } */}
            {/* <Grid item xs={12} md={3} lg={3}>
              <ul className="account--nav--list">
                <li className="account--nav--list--item">
                  <NavLink to={`${account}/orders-unconfirmed`}>
                    Commandes
                  </NavLink>
                </li>
                <li className="account--nav--list--item">
                  <NavLink to={`${account}/orders-confirmed`}>
                    Réservations
                  </NavLink>
                </li>
                <li className="account--nav--list--item">
                  <NavLink to={`${account}/edit`}>Détails du compte</NavLink>
                </li>
                <li className="account--nav--list--item">
                  <NavLink to={`${account}/review`}>Rating </NavLink>
                </li>
                <li className="account--nav--list--item">
                  <NavLink to={`/logout`}>Déconnextion</NavLink>
                </li>
              </ul>
            </Grid> */}
            <Grid item xs={12} md={12} lg={12}>
              <div>
                <Switch>
                  <Route path={`${route.path}/orders`}>
                    <Booking />
                  </Route>
                  <Route path={`${route.path}/edit`}>
                    <Edit />
                  </Route>
                  {/* 
                  <Route path={`${route.path}/orders-unconfirmed`}>
                    <Command />
                  </Route>
                  <Route path={`${route.path}/orders-confirmed`}>
                    <CommandConfirmed />
                  </Route>
                  */}
                  <Route path={`${route.path}/review`}>
                    <Review />
                  </Route>
                  <Route path={`${route.path}/phone-confirmation`}>
                    <PhoneConferm />
                  </Route>
                </Switch>
              </div>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <img src={cancel} height="400" />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              You dont have the right to acces this page . go to{" "}
              <Link to="/">Home</Link> page
            </Grid>
          </Grid>
        )}
      </Container>
      }
    </div>
  );
}
