import React from "react";
import Grid from "@material-ui/core/Grid";
import Collapse from "../../../shared/components/UI/Collapse";

const Program = ( props ) => {
  return (
    <Collapse
      header={<h4 className="program--title">{props.title}</h4>}
      children={
        <Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div className="program--content">
            {props.children}
            </div>
          </Grid>
        </Grid>
      }
    />
  );
};
export default Program;
