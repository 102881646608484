import React from 'react'
// import { Link } from 'react-router-dom'
import "./Button.scss";

const STYLE = ['btn--primary','btn--second','btn--green', 'btn--danger','btn--danger--link','btn--outline', 'btn--link', 'btn--disabled', 'btn--prim--opacity']

const SIZES = ['btn--small','btn--medium', 'btn--large','btn--max','btn--medium--fullwidth']


const Button = ({
    children,
    type,
    onClick,
    buttonStyle,
    buttonSize,
    disabled = false,
    custom = '',
    ...rest
}) => {

    const checkButtonStyle = STYLE.includes(buttonStyle) ? buttonStyle : STYLE[0]

    const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[1]

    return (
            <button
                className={`btn ${checkButtonSize} ${custom} `+ ( disabled ? ' btn--disabled ': checkButtonStyle ) }
                onClick={onClick}
                type={type}
                disabled={disabled}
                {...rest}
            >
                {children}
            </button>
    )
}
export default Button