import React from 'react';
import {TextField } from '@material-ui/core';
import "./styles.scss"



const Input = ({ title, ...rest}) => {
  return (
      <TextField className="custom__input"  id="outlined-basic" label={title} variant="outlined" {...rest}/>
    );
  }
export default Input;
