import React from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Banner from "../../../shared/components/UI/Banner";
import not_found from "../../../shared/assets/images/not_found_60pq.svg";
import Carpool from "../../../shared/components/UI/Carpool";
import DepartCity from "../../../shared/components/UI/DepartCity";
import {
  AccessTime,
  CalendarToday,
  PersonAdd,
  PersonOutline,
} from "@material-ui/icons";
import Gallery from "./gallery";

import Program from "./program";
import Features from "./features";
import SejourEquipments from "./sejourEquipments";
import Before from "./before";
import Booking from "./bookingCard";
import Organizer from "./organizer";
import Review from "./review";
import Gift from "./gift";
import Title from "../../../shared/components/UI/Title";
import Button from "../../../shared/components/UI/Button/Button";
import EventCarousel from "../../../shared/components/EventsCard/EventCarousel";
import ActivitieCarousel from "../../../shared/components/EventsCard/ActivitieCarousel";
import SejourCarousel from "../../../shared/components/EventsCard/SejourCarousel";

import * as API from "../../../api";
import { useParams } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";

// import { momentLocalizer} from "react-big-calendar";
//import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import "react-big-calendar/lib/sass/styles.scss";
//import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss'
// import moment from "moment";
import "moment/locale/fr";
import { FiChevronRight, FiGift, FiHeart } from "react-icons/fi";
import Ratings from "../../../shared/components/UI/Ratings";
// const localizer = momentLocalizer(moment);

const Events = () => {
  const [eventLoading, setEventLoading] = React.useState(false);
  const [event, setEvent] = React.useState({});

  const [organizerLoading, setOrganizerLoading] = React.useState(false);
  const [organizer, setOrganizer] = React.useState({});

  const [gallery, setGallery] = React.useState([]);
  const [gathering, setGathering] = React.useState([]);

  const [eventsCalender, setEventsCalender] = React.useState([]);

  const [typeEvent, setTypeEvent] = React.useState("Evenement");

  const [modalGiftShown, toggleModalGiftShown] = React.useState(false);
  const ceOrCet = ["A", "E", "I", "O", "U"].includes(typeEvent[0].toUpperCase())
    ? "cet"
    : "ce";

  const { id } = useParams();
  // const monthNames = [
  //   "Janvier",
  //   "février",
  //   "mars",
  //   "avril",
  //   "mai",
  //   "juin",
  //   "juillet",
  //   "août",
  //   "septembre",
  //   "octobre",
  //   "novembre",
  //   "décembre",
  // ];

  // const location = useLocation();
  const getPrice = (prices) => {
    if (prices.length == 0) return "0,00";
    let price = prices.filter((item) => item.default == "1")[0].price;
    return API.priceFormat(price);
  };

  const getPriceDescription = (prices) => {
    let filteredPrices = prices.filter((item) => item.default == "1");
    if (filteredPrices.length > 0) {
      return filteredPrices[0].description ?? "";
    } else {
      return "";
    }
  };

  React.useEffect(() => {
    setEventLoading(true);
    API.Event(id)
      .then((res) => {
        let ev = res.data;
        // Set title page
        document.title = API.APP_NAME + " - " + res.data.title;

        setEvent(res.data);
        if (ev.type === "Activitie") {
          setTypeEvent("activité");
        } else if (ev.type === "Event") {
          setTypeEvent("voyage");
        } else {
          setTypeEvent("Sejour");
        }
        // gallery
        setGallery(ev.gallery);

        // for calender if event is an activity
        if (res.data.type === "Activitie") {
          setEventsCalender(() => {
            return JSON.parse(res.data.calendrier || "[]").map((ev) => {
              return {
                ...ev,
                start: new Date(ev.start),
                end: new Date(ev.end),
              };
            });
          });
        }

        // get Partner Data
        let _event = res.data;
        if (_event.proprietor === "partner") {
          setOrganizerLoading(true);
          API.PartnerInformation(_event.partner_id)
            .then((res) => {
              //console.log(res.data);
              setOrganizer(res.data);
            })
            .catch((err) => {
              //console.log(err);
            })
            .then(() => {
              setOrganizerLoading(false);
            });
        }
      })
      .catch((err) => {
        //console.log(err);
      })
      .then(() => {
        setEventLoading(false);
      });
    localStorage.setItem("intended", `/event/${id}`);
  }, [0, id]);

  React.useEffect(() => {
    setGathering(event.gathering);
  }, [0]);

  const renderEventsUi = (event) => {
    let carouselComponent = <></>;
    if (event.type === "Activitie") {
      carouselComponent = <ActivitieCarousel />;
    } else if (event.type === "Event") {
      carouselComponent = <EventCarousel />;
    } else if(event.type === "Sejour") {
      console.log('%cindex.js line:155 callevents', 'color: #007acc;', event.id);
        carouselComponent = <SejourCarousel event_id={event.id}/>; // Empty component when there is no match
    }
    return carouselComponent;
  };

  const renderEventUiTitle = (event) => {
    let titleComponent = <></>;
    if (event.type === "Activitie") {
      titleComponent = <Title title="Activités" mode={"medium"} />;
    } else if (event.type === "Event") {
      titleComponent = <Title title="Voyages" mode={"medium"} />;
    } else {
      titleComponent = <Title title="Événements Liés" mode={"medium"} />; // Empty component when there is no match
    }
    return titleComponent;
  };

  return (
    <div className="">
      <div className="section--events p-bottom-40">
        {event && Object.keys(event).length && !gallery.length ? (
          <>
            <Banner size={"380px"} bg={event.image} />
          </>
        ) : (
          <></>
        )}

        {gallery.length ? (
          <>
            <Container maxWidth="lg" className="h-100-100 p-top-20">
              <Grid
                container
                spacing={2}
                justifyContent={`center`}
                className="h-100-100"
              >
                <Grid item={true} xs={12} md={12} container>
                  <Gallery
                    data={gallery.concat([
                      {
                        id: Date.now(),
                        name: event.title,
                        pathImage: event.image,
                      },
                    ])}
                  />
                </Grid>
              </Grid>
            </Container>
          </>
        ) : (
          <></>
        )}

        <Container maxWidth="lg" className="h-100-100 p-top-20">
          <Grid
            container
            spacing={2}
            alignItems={`flex-start`}
            justifyContent={`center`}
            className="h-100-100"
          >
            {eventLoading ? (
              <CircularProgress />
            ) : (
              <>
                {Object.keys(event).length ? (
                  <>
                    <Grid item xs={12} sm={12} md={8} container>
                      <div className="detail--event--wrapper">
                        <div className="detail--event--wrapper--head">
                          {event.type === "Activitie" ? (
                            <div className="detail--event--wrapper--head--paths">
                              <p>Activités</p>
                              <FiChevronRight />
                              <p>{event.title}</p>
                            </div>
                          ) : event.type == "Sejour" ? (
                            <div className="detail--event--wrapper--head--paths">
                              <p>Séjour</p>
                              <FiChevronRight />
                              <p>{event.title}</p>
                            </div>
                          ) : (
                            <div className="detail--event--wrapper--head--paths">
                              <p>Voyages</p>
                              <FiChevronRight />
                              <p>{event.title}</p>
                            </div>
                          )}
                          <div className="detail--event--wrapper--head--title">
                            <h1> {event.title} </h1>
                            {event.type === "Activitie" ||
                            event.type == "Sejour" ? (
                              <>
                                <DepartCity title={event.citie} />
                              </>
                            ) : (
                              <>
                                {event.covoiturage === "1" && (
                                  <>
                                    <Carpool title={"Covoiturage"} />
                                    <div className="m-left-10" />
                                  </>
                                )}
                                <DepartCity
                                  title={event.gathering.map((item) => {
                                    return ` - ${item.gathering}`;
                                  })}
                                />
                              </>
                            )}
                          </div>

                          <div className="detail--event--wrapper--head--reviewGifts">
                            <div className="detail--event--wrapper--head--reviewGifts--rating">
                              <Ratings
                                value={event.total_reviews}
                                name={"read"}
                                readOnly
                              />
                              <p>
                                {" "}
                                {event.total_reviews}/5 - {event.reviews.length}{" "}
                                avis
                              </p>
                            </div>

                            {!event.expire && (
                              <>
                                <div className="detail--event--wrapper--head--reviewGifts--gifts">
                                  <Button
                                    type="submit"
                                    buttonSize={"btn--medium"}
                                    buttonStyle={"btn--prim--opacity"}
                                    onClick={() => {
                                      toggleModalGiftShown(true);
                                    }}
                                  >
                                    <FiGift size={20} />
                                    <h4 className="p-left-10">
                                      L’offrir en cadeau
                                    </h4>
                                  </Button>

                                  <Gift
                                    event={event}
                                    shown={modalGiftShown}
                                    close={() => {
                                      toggleModalGiftShown(false);
                                    }}
                                    toRegister={() => {
                                      toggleModalGiftShown(false);
                                    }}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        </div>

                        <div className="mobile--price">
                          <Booking
                            event={event}
                            price={getPrice(event.prices)}
                            priceDescription={getPriceDescription(event.prices)}
                          />
                        </div>

                        {event.type === "Activitie" ||
                        event.type == "Sejour" ? (
                          <></>
                        ) : (
                          <div className="detail--event--wrapper--specific">
                            <div className="detail--event--wrapper--specific--item">
                              <AccessTime />
                              <h5>{event.total_days} Jour(s)</h5>
                            </div>
                            <div className="detail--event--wrapper--specific--item">
                              <CalendarToday />
                              <h5>
                                Du {event.date_depart} au {event.date_end}
                                {/* { new Date(event.date_depart).getUTCDay() } au { new Date(event.date_end).getUTCDay() } { monthNames[new Date(event.date_end).getMonth()] }  */}
                              </h5>
                            </div>
                            <div className="detail--event--wrapper--specific--item">
                              <PersonOutline />
                              <h5>Capacité {event.capacitie}</h5>
                            </div>
                            <div className="detail--event--wrapper--specific--item">
                              <PersonAdd />
                              <h5>Age {event.age}+</h5>
                            </div>
                          </div>
                        )}

                        {/*
                        {Object.keys(event).length ? (
                          <div className="detail--event--wrapper--imagee">
                            <img src={event.image} alt="Image" />
                          </div>
                        ) : (
                          <div className="detail--event--wrapper--imagee">
                            <img src={banner01} alt="Image" />
                          </div>
                        )}
                        */}
                        {event.description ? (
                          <>
                            <div className="detail--event--wrapper--descriptions">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: event.description,
                                }}
                              />
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {/* {event.type == "Activitie" ? (
                          <div className="m-top-20 m-bottom-20">
                            <Calendar
                              selectable={false}
                              culture="fr"
                              messages={{
                                time: "Temps",
                                event: "Événement",
                                allDay: "Toute la journée",
                                week: "Semaine",
                                work_week: "Semaine de travail",
                                day: "Jour",
                                month: "Mois",
                                previous: "<",
                                next: ">",
                                yesterday: "Hier",
                                tomorrow: "Demain",
                                today: "Aujourd'hui",
                                agenda: "Agenda",
                                noEventsInRange:
                                  "Il n'y a aucun événement dans cette plage.",
                                showMore: function (e) {
                                  return "+" + e + " plus";
                                },
                              }}
                              localizer={localizer}
                              events={eventsCalender}
                              style={{ height: 500 }}
                              defaultView={Views.MONTH}
                              defaultDate={new Date()}
                            />
                          </div>
                        ) : (
                          ""
                        )} */}

                        {event.type == "Sejour" &&
                          event.equipments &&
                          event.equipments.length > 0 && (
                            <div className="detail--event--wrapper--features">
                              <Title
                                title="Éléments Domestiques"
                                mode={"medium"}
                              />
                              <div className="p-top-20" />
                              <SejourEquipments event={event} is_space_category={true}/>
                            </div>
                          )}

                        {event.type == "Sejour" &&
                          event.equipments &&
                          event.equipments.length > 0 && (
                            <div className="detail--event--wrapper--features">
                              <Title
                                title="Équipements et Matériaux                                "
                                mode={"medium"}
                              />
                              <div className="p-top-20" />
                              <SejourEquipments event={event}  is_space_category={false}/>
                            </div>
                          )}

                        {event.abouts.length ? (
                          <div className="detail--event--wrapper--aboutEvent">
                            <div className="detail--event--wrapper--aboutEvent--head">
                              <Title
                                title={`À propos de ${ceOrCet} ${
                                  typeEvent === "activité"
                                    ? "activité"
                                    : typeEvent === "voyage"
                                    ? "voyage"
                                    : "séjour"
                                }`}
                                mode={"medium"}
                              />
                            </div>
                            {event.abouts.map((about) => {
                              return (
                                <div
                                  key={about.id}
                                  className="detail--event--wrapper--aboutEvent--item"
                                >
                                  <div className="detail--event--wrapper--aboutEvent--item--icon">
                                    <FiHeart size={20} color={"#03387D"} />
                                  </div>
                                  <div className="detail--event--wrapper--aboutEvent--item--content">
                                    <h5 className="detail--event--wrapper--aboutEvent--item--content--title">
                                      {" "}
                                      {about.title}{" "}
                                    </h5>
                                    <p className="detail--event--wrapper--aboutEvent--item--content--text">
                                      {" "}
                                      {about.description}{" "}
                                    </p>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          <></>
                        )}

                        {event.type === "Event" ? (
                          <>
                            {event.programmes.length > 0 && (
                              <div className="detail--event--wrapper--programs">
                                <Title title="Programme" mode={"medium"} />
                                <br />
                                {event.programmes.map((programm, index) => {
                                  return (
                                    <Program key={index} title={programm.title}>
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: programm.description,
                                        }}
                                      />
                                    </Program>
                                  );
                                })}
                              </div>
                            )}
                          </>
                        ) : (
                          <></>
                        )}

                        {event.strong_point.length > 0 && (
                          <div className="detail--event--wrapper--features">
                            <Title title="Votre expérience" mode={"medium"} />
                            <div className="p-top-20" />
                            <Features event={event} />
                          </div>
                        )}

                        {event.before_description && (
                          <div className="detail--event--wrapper--features">
                            <Title
                              title={`Avant ${typeEvent}`}
                              mode={"medium"}
                            />
                            <div className="p-top-20" />
                            <Before event={event} />
                          </div>
                        )}

                        {event.proprietor !== "admin" ? (
                          <div className="m-top-20">
                            {organizerLoading ? (
                              <>
                                <CircularProgress />
                              </>
                            ) : (
                              <div className="detail--event--wrapper--noborder">
                                <Title title="Organisé par" mode={"medium"} />
                                <div className="p-top-20" />
                                <Organizer
                                  organizer={organizer.name}
                                  ratingValue={event.total_reviews}
                                  about={organizer.about ? organizer.about : ""}
                                  logo={organizer.logo}
                                />
                              </div>
                            )}
                          </div>
                        ) : (
                          <></>
                        )}

                        {/* Reviews cient */}
                        {event.reviews.length ? (
                          <div className="detail--event--wrapper--noborder">
                            <Title title="L'avis des clients" mode={"medium"} />
                            <div className="p-top-20" />
                            {event.reviews.map((review) => {
                              return <Review data={review} />;
                            })}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={4}
                      container
                      className="right--box box--sticky"
                    >
                      <Booking
                        event={event}
                        price={getPrice(event.prices)}
                        priceDescription={getPriceDescription(event.prices)}
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item lg={12} container>
                      <img
                        src={not_found}
                        style={{ maxHeight: "400px", margin: "auto" }}
                      />
                    </Grid>
                    <Grid>
                      <Title title={"Oups, élément non trouvé"} />
                      <br />
                      <hr />
                    </Grid>
                  </>
                )}
              </>
            )}
          </Grid>
        </Container>
      </div>
      <div className="section--1 p-top-20 p-bottom-60">
        <Container maxWidth="lg">
          <Grid container spacing={4} className="p-top-40 p-bottom-40">
            <Grid item xs={12} md={12} container>
              {renderEventUiTitle(event)}
            </Grid>
          </Grid>
          {renderEventsUi(event)}
        </Container>
      </div>
    </div>
  );
};
export default Events;
