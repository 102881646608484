import React from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import { Switch, Route, Link, useLocation, useRouteMatch } from 'react-router-dom';

import Banner from '../../shared/components/UI/Banner';
import banner02 from '../../shared/assets/images/banner04.jpg'
import not_found_image from '../../shared/assets/images/page_not_found_su7k.svg'



export default function NotFound() {

    return (
        <div className="section--auth">

            <Banner
                size={"200px"}
                bg={banner02}
            >
                <Container maxWidth="lg"  alignItems="center" justify="center">
                    <Grid container item xs={12} sm={12} alignItems="center" justifyContent="center" className="h-100-100">
                        <div className="tafwija--banner--content">
                            <h1> Oups, cette page est introuvable </h1>
                        </div>
                    </Grid>
                </Container>
            </Banner>

            <Container maxWidth="lg"  >
                    <Grid container alignItems="center" justifyContent="center" className="p-top-40 p-bottom-40">
                        <Grid item xs={12} md={12} container>
                             <img src={not_found_image} height="400" style={{maxHeight:"400px",margin:'auto'}} />
                        </Grid>
                    </Grid>
            </Container>
        </div>
    );
}
