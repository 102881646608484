import React from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Banner from "../../shared/components/UI/Banner";
import banner03 from "../../shared/assets/images/tafwija--banner@2x.jpg";
import icon1 from "../../shared/assets/images/icon--01--t.svg";
import icon2 from "../../shared/assets/images/icon--02--t.svg";
import icon3 from "../../shared/assets/images/icon--03--t.svg";
import equal from "../../shared/assets/images/partner--equal.svg";
import { FiUser } from "react-icons/fi";

import * as API from "../../api";


export default function Partners() {
  return (
    <div className="become--partner">
      <Banner size={"280px"} bg={banner03}>
        <Container maxWidth="lg" alignItems="center" justify="center">
          <Grid
          container
            item
            xs={12}
            sm={12}
            alignItems="center"
            justifyContent="center"
            className="h-100-100"
          >
            <div className="tafwija--banner--content" style={{textAlign:'center'}}>
              <h1 className="p-bottom-20">
                Atteignez des milliers de clients pour vos activités avec
                Tafwija
              </h1>
              <a href={ API.URL + "/partner" } className="link--btn link--btn--max  link--btn--one" > <FiUser size={20} /> {" "} Se connecter </a>
            </div>
          </Grid>
        </Container>
      </Banner>
      <Container
        maxWidth="lg"
        alignItems="center"
        justify="center"
        className="p-top-40 p-bottom-20"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} lg={4}>
            <div className="become--partner--card-item">
              <div className="become--partner--card-item--head">
                <img src={icon1} alt={""} />
              </div>
              <div className="become--partner--card-item--content">
                <h3 className="become--partner--card-item--content--title">
                  Elargir votre marché
                </h3>
                <p className="become--partner--card-item--content--description">
                  Bénéficier d’un référencement à l’échelle nationale et obtenez
                  plus de visibilité pour vos activités. Nous ferons la
                  promotion et la distribution via les différents canaux de
                  communication payantes et gratuites
                </p>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <div className="become--partner--card-item">
              <div className="become--partner--card-item--head">
                <img src={icon2} alt={""} />
              </div>
              <div className="become--partner--card-item--content">
                <h3 className="become--partner--card-item--content--title">
                  Digitaliser le parcours client
                </h3>
                <p className="become--partner--card-item--content--description">
                  Digitaliser le process de réservation et faciliter le parcours
                  client. Gérer vos réservation directement depuis votre espace
                  privé et garder la trace de toutes vos opérations
                </p>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <div className="become--partner--card-item">
              <div className="become--partner--card-item--head">
                <img src={icon3} alt={""} />
              </div>
              <div className="become--partner--card-item--content">
                <h3 className="become--partner--card-item--content--title">
                  Fiabiliser votre business
                </h3>
                <p className="become--partner--card-item--content--description">
                  Des grandes marques nous font déjà confiance, mettre votre nom
                  à leur coté augmentera le niveau de fiabilité de votre
                  établissement et de vos produits
                </p>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>

      <Container
        maxWidth="lg"
        alignItems="center"
        justify="center"
        className="p-top-40 p-bottom-20"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12}>
            <div className="become--partner--equal">
              <img src={equal} alt={""} />

              <div className="become--partner--equal--infos">
                <h6>
                  Pour plus d’informations contactez nous sur
                  <a href="mailto:partenaires@tafwija.com">
                    {" "}
                    partenaires@tafwija.com
                  </a>
                  {" "}ou sur notre WhatsApp:{" "}
                  <a href="tel:0689771647">0689771647</a>.

                  <br/><br/>

                  {/* Merci de remplir ce formulaire pour l'inscription */}
                  {/* <br/><br/> */}
                  {/* <a href="https://docs.google.com/forms/d/e/1FAIpQLSd9GWLzX-9sEBvua7jAMV01IUwZO1ixDy7C0tVj97B4nXVLGg/viewform?usp=sf_link" target="_blank" className="link--btn link--btn--max  link--btn--one" style={{ background: "#ffb62c" , color : "#fff" }} > Inscription </a> */}

                  <a href={`${API.URL}/partner/register`} target="_blank" className="link--btn link--btn--max link--btn--one" style={{ backgroundColor: "#ffb62c", color: "#fff" }}>Inscription</a>


                </h6>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
      {/*
      <Container
        maxWidth="lg"
        alignItems="center"
        justify="center"
        className="p-top-20 p-bottom-80"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12}>
            <div>
              <iframe
                src="https://forms.gle/qePfDnN9sTs8KTx98"
                height="600px"
                width="100%"
              ></iframe>
            </div>
          </Grid>
        </Grid>
      </Container>
      */}
    </div>
  );
}
