import PropTypes from "prop-types";
import React, { useMemo } from "react";
import Large from "./Large";
import Medium from "./Medium";

const MODE = {
  large: Large,
  medium: Medium,
};

const Title = ({ mode, ...props }) => {
  const Div = useMemo(() => MODE[mode], [mode]);
  return <Div {...props} />;
};

Title.propTypes = {
  mode: PropTypes.oneOf(Object.keys(MODE)),
};

Title.defaultProps = {
  mode: "large",
};

export default Title;
