import { Grid } from "@material-ui/core";
import Echec from "../../shared/assets/images/payment-error.svg";
import Link from "../../shared/components/UI/Link";




const PaymentFailed = () => {
    return (
        <div className="tafwija--checkout--card">
            <div className="tafwija--checkout--card--body">
                <div className="tafwija--checkout--card--body--p1">
                    <Grid container justifyContent="center">
                        <div className="thankyou--wrapper" style={{ height: "400px", paddingTop: "20px" }}>
                            <div className="thankyou--wrapper--image">
                                <img src={Echec} alt="" style={{width : "30vh", height : "30vh"}}/>
                                <h1 style={{ paddingTop: "20px" }}>Échec du paiement. Veuillez réessayer plus tard.</h1>
                                <div className="tafwija--checkout--button">
                                    <Grid container justifyContent="center">
                                        <Link
                                            linkStyle={"link--btn--three"}
                                            linkSize={"link--btn--medium"}
                                            to={`/account/orders`}
                                            style={{ width: "58vh" }}
                                        >
                                            <span>Mes Réservations</span>
                                        </Link>
                                    </Grid>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </div>
            </div>
        </div>
    )
}

export default PaymentFailed;