import { Grid } from "@material-ui/core";
import Collapse from "../../../shared/components/UI/Collapse";
import React, { useEffect } from "react";

const SejourEquipments = ({ event, is_space_category }) => {
  const [equipements, setEquipements] = React.useState([]);
  useEffect(() => {
    if (event) {
      let equipements = event.equipments
        ? event.equipments.filter((item) => {
            return item.is_space_category == is_space_category;
          })
        : [];
      setEquipements(equipements);
    }
  }, []);

  return (
    <>
      <div className="mobile--experience">
        {equipements.length > 0 && (
          <Collapse
            header=""
            children={equipements.map((point, index) => {
              return (
                <div key={point.id} className="mobile--experience--item--value">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19.018"
                    height="13.092"
                    viewBox="0 0 19.018 13.092"
                  >
                    <g transform="translate(-355 -1256.894)">
                      <rect
                        width="17"
                        height="7"
                        rx="3.5"
                        transform="translate(355 1262.986)"
                        fill="#ffb62c"
                      />
                      <path
                        d="M17.44,6,8.2,15.24,4,11.04"
                        transform="translate(355.447 1252.025)"
                        fill="none"
                        stroke="#083a7b"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.6"
                      />
                    </g>
                  </svg>
                  <p className="mobile--experience--item--value--text">
                    {point.name}{" "}
                  </p>
                </div>
              );
            })}
          />
        )}
      </div>

      <div className="web--experience">
        {equipements.length > 0 && (
          <div className="detail--event--wrapper--features--item">
            <Grid className="valuee" container spacing={2}>
              {equipements.map((point, index) => {
                return (
                  <Grid
                    key={index}
                    className=" detail--event--wrapper--features--item--value"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19.018"
                      height="13.092"
                      viewBox="0 0 19.018 13.092"
                    >
                      <g transform="translate(-355 -1256.894)">
                        <rect
                          width="17"
                          height="7"
                          rx="3.5"
                          transform="translate(355 1262.986)"
                          fill="#ffb62c"
                        />
                        <path
                          d="M17.44,6,8.2,15.24,4,11.04"
                          transform="translate(355.447 1252.025)"
                          fill="none"
                          stroke="#083a7b"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.6"
                        />
                      </g>
                    </svg>
                    <p
                      className="detail--event--wrapper--features--item--value--text"
                      style={{ paddingRight: "12px" }}
                    >
                      {point.nbr_properties > 0 && point.is_space_category ? (
                        <span>{point.nbr_properties} {" "}</span>
                      ) : null}
                      {point.name}{" "}
                    </p>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        )}
      </div>
    </>
  );
};
export default SejourEquipments;
