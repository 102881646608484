import React, { useState } from "react";

import TafwijaLogoImg from "../../../shared/assets/images/logo.svg";
import Ratings from "../../../shared/components/UI/Ratings";
//import ReactReadMoreReadLess from "react-read-more-read-less";

const Organizer = (props) => {
  const [readMore, setReadMore] = useState(false);
  const extraContent = (
    <p className="extra-content"> {props.about.replace("<br/>", "\n")}</p>
  );

  const linkName = readMore ? "Lire moins << " : "En savoir plus >> ";

  return (
    <div className="card--organizer m-top-20">
      <div className="card--organizer--heading">
        <div className="card--organizer--heading--left">
          <img src={props.logo || TafwijaLogoImg} alt="" />
          <h2>{props.organizer}</h2>
        </div>
        <Ratings value={props.ratingValue} readOnly />
      </div>
      <div className="card--organizer--body">
        <p className="extra-content">{props.about.replace("<br/>", "\n")}</p>

        {/* {readMore && extraContent}
        <a
          className="read-more-link"
          onClick={() => {
            setReadMore(!readMore);
          }}
        >
          <h2>{linkName}</h2>
        </a> */}

        <div className="social-single">
          <span>Partager sur</span>
          <ul>
            <li>
              {/* <a href={`https://facebook.com/sharer.php?u=${location.pathname}${location.search}`} target="_blank"> */}
              <a
                className="fb"
                href={`https://facebook.com/sharer.php?u=${window.location.href}`}
                target="_blank"
              >
                <i className="fab fa-facebook" aria-hidden="true"></i>
              </a>
            </li>
            <li>
              <a
                className="w"
                href={`whatsapp://send?text=${window.location.href}`}
                data-action="share/whatsapp/share"
                target="_blank"
              >
                <i className="fab fa-whatsapp" aria-hidden="true"></i>
              </a>
            </li>
            <li>
              <a
                className="in"
                href={`https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`}
                target="_blank"
              >
                <i className="fab fa-linkedin" aria-hidden="true"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default Organizer;
