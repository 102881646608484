import React from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import { Switch, Route, Link, useLocation, useRouteMatch, useParams, useHistory } from 'react-router-dom';

import InputAdornment from '@material-ui/core/InputAdornment';
import { EmailOutlined , LockOutlined} from '@material-ui/icons';

import Banner from '../../shared/components/UI/Banner';
import Card from '../../shared/components/UI/Card';
import Input from '../../shared/components/UI/Input';
import Button from '../../shared/components/UI/Button/Button';
import banner02 from '../../shared/assets/images/banner04.jpg'
import { Alert } from '@material-ui/lab';
import { CircularProgress } from '@material-ui/core';

import * as API from "../../api";

export default function Reset() {
    let { key = null  } = useParams();
    const history       = useHistory();
    const [loading          , setLoading          ] = React.useState(false);
    const [passwordReset    , setPasswordReset    ] = React.useState(false);
    const [emailHelper      , setEmailHelper      ] = React.useState("");
    const [passwordHelper   , setPasswordHelper   ] = React.useState("");
    const [repasswordHelper , setRepasswordHelper ] = React.useState("");
    const [message          , setMessage          ] = React.useState("");

    const sendResetPassword = (event)=>{
        setLoading(true);
        setPasswordHelper('');
        setRepasswordHelper('');
        setMessage('');

        const formData = new FormData( event.target );
        formData.append('token', key );

        API.ResetPassword( formData )
            .then(( res )=>{
                setMessage( "Félicitations, votre mot de passe a été modifié avec succès" );
                event.target.reset();
                setPasswordReset(true);
                setTimeout(()=>{
                    history.push('/connection?target=login');
                },2000);
            })
            .catch(( err )=>{
                if( err.status === 422 ){
                    if( err.data.errors.password    ){ setPasswordHelper(err.data.errors.password) }
                    if( err.data.errors.password_confirmation  ){ setRepasswordHelper(err.data.errors.password_confirmation) }
                }
            })
            .then(()=>{
                // when connection is finish
                setLoading(false);
            });

        event.preventDefault();
    }

    const sendForgot = (event)=>{
        setLoading(true);
        setEmailHelper('');
        setMessage('');

        const formData = new FormData( event.target );

        API.ForgotPassword( formData )
            .then(( res )=>{
                setMessage( "Merci, dans quelques instants vous allez recevoir un message dans votre boîte mail qui contient un lien de renitialisation du mot de passe." );
                event.target.reset();
            })
            .catch(( err )=>{
                //console.log(err);
                if( err.status == 404 ){
                    setEmailHelper( err.data.errors );
                }
            })
            .then(()=>{
                // when connection is finish
                setLoading(false);
            });
        event.preventDefault();
    }
    return (
        <div className="section--auth">

            <Banner
                size={"200px"}
                bg={banner02}
            >
                <Container maxWidth="lg"  alignItems="center" justify="center">
                    <Grid container item xs={12} sm={12} alignItems="center" justifyContent="center" className="h-100-100">
                        <div className="tafwija--banner--content">
                            <h1> Réinitialiser mon mot de passe </h1>
                        </div>
                    </Grid>
                </Container>
            </Banner>

            <Container maxWidth="lg"  >
                    <Grid container alignItems="center" justifyContent="center" className="p-top-40 p-bottom-40">
                        <Grid item xs={12} md={6} container>
                            <Card>
                                {
                                    key ?
                                        <>
                                        <p>
                                            Veuillez entrer votre nouveau mot de passe
                                        </p>
                                        <div className="auth--wrapper--form">
                                            <form onSubmit={sendResetPassword} method="POST" className="auth--wrapper--form--content">
                                                {
                                                    message &&
                                                    <div className="auth--wrapper--form--content--item">
                                                        <Alert severity='info' > { message } </Alert>
                                                    </div>
                                                }
                                                
                                                <div className="auth--wrapper--form--content--item">
                                                    <Input
                                                        placeholder={"Nouveau mot de passe"}
                                                        type={'password'}
                                                        helperText={passwordHelper}
                                                        error
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="start">
                                                                    <LockOutlined style={{ color: "#B9BFC6" }} />
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                        name="password"
                                                        disabled={ loading || passwordReset }
                                                    />
                                                </div>

                                                <div className="auth--wrapper--form--content--item">
                                                    <Input
                                                        placeholder={"Réinitialiser le nouveau mot de passe"}
                                                        type={'password'}
                                                        helperText={repasswordHelper}
                                                        error
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="start">
                                                                    <LockOutlined style={{ color: "#B9BFC6" }} />
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                        name="password_confirmation"
                                                        disabled={ loading || passwordReset }
                                                    />
                                                </div>


                                                <div className="auth--wrapper--form--content--item">
                                                    <Button buttonSize={'btn--large'} disabled={ loading || passwordReset }>
                                                        {
                                                            loading ?
                                                            <> <CircularProgress color="white" /> </>
                                                            :
                                                            <> Changer le mot de passe </>
                                                        }
                                                    </Button>
                                                </div>
                                            </form>
                                        </div>
                                        </>
                                    :
                                        <>
                                        <h3 className="title--card--auth">
                                            {/* Pour réinitialiser le mot de passe, vous devez taper votre adresse e-mail pour obtenir la validation */}
                                            Merci de saisir votre adresse e-mail pour réinitialiser le mot de passe
                                        </h3>
                                        <div className="auth--wrapper--form">
                                            <form onSubmit={sendForgot} method="POST" className="auth--wrapper--form--content">
                                                {
                                                    message &&
                                                    <div className="auth--wrapper--form--content--item">
                                                        <Alert severity='info' > { message } </Alert>
                                                    </div>
                                                }
                                                
                                                <div className="auth--wrapper--form--content--item">
                                                    <Input
                                                        placeholder={"Votre Email"}
                                                        helperText={emailHelper}
                                                        error
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="start">
                                                                    <EmailOutlined style={{ color: "#B9BFC6" }} />
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                        type={'email'}
                                                        name="email"
                                                        disabled={ loading }
                                                    />
                                                </div>
                                                <div className="auth--wrapper--form--content--item">
                                                    <Button buttonSize={'btn--large'} disabled={ loading  }>
                                                        {
                                                            loading ?
                                                            <> <CircularProgress color="white" /> </>
                                                            :
                                                            <> Envoyer la confirmation </>
                                                        }
                                                    </Button>
                                                </div>
                                            </form>
                                        </div>        
                                        </>
                                }
                                
                            </Card>
                        </Grid>
                    </Grid>
            </Container>
        </div>
    );
}
