import React, { useState, useEffect } from "react";
import { Grid, CircularProgress } from "@material-ui/core";
import { Link } from "react-router-dom";
import Event from "../Event";
import "../style.scss";
import WOW from "wow.js";

import * as API from "../../../../api";

const HotDeals = (props) => {
  const [events, setEvents] = useState([]);
  const [page, setPage] = useState(1);
  const [loadingEvents, setLoadingEvents] = useState(false);

  const getPrice = (prices) => {
    let price = prices.filter((item) => item.default == "1")[0].price;
    return API.priceFormat(price);
  };


  const getPriceDescription = (prices) => {
    let price_description = prices.filter((item) => item.default == "1")[0]
      .description ?? "";
    return price_description;
  };

  useEffect(() => {
    const wow = new WOW();
    wow.init();
  }, []);

  useEffect(() => {
    setLoadingEvents(true);
    API.HoteEvents(page)
      .then((res) => {
        setEvents(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .then(() => {
        setLoadingEvents(false);
      });

  }, [0]);
  return (
    <Grid
      container
      spacing={2}
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      {loadingEvents ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ margin: "3rem 0" }}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <>
          {events.map((event, index) => {
            return (
              <Grid key={index} item xs={12} sm={6} md={4} container>
                <Link
                  to={`event/${event.id}?slug=${event.slug}`}
                  className="link--events"
                >
                  <Event
                    data={event}
                    price={getPrice(event.prices)}
                    priceDescription={getPriceDescription(event.prices)}
                    cityDepart={event.gathering.map((d, index) => (
                      <span className="events--circuit" key={index}>
                        {" "}
                        {index === 0 ? (
                          " "
                        ) : (
                          <i className="events--circuit--divider">-</i>
                        )}{" "}
                        {d.gathering}{" "}
                      </span>
                    ))}
                    circuit={event.circuit.map((c, index) => (
                      <span className="events--circuit" key={index}>
                        {" "}
                        {index === 0 ? (
                          " "
                        ) : (
                          <i className="events--circuit--divider">-</i>
                        )}{" "}
                        {c.circuit}{" "}
                      </span>
                    ))}
                    key={index}
                    type={
                      event.type == "Activitie" ? (
                        <div className="ribbon-wrapper">
                          <div className="ribbon bg--yellow">Activité</div>
                        </div>
                      ) : event.type == "Sejour" ? (
                        <div className="ribbon-wrapper">
                          <div className="ribbon bg--green">Séjour</div>
                        </div>
                      ) : (
                        <div className="ribbon-wrapper">
                          <div className="ribbon bg--blue">Voyage</div>
                        </div>
                      )
                    }
                  />
                </Link>
              </Grid>
            );
          })}
        </>
      )}
    </Grid>
  );
};
export default HotDeals;
