import React from "react";
import { Rating } from "@material-ui/lab";

const Ratings = ({ name, value, ...rest }) => {
  return (
    <Rating
      name={name}
      value={value}
      size={"small"}
      max={5}
      {...rest}
    />
  );
};
export default Ratings;
