import React from "react";
import TafwijaLogoImg from "../../../assets/images/logo.svg";
import './style.scss'

export function Logo(props) {
  return (
    <div className="LogoWrapper">
      <div className='LogoImg'>
        <img src={TafwijaLogoImg} alt="Tafwija logo" />
      </div>
    </div>
  );
}