import React from 'react'
import PropTypes from "prop-types";
import './style.scss'

const COLORS = ["primary", "white"];


function Medium({title, color}) {
    return (
        <h2 className="wrapper">
            <div className={`wrapper--title ${color}`}>{title}</div>
        </h2>
    )
}

Medium.propTypes = {
    color: PropTypes.oneOf(COLORS),
  };
  
  Medium.defaultProps = {
    color: "primary",
  };
  
export default Medium
