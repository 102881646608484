import { CircularProgress, Container, Grid } from "@material-ui/core";
import React from "react";
import Banner from "../../shared/components/UI/Banner";
import banner02 from '../../shared/assets/images/banner04.jpg'
import empty_xct9 from '../../shared/assets/images/empty_xct9.svg';
import * as API from '../../api';
import Item from "../../shared/components/PostsCard/Item";
import { Link, useLocation } from "react-router-dom";
import { Pagination } from "@material-ui/lab";

function Blog() {
  const location = useLocation();
  const query    = new URLSearchParams( location.search );
  const _page    = parseInt(query.get('page') || '1', 10);

  const [ loadingPosts  , setLoadingPosts ] = React.useState(false);
  const [ posts         , setPosts        ] = React.useState([]);
  const [ page          , setPage         ] = React.useState( _page );
  const [ pages         , setPages        ] = React.useState(0);
  const handleChangePagination = (value) =>{
    // Set the curent page 
    setPage(value);
    // make the connection to load the next page by value 
    loadPosts(value);
    // push value of page to the link
    window.history.pushState( {} , '', '?page='+value );
  }
  const loadPosts = (_page) => {
    setLoadingPosts(true);
    API.Posts(_page)
      .then((res)=>{
        //console.log(res);
        setPosts(res.data);
        setPages( res.meta.last_page );
      })
      .catch((err)=>{ 
        //console.log(err);
      })
      .then(()=>{
        setLoadingPosts(false);
      });
  }
  React.useEffect(()=>{
    loadPosts(page);
    // Set title page
    document.title = API.APP_NAME + " - Blog " ;
  },[0])
  return (
    <div className="section--galleries">
      <Banner size={"240px"} bg={banner02}>
        <Container maxWidth="lg" alignItems="center" justify="center">
          <Grid
            container
            item
            xs={12}
            sm={12}
            alignItems="center"
            justifyContent="center"
            className="h-100-100"
          >
            <div className="tafwija--banner--content">
              <h1> Tafwija Actualites </h1>
              <p>
                Réservez en deux clicks des expériences inoubliables{" "}
              </p>
            </div>
          </Grid>
        </Container>
      </Banner>

      <div className="p-top-40">
        <Container>
          {
            loadingPosts ?
            <Grid container justifyContent="center" alignItems="center" style={{margin:'3rem 0'}}>
                <CircularProgress />
            </Grid>
            :
            <>
              {
                posts.length ?
                <>
                  <Grid container spacing={2} justifyContent="flex-start" alignItems="flex-start">
                    {posts.map((item, index) => {
                      return (
                        <Grid key={index} item xs={12} md={4} lg={4} >
                          <Link to={`/post/${item.id}?slug=${item.slug}`} className="no-decoration">
                            <Item data={item} />
                          </Link>
                        </Grid>
                      );
                    })}
                  </Grid>
                </>
                :
                <>
                  <Grid container justifyContent="center" alignItems="center" style={{margin:'2rem 0'}}>
                      <img src={empty_xct9} height="300" />
                      <h1> il n'y a pas encore de données </h1>
                  </Grid>
                </>
              }
                
            </>
          }
          {
            ( posts.length && pages > 0 ) ?
            <Grid container justifyContent="center" alignItems="center" style={{margin:'2rem 0'}}>
                <Pagination count={pages} page={page} size="large" onChange={(e,value)=>{ handleChangePagination(value)  }} />
            </Grid>
            :<></>
          }
        </Container>
      </div>
    </div>
  );
}

export default Blog;
