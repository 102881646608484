import React from "react";
import { FiSlash, FiUser } from "react-icons/fi";
import Button from "../../shared/components/UI/Button/Button";
import Modal from "../../shared/components/UI/Modal";

import Radio from "@material-ui/core/Radio";
import { FormControlLabel, InputAdornment , Input } from "@material-ui/core";
//import Input from "../../shared/components/UI/Input";

import * as API from "../../api";
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  TextField,
} from "@material-ui/core";
import { Alert, Rating } from "@material-ui/lab";

const labels = {
  1: "inutile",
  2: "Pauvre",
  3: "Ok",
  4: "Bon",
  5: "Excellent",
};

const RatingModal = ({ shown , close , order }) => {
  

  const [ ratingDone    , setRatingDone    ] = React.useState(false);
  const [ reviewExist   , setReviewExist   ] = React.useState({});
  const [ rating        , setRating        ] = React.useState(0);
  const [ hoverRating   , setHoverRating   ] = React.useState(0);

  const [ loadingRating , setLoadingRating ] = React.useState(false);
  const [ reviewHelper  , setReviewHelper  ] = React.useState({ type : '' /* Extent 'severity' props from material ui component */ , message : '' });
  const [ ratingHelper  , setRatingHelper  ] = React.useState('');
  



  const submitRating = (event) => {
      event.preventDefault();
      setRatingHelper('');
      let formData = new FormData( document.getElementById("form-review-order") );
      
      let _form = {
          reservation_id : order.id,
          rating : rating ,
          title : formData.get("title"),
          comment : formData.get("body")
      }
      if( !rating || rating == 0 ){
          setRatingHelper('The rating is required');
      }else{

          setLoadingRating(true);
          
          API.SendReview( _form )
              .then((res)=>{
                  setReviewHelper( { type : 'success' , message : res.message } );
                  setRatingDone(true);
                  setReviewExist( _form );
                  // console.log(res.message);
              })
              .catch((err)=>{
                  setReviewHelper( { type : 'error' , message : err.data.errors } );
              })  
              .then(()=>{
                  setLoadingRating(false);
                  window.location.reload();
              });
      }
       
  }

  React.useEffect(function(){
    
    let Auth = API.AuthStorage;
    if( Object.keys(order).length ){
      order.reviews.map((review)=>{
        if( review.user_id == Auth.user.id ){
          setReviewExist( review );
        }
      });
    }else{
      setReviewExist({});
    }
    
  },[order]);
  
  return (
    <>
      <Modal
        shown={shown}
        close={close}
        size={"modal--medium"}
        title={ !reviewExist.rating && "Veuillez noter l’événement et mettre un commentaire" }
        subtitle="Cette notation vous permet de cumuler 15 points"
      >
        <div className="tafwija--modal--card--body--cancel">
          {reviewHelper.message && (
            <div className="auth--wrapper--form--content--item">
              <Alert severity={reviewHelper.type}>
                <div
                  dangerouslySetInnerHTML={{ __html: reviewHelper.message }}
                ></div>
              </Alert>
            </div>
          )}
          { Object.keys(reviewExist).length ?
            <>
              <Grid container spacing={2} alignItems="center" justifyContent="center">
                  <Grid item xs={12} md={12} lg={12}>
                    <div
                      style={{
                        width: 200,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Rating
                        name="size-large"
                        defaultValue={reviewExist.rating}
                        size="large"
                        readOnly
                      />
                      {reviewExist.rating !== null && (
                        <Box ml={2}>
                          {labels[reviewExist.rating]}
                        </Box>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <h2> { reviewExist.title }  </h2>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <p> <b> { reviewExist.comment } </b> </p>
                  </Grid>
              </Grid>
            </>
          :
            <>
            
              <form onSubmit={submitRating} method="POST" id="form-review-order" >
                <Grid container spacing={2} alignItems="center" justifyContent="center">
                  
                  <Grid item xs={12} md={12} lg={12}>
                    <div
                      style={{
                        width: 200,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Rating
                        name="size-large"
                        defaultValue={rating}
                        size="large"
                        onChange={(event, newValue) => {
                          setRating(newValue);
                        }}
                        onChangeActive={(event, newHover) => {
                          setHoverRating(newHover);
                        }}
                        disabled={loadingRating || ratingDone}
                      />
                      {rating !== null && (
                        <Box ml={2}>
                          {labels[hoverRating !== -1 ? hoverRating : rating]}
                        </Box>
                      )}
                    </div>
                    {ratingHelper && <p style={{ color: "red" }}>{ratingHelper}</p>}
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <Input
                      disabled={loadingRating || ratingDone}
                      style={{ width: "100%" }}
                      id="outlined-textarea"
                      placeholder="Titre"
                      multiline
                      variant="outlined"
                      name="title"
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <Input
                      disabled={loadingRating || ratingDone}
                      style={{ width: "100%",  }}
                      id="outlined-textarea"
                      placeholder="Commentaire"
                      multiline
                      variant="outlined"
                      name="body"
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <Button
                      type="submit"
                      buttonStyle={"btn--second"}
                      buttonSize="btn--medium--fullwidth"
                      custom="btn--custom"
                      disabled={loadingRating || ratingDone}
                      onClick={submitRating}
                    >
                      {loadingRating ? (
                        <>
                          <CircularProgress color="white" />
                        </>
                      ) : (
                        <>Publier</>
                      )}
                    </Button>
                  </Grid>

                </Grid>
              </form>

            </>
          }
        </div>
      </Modal>
    </>
  );
};
export default RatingModal;
