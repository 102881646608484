import React, { useContext, useEffect, useState } from "react";
import { Container, Grid } from "@material-ui/core";
import { NavLink, Link } from "react-router-dom";
import logoWhite from "../../../assets/images/logo-w.svg";
import insta from "../../../assets/images/instagram.png";
import "./style.scss";
import * as API from "../../../../api";
import WOW from "wow.js";
import { CircularProgress } from "@material-ui/core";


function Footer() {
  const [ settings          , setSettings        ] = React.useState( {} );
  const [auth, setAuth] = useState(false);
  const [loadingAuth, setLoadingAuth] = useState(false);

  React.useEffect(() => {
    setLoadingAuth(true);
    API.Auth()
      .then(({ user }) => {
        setAuth(true);
      })
      .catch(() => {
        setAuth(false);
      })
      .then(() => {
        setLoadingAuth(false);
      });
    API.Settings()
    .then((res)=>{
        setSettings( res.settings );
    })
}, []);

React.useEffect(() => {
  const wow = new WOW();
  wow.init();
}, [])

  return (
    <div className="footer--section ">
      <Container maxWidth="lg">
        <Grid container spacing={4} className="p-top-40 ">
          <Grid item xs={12} md={4} lg={4} className="wow slideInLeft" data-wow-offset="50" data-wow-delay="0.5s">
            <div className="footer--section--item">
              <img src={logoWhite} alt="Tafwija" className="logo-footer" />

              <div className="footer--section--item--description">
                <div dangerouslySetInnerHTML={{__html: settings.aboutus }}></div>
              </div>
            </div>
          </Grid>
        
          <Grid item xs={12} md={4} lg={4} className="wow zoomIn" data-wow-offset="50" data-wow-delay="0.5s">
            <div className="footer--section--item">
              <div className="footer--section--item--p1">
                <h3 className="footer--section--item--p1--head">Informations utiles</h3>
              </div>
              {/* <div className="footer--section--item--insta">
                <img src={insta} alt="" />
                <div className="footer--section--item--insta--overlay">
                  <a href={`${settings.twitter}`} target='_blank'
                    className="footer--section--item--insta--overlay--tag"
                  >
                    <i className="fab fa-instagram"></i> @tafwija
                  </a>
                </div>
              </div> */}
           
              <div className="footer--section--item--p1 ">
                
                <div className="footer--section--item--p1--p">
                <div className="footer--nav--right">
                {!loadingAuth && (
                  <>
                    {!auth && (
                        <NavLink to="/become-partner">
                          <i className="fas fa-briefcase"></i>
                          <span> Devenir partenaire</span>
                        </NavLink>
                    )}
                  </>
                )}
                  
                <NavLink
                  to="/faqs"
                >
                  <i className="fa fa-dot-circle"></i>  
                  <span>Questions fréquentes</span>
                </NavLink>

                <NavLink to={`/contact`}>
                  <i className="fa fa-dot-circle"></i>  
                  <span>contact</span>
                </NavLink>
                
                <NavLink to={`/cgu`}>
                  <i className="fa fa-dot-circle"></i>  
                  <span>Conditions de vente</span>
                </NavLink>
              </div>
                </div>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} md={4} lg={4} className="wow slideInRight" data-wow-offset="50" data-wow-delay="0.5s">
            <div className="footer--section--item">
              <div className="footer--section--item--p1">
                <h3 className="footer--section--item--p1--head">Suivez-nous</h3>
                <div className="footer--section--item--p1--icons">
                <a href={`${settings.facebook}`} target='_blank'
                    className="footer--section--item--p1--icons--icon fb"
                  >
                    <i className="fab fa-facebook"></i>
                  </a>
                  <a href={`${settings.twitter}`} target='_blank'
                    className="footer--section--item--p1--icons--icon insta"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                  {/* <a href={`${settings.linkedin}`} target='_blank'
                    className="footer--section--item--p1--icons--icon in"
                  >
                    <i className="fab fa-linkedin"></i>
                  </a> */}
                </div>
              </div>

              <div className="footer--section--item--p1 p-top-30">
                <h3 className="footer--section--item--p1--head">Mots clés</h3>
                <div className="footer--section--item--p1--p">
                <div dangerouslySetInnerHTML={{__html: settings.footer }}></div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={4} justifyContent="center" className="p-top-40  ">
          <Grid item xs={12} md={12} lg={12}>
            <div className="footer--section--bottom wow zoomIn" data-wow-offset="50" data-wow-delay="0.5s">
              <p>Tafwija All right reserved. Created by <a href="//almossaid.com" target="_blank" ><img src="//almossaid.com/logo/light.png" height="30" style={{marginBottom:'-10px'}}/>Almossaid LLC</a></p>
            </div>
          </Grid>
        </Grid>
      </Container>
      <div className="notif-whatsapp">
      <div className="icon">
        <a
          href={`https://api.whatsapp.com/send?phone=${settings.phone}`}
          target="_blank"><i className="fab fa-whatsapp fa-2x"></i></a>
      </div>
    </div>
    </div>
  );
}

export default Footer;
