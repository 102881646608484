import React from 'react';
import "./style.scss";


const BACKGROUND_COLOR = ['activity', 'voyage']


const Type = (props) => {

  const typeEvent = BACKGROUND_COLOR.includes(props.backgroundColor) ? props.backgroundColor : BACKGROUND_COLOR[0]

  return (
    <div className={`type ${typeEvent}`} >
      <h4>{props.title}</h4>
    </div>
  );
}
export default Type;
