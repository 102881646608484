import React from "react";
import Button from "../../shared/components/UI/Button/Button";
import Modal from "../../shared/components/UI/Modal";
import CancelModal from "./CancelModal";
import EditModal from "./EditModal";
import moment from "moment";

const ModalEditCancel = ({ shown, close, toMore , order }) => {
  const [modalShowCancel, toggleModalShowCancel] = React.useState(false);
  const [modalShowEdit, toggleModalShowEdit] = React.useState(false);
  
  return (
    <>
      <Modal
        shown={shown}
        close={close}
        size={"modal--small"}
        title="Gérer votre résevation"
        subtitle={`Annulez avant le ${moment( (order.date ?? order.date_depart_activity)+' 12:00' ).format('LL à LT')} pour remboursement intégral.`}
      >
        <div className="tafwija--modal--card--body--editCancel">
          <Button 
            type="submit" 
            buttonStyle={"btn--outline"} 
            buttonSize="btn--medium--fullwidth"
            onClick={() => { 
              toggleModalShowEdit(!modalShowEdit); 
              close(!toggleModalShowEdit)
            }}>
            Modifier la réservation
          </Button>
          <div className="p-top-10" />
          <Button 
            type="submit" 
            buttonStyle={"btn--outline"} 
            buttonSize="btn--medium--fullwidth" 
            onClick={() => { 
              toggleModalShowCancel(!modalShowCancel); 
              close(false)
            }}>
            Annuler la réservation
          </Button>
        </div>
      </Modal>

      <CancelModal
        order={order}
        shown={modalShowCancel}
        close={() => {
          toggleModalShowCancel(false);
        }}
      />
      <EditModal
        order={order}
        shown={modalShowEdit}
        close={() => {
          toggleModalShowEdit(false);
        }}
      />
    </>
  );
};
export default ModalEditCancel;
