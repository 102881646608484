import Collapse from "../../../shared/components/UI/Collapse";
import React from "react";

const Features = ({ event }) => {
  const [gathering, setGathering] = React.useState([]);
  const [circuit, setCircuit] = React.useState([]);
  const [included, setIncluded] = React.useState([]);
  const [notIncluded, setNostIncluded] = React.useState([]);

  React.useEffect(() => {
    setGathering(event.gathering);
    setCircuit(event.circuit);
    setIncluded(event.included);
    setNostIncluded(event.not_included);
  }, [0]);
  return (
    <>
      <div className="mobile--experience">
        {event.strong_point.length > 0 && (
          <Collapse
            header={<h4 className="mobile--experience--title">Points forts</h4>}
            children={event.strong_point.map((point, index) => {
              return (
                <div key={index} className="mobile--experience--item--value">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19.018"
                    height="13.092"
                    viewBox="0 0 19.018 13.092"
                  >
                    <g transform="translate(-355 -1256.894)">
                      <rect
                        width="17"
                        height="7"
                        rx="3.5"
                        transform="translate(355 1262.986)"
                        fill="#ffb62c"
                      />
                      <path
                        d="M17.44,6,8.2,15.24,4,11.04"
                        transform="translate(355.447 1252.025)"
                        fill="none"
                        stroke="#083a7b"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.6"
                      />
                    </g>
                  </svg>
                  <p className="mobile--experience--item--value--text">
                    {point}{" "}
                  </p>
                </div>
              );
            })}
          />
        )}

        {gathering.length > 0 && (
          <Collapse
            header={
              <h4 className="mobile--experience--title">Villes de départ</h4>
            }
            children={
              <div className="mobile--experience--item--value">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.018"
                  height="13.092"
                  viewBox="0 0 19.018 13.092"
                >
                  <g transform="translate(-355 -1256.894)">
                    <rect
                      width="17"
                      height="7"
                      rx="3.5"
                      transform="translate(355 1262.986)"
                      fill="#ffb62c"
                    />
                    <path
                      d="M17.44,6,8.2,15.24,4,11.04"
                      transform="translate(355.447 1252.025)"
                      fill="none"
                      stroke="#083a7b"
                      strokeLinecap="round"
                      strokelinejoin="round"
                      strokeWidth="1.6"
                    />
                  </g>
                </svg>
                <p className="mobile--experience--item--value--text">
                  {gathering.map((item, index) => {
                    return <span key={index}> - {item.gathering} </span>;
                  })}
                </p>
              </div>
            }
          />
        )}

        {circuit.length > 0 && (
          <Collapse
            header={<h4 className="mobile--experience--title">Circuit</h4>}
            children={
              <div className="mobile--experience--item--value">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.018"
                  height="13.092"
                  viewBox="0 0 19.018 13.092"
                >
                  <g transform="translate(-355 -1256.894)">
                    <rect
                      width="17"
                      height="7"
                      rx="3.5"
                      transform="translate(355 1262.986)"
                      fill="#ffb62c"
                    />
                    <path
                      d="M17.44,6,8.2,15.24,4,11.04"
                      transform="translate(355.447 1252.025)"
                      fill="none"
                      stroke="#083a7b"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.6"
                    />
                  </g>
                </svg>
                <p className="mobile--experience--item--value--text">
                  {circuit.map((item, index) => {
                    return <span key={index}> - {item.circuit} </span>;
                  })}
                </p>
              </div>
            }
          />
        )}

        {event.type == "Event" ? (
          <Collapse
            header={
              <h4 className="mobile--experience--title">Heure de départ</h4>
            }
            children={
              <div className="mobile--experience--item--value">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.018"
                  height="13.092"
                  viewBox="0 0 19.018 13.092"
                >
                  <g transform="translate(-355 -1256.894)">
                    <rect
                      width="17"
                      height="7"
                      rx="3.5"
                      transform="translate(355 1262.986)"
                      fill="#ffb62c"
                    />
                    <path
                      d="M17.44,6,8.2,15.24,4,11.04"
                      transform="translate(355.447 1252.025)"
                      fill="none"
                      stroke="#083a7b"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.6"
                    />
                  </g>
                </svg>
                <p className="mobile--experience--item--value--text">
                  {event.hour_depart}
                </p>
              </div>
            }
          />
        ) : (
          ""
        )}

        {event.full_description ? (
          <Collapse
            header={
              <h4 className="mobile--experience--title">
                Description complète
              </h4>
            }
            children={
              <div className="mobile--experience--item--value">
                <div className="mobile--experience--item--value--text">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: event.full_description,
                    }}
                  />
                </div>
              </div>
            }
          />
        ) : (
          ""
        )}

        {included.length > 0 && (
          <Collapse
            header={<h4 className="mobile--experience--title">Inclus</h4>}
            children={included.map((item, index) => {
              if (!item) return false;
              return (
                <div key={index} className="mobile--experience--item--value">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19.018"
                    height="13.092"
                    viewBox="0 0 19.018 13.092"
                  >
                    <g transform="translate(-355 -1256.894)">
                      <rect
                        width="17"
                        height="7"
                        rx="3.5"
                        transform="translate(355 1262.986)"
                        fill="#ffb62c"
                      />
                      <path
                        d="M17.44,6,8.2,15.24,4,11.04"
                        transform="translate(355.447 1252.025)"
                        fill="none"
                        stroke="#083a7b"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.6"
                      />
                    </g>
                  </svg>
                  <p className="mobile--experience--item--value--text">
                    {item}
                  </p>
                </div>
              );
            })}
          />
        )}

        {event.type == "Event" ? (
          <>
            {notIncluded.length > 0 && (
              <Collapse
                header={
                  <h4 className="mobile--experience--title">Non inclus</h4>
                }
                children={notIncluded.map((item, index) => {
                  if (!item) return false;
                  return (
                    <div
                      key={index}
                      className="mobile--experience--item--value"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="13.456" height="13.456" viewBox="0 0 13.456 13.456"><path d="M9.279,12.353,13.1,8.528a1.2,1.2,0,0,0,0-1.7l-.85-.85a1.2,1.2,0,0,0-1.7,0L6.728,9.8,2.9,5.977a1.2,1.2,0,0,0-1.7,0l-.85.85a1.2,1.2,0,0,0,0,1.7l3.825,3.825L.352,16.178a1.2,1.2,0,0,0,0,1.7l.85.85a1.2,1.2,0,0,0,1.7,0L6.728,14.9l3.825,3.825a1.2,1.2,0,0,0,1.7,0l.85-.85a1.2,1.2,0,0,0,0-1.7Z" transform="translate(0 -5.625)" fill="#ea0000"></path></svg>
                      <p className="mobile--experience--item--value--text">
                        {item}
                      </p>
                    </div>
                  );
                })}
              />
            )}
          </>
        ) : (
          <>
            {notIncluded.length > 0 && (
              <Collapse
                header={
                  <h4 className="mobile--experience--title">
                    Non Inclus
                  </h4>
                }
                children={notIncluded.map((item, index) => {
                  if (!item) return false;
                  return (
                    <div
                      key={index}
                      className="mobile--experience--item--value"
                    >
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="13.456"
                          height="13.456"
                          viewBox="0 0 13.456 13.456"
                        >
                          <path
                            d="M9.279,12.353,13.1,8.528a1.2,1.2,0,0,0,0-1.7l-.85-.85a1.2,1.2,0,0,0-1.7,0L6.728,9.8,2.9,5.977a1.2,1.2,0,0,0-1.7,0l-.85.85a1.2,1.2,0,0,0,0,1.7l3.825,3.825L.352,16.178a1.2,1.2,0,0,0,0,1.7l.85.85a1.2,1.2,0,0,0,1.7,0L6.728,14.9l3.825,3.825a1.2,1.2,0,0,0,1.7,0l.85-.85a1.2,1.2,0,0,0,0-1.7Z"
                            transform="translate(0 -5.625)"
                            fill="#ea0000"
                          />
                        </svg>
                      <p className="mobile--experience--item--value--text">
                        {item}
                      </p>
                    </div>
                  );
                })}
              />
            )}
          </>
        )}
      </div>

      <div className="web--experience">
        {event.strong_point.length > 0 && (
          <div className="detail--event--wrapper--features--item">
            <h4>Points forts</h4>
            <div className="valuee">
              {event.strong_point.map((point, index) => {
                return (
                  <div
                    key={index}
                    className=" detail--event--wrapper--features--item--value"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19.018"
                      height="13.092"
                      viewBox="0 0 19.018 13.092"
                    >
                      <g transform="translate(-355 -1256.894)">
                        <rect
                          width="17"
                          height="7"
                          rx="3.5"
                          transform="translate(355 1262.986)"
                          fill="#ffb62c"
                        />
                        <path
                          d="M17.44,6,8.2,15.24,4,11.04"
                          transform="translate(355.447 1252.025)"
                          fill="none"
                          stroke="#083a7b"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.6"
                        />
                      </g>
                    </svg>
                    <p className="detail--event--wrapper--features--item--value--text">
                      {point}{" "}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {gathering.length > 0 && (
          <div className="detail--event--wrapper--features--item">
            <h4>Villes de départ</h4>
            <p>
              {gathering.map((item, index) => {
                return <span key={index}> - {item.gathering} </span>;
              })}
            </p>
          </div>
        )}

        {circuit.length > 0 && (
          <div className="detail--event--wrapper--features--item">
            <h4>Circuit</h4>
            <p>
              {circuit.map((item, index) => {
                return <span key={index}> - {item.circuit} </span>;
              })}
            </p>
          </div>
        )}

        {event.type == "Event" ? (
          <div className="detail--event--wrapper--features--item">
            <h4>Heure de départ</h4>
            <div className="valuee">
              <p>{event.hour_depart}</p>
            </div>
          </div>
        ) : (
          ""
        )}

        {event.full_description ? (
          <div className="detail--event--wrapper--features--short">
            <h4>Description complète</h4>
            <div className="valuee detail--event--wrapper--features--short--content">
              <div className="detail--event--wrapper--features--short--content--text">
                <div
                  dangerouslySetInnerHTML={{
                    __html: event.full_description,
                  }}
                />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {included.length > 0 && (
          <div className="detail--event--wrapper--features--item">
            <h4>Inclus</h4>
            <div className="valuee">
              {included.map((item, index) => {
                if (!item) return false;
                return (
                  <div
                    key={index}
                    className=" detail--event--wrapper--features--item--value"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18.221"
                      height="13.466"
                      viewBox="0 0 18.221 13.466"
                    >
                      <path
                        d="M16.564,6,7.926,14.638,4,10.712"
                        transform="translate(-1.172 -3.172)"
                        fill="none"
                        stroke="#10cb00"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="4"
                      />
                    </svg>
                    <p className="detail--event--wrapper--features--item--value--text">
                      {item}{" "}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {event.type == "Event" ? (
          <>
            {notIncluded.length > 0 && (
              <div className="detail--event--wrapper--features--item">
                <h4>Non inclus</h4>
                <div className="valuee">
                  {notIncluded.map((item, index) => {
                    if (!item) return false;
                    return (
                      <div
                        key={index}
                        className=" detail--event--wrapper--features--item--value"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="13.456"
                          height="13.456"
                          viewBox="0 0 13.456 13.456"
                        >
                          <path
                            d="M9.279,12.353,13.1,8.528a1.2,1.2,0,0,0,0-1.7l-.85-.85a1.2,1.2,0,0,0-1.7,0L6.728,9.8,2.9,5.977a1.2,1.2,0,0,0-1.7,0l-.85.85a1.2,1.2,0,0,0,0,1.7l3.825,3.825L.352,16.178a1.2,1.2,0,0,0,0,1.7l.85.85a1.2,1.2,0,0,0,1.7,0L6.728,14.9l3.825,3.825a1.2,1.2,0,0,0,1.7,0l.85-.85a1.2,1.2,0,0,0,0-1.7Z"
                            transform="translate(0 -5.625)"
                            fill="#ea0000"
                          />
                        </svg>
                        <p className="detail--event--wrapper--features--item--value--text">
                          {item}{" "}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            {notIncluded.length > 0 && (
              <div className="detail--event--wrapper--features--item">
                <h4> Non Inclus </h4>
                <div className="valuee">
                  {notIncluded.map((item, index) => {
                    if (!item) return false;
                    return (
                      <div
                        key={index}
                        className=" detail--event--wrapper--features--item--value"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="13.456"
                          height="13.456"
                          viewBox="0 0 13.456 13.456"
                        >
                          <path
                            d="M9.279,12.353,13.1,8.528a1.2,1.2,0,0,0,0-1.7l-.85-.85a1.2,1.2,0,0,0-1.7,0L6.728,9.8,2.9,5.977a1.2,1.2,0,0,0-1.7,0l-.85.85a1.2,1.2,0,0,0,0,1.7l3.825,3.825L.352,16.178a1.2,1.2,0,0,0,0,1.7l.85.85a1.2,1.2,0,0,0,1.7,0L6.728,14.9l3.825,3.825a1.2,1.2,0,0,0,1.7,0l.85-.85a1.2,1.2,0,0,0,0-1.7Z"
                            transform="translate(0 -5.625)"
                            fill="#ea0000"
                          />
                        </svg>
                        <p className="detail--event--wrapper--features--item--value--text">
                          {item}{" "}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};
export default Features;
