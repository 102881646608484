import React from 'react';
import "./style.scss";
import { DriveEtaOutlined } from '@material-ui/icons';

const Carpool = (props) => {
  return (
    <div className="carpool">
      {/* <img src={require('../../../assets/images/car.svg')} alt="Covoiturage"/> */}
      <DriveEtaOutlined />
      <h4>{props.title}</h4>
    </div>
  );
}
export default Carpool;
