import React from "react";
import Card from "../../shared/components/UI/Card";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Banner from "../../shared/components/UI/Banner";
import banner02 from "../../shared/assets/images/banner04.jpg";
import { CircularProgress, InputAdornment } from "@material-ui/core";

import * as API from "../../api";
import { Alert } from "@material-ui/lab";
import { Link, useHistory , useRouteMatch } from "react-router-dom";
import { Call, EmailOutlined } from "@material-ui/icons";
import Input from "../../shared/components/UI/Input";
import Button from "../../shared/components/UI/Button/Button";
import { useSnackbar } from "notistack";

export default function Connection() {
  let intended = localStorage.getItem('intended');

  const { enqueueSnackbar } = useSnackbar();
  
  const route = useRouteMatch();
  const provider = route.params.provider;
  const [loginHelper, setLoginHelper] = React.useState({
    type: "" /* Extent 'severity' props from material ui component */,
    message: "",
  });

  const [ loadingLoginSocial , setLoadingLoginSocial] = React.useState( false );
  const [ token , setToken ] = React.useState("");
  const [ auth , setAuth ] = React.useState({});

  const [emailHelper, setEmailHelper] = React.useState("");
  const [phoneHelper, setPhoneHelper] = React.useState("");

  const [loading, setLoading] = React.useState(false);

  const submitUserUpdate = (event) => {
    setLoading(true);
    setEmailHelper("");
    setPhoneHelper("");
    const formData = new FormData(event.target);
    const form = {};
    let _formAssign = {};
    
    if( !auth.email ){
      _formAssign = Object.assign(form, { email: formData.get("email") }); 
    }
    if( !auth.phone ){
      _formAssign = Object.assign(form, { phone: formData.get("phone") });
    }


    API.ChangeUserInfo(_formAssign ,token)
      .then((res) => {
        //console.log(res);
        enqueueSnackbar("Vos informations ont été mises à jour avec succès", {
          variant: "success",
        });

        setTimeout( async ()=>{
          // Redirecting after 4 seconds by user type
          window.location.href = intended ?? "/";
        },4000);
      })
      .catch((err) => {
        //console.log(err);
        if (err.status === 422) {
          enqueueSnackbar(err.data.message, { variant: "error" });
          if (err.data.errors.phone) {
            setPhoneHelper(err.data.errors.phone);
          }
          if (err.data.errors.email) {
            setEmailHelper(err.data.errors.email);
          }
        }
      })
      .then(() => {
        // End loading
        setLoading(false);
      });

    event.preventDefault();

  }

  React.useEffect(() => {
    //console.log( intended );
    if(provider){
        const queryParams = new URLSearchParams(window.location.search);
        const queryString = queryParams.toString();

        setLoadingLoginSocial(true);
        API.socialLoginCallback(provider , "?" + queryString )
            .then( async (res) =>{
                setLoginHelper( { type : 'success' ,  message : 'Bienvenue 👋 <b>'+res.data.user.name+'</b> <br/><br/> Vous serez dirigé dans un instant 😃...  '  } );
                setAuth( res.data.user );
                setToken( res.data.access_token );
                
                if( res.data.user.phone && res.data.user.email ){
                  setTimeout( async ()=>{
                      // Redirecting after 4 seconds by user type
                      window.location.href = intended ?? "/";
                  },4000);
                
                }
            })
            .catch((err)=>{
                setLoginHelper( { type : 'error' ,  message : err?.data?.errors  } );
                console.log( err.data.errors );
                setTimeout( async ()=>{
                  // Redirecting after 4 seconds by user type
                  window.location.href = intended ?? "/";
                },3000);
            })
            .then(()=>{
              setLoadingLoginSocial(false);
            });
            
    }
  },[0]);

  return (
    <div className="section--auth">
      <Banner size={"200px"} bg={banner02}>
        <Container maxWidth="lg" alignItems="center" justify="center">
          <Grid
           container
            item
            xs={12}
            sm={12}
            alignItems="center"
            justifyContent="center"
            className="h-100-100"
          >
            <div className="tafwija--banner--content">
              <h1>S'identifier/S'inscrire</h1>
              <p>....</p>
            </div>
          </Grid>
        </Container>
      </Banner>

      <Container maxWidth="lg">
        <Grid container spacing={4} alignItems="center" justifyContent="center">
          <Grid item xs={12} md={6} container>
            <Card style={{padding:"5rem 0 "}}>
              {
                    loginHelper.message && (
                      <>
                        {
                          (  Object.keys(auth).length && ( !auth.phone || !auth.email ) ) ?
                          <>
                            <form
                              onSubmit={submitUserUpdate}
                              method="POST"
                              className="auth--wrapper--form--content"
                              >
                                <div className="auth--wrapper--form--content--item">
                                  <p> Veuillez remplir les informations suivantes pour activer votre compte </p>
                                </div>
                                {
                                  (!auth.email) &&
                                    (
                                      <>
                                        <div className="auth--wrapper--form--content--item">
                                          <Input
                                            placeholder={"Votre Email"}
                                            helperText={emailHelper}
                                            error
                                            InputProps={{
                                              endAdornment: (
                                                <InputAdornment position="start">
                                                  <EmailOutlined style={{ color: "#B9BFC6" }} />
                                                </InputAdornment>
                                              ),
                                            }}
                                            type={"email"}
                                            name="email"
                                            disabled={loading}
                                          />
                                        </div>
                                      </>
                                    )
                                }
                                {
                                  (!auth.phone) &&
                                    (
                                      <>
                                        <div className="auth--wrapper--form--content--item">
                                          <Input
                                            placeholder={"Téléphone"}
                                            helperText={phoneHelper}
                                            error
                                            InputProps={{
                                              endAdornment: (
                                                <InputAdornment position="start">
                                                  <Call style={{ color: "#B9BFC6" }} />
                                                </InputAdornment>
                                              ),
                                            }}
                                            type={"phone"}
                                            name="phone"
                                            disabled={loading}
                                          />
                                        </div>     
                                      </>
                                    )
                                }
                                <div className="auth--wrapper--form--content--item">
                                  <Button
                                    buttonSize={"btn--large"}
                                    disabled={loading}
                                  >
                                    {loading ? (
                                      <>
                                        <CircularProgress color="white" />
                                      </>
                                    ) : (
                                      <> Enregistrer </>
                                    )}
                                  </Button>
                                </div>
                            </form>
                          </>
                          :
                          <>
                            <div className="auth--wrapper--form--content--item">
                              <Alert severity={loginHelper.type} > <div dangerouslySetInnerHTML={{__html: loginHelper.message}}></div> </Alert>
                            </div>
                          </> 
                        }
                          
                      </>
                    )  
                }
                {
                    loadingLoginSocial &&
                    <div className="auth--wrapper--form--content--item">
                        <br/>
                        <CircularProgress color="white" />
                    </div>
                }
            </Card>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
