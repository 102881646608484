

import React, {useState} from 'react'
import { FiChevronsDown,FiChevronsUp } from "react-icons/fi";
import Card from "../UI/Card";
import { Grid } from '@material-ui/core';


const Item = (props) => {
  const [showDetails, setShowDetails] = useState(false);
    return (
      <Card cardStyle='booking--card'>
         <div className="booking--card--head">
            <div className="booking--card--head--left">
              <div className="booking--card--head--left--image">
                <img src={props.data.event.image} alt={props.data.event.title} />
              </div>
              <div className="booking--card--head--left--title">
                <h4 className="booking--card--head--left--title--txt">
                  <span>{props.data.event.title}</span>
                  {props.eventType}
                </h4>
                {props.status}
              </div>
            </div>
            <div className="booking--card--head--right">
              {props.actions}
            </div>
         </div>
         {showDetails ? (
           <div className="booking--card--infos">{props.infos}</div>
         ) 
         : null}
         <div className="booking--card--toggle" onClick={() => setShowDetails(!showDetails)}>
            <h3>Afficher les détails </h3>
            { showDetails ?  <FiChevronsUp /> : <FiChevronsDown /> }
         </div>

         
            
      </Card>
    )
}
export default Item