import React from 'react';
import "./style.scss";
// import { FiClock, FiCheckCircle, FiXOctagon } from "react-icons/fi";

// const confirmed = () => <FiCheckCircle />;
// const pending = () => <FiClock />;
// const canceled = () => <FiXOctagon />;

const STATUS = [ 'pending','confirmed','canceled']
// const ICONS = [ <FiClock />,<FiCheckCircle />, <FiXOctagon />]

const Status = (props) => {

  const checkStatus = props.type ? props.type : STATUS[0]

  return (
    <div className={`status--wrap status--${checkStatus}`} >
      {props.icon}
      <h4>{props.title}</h4>
    </div>
  );
}
export default Status;
