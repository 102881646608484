import React from "react";
import Collapse from "../../shared/components/UI/Collapse";
import { Grid, Container, CircularProgress } from "@material-ui/core";
import Banner from "../../shared/components/UI/Banner";
import banner02 from "../../shared/assets/images/banner04.jpg";
import * as API from "../../api";

const CGU = () => {

  const [ data        , setData        ] = React.useState([]);
  const [ loadingData , setLoadingData ] = React.useState(false);

  React.useEffect(()=>{
    
    setLoadingData(true);
    API.CGU()
      .then((res)=>{
        //console.log(res.data);
        setData( res.data );
      })
      .catch((err)=>{
        console.log(err);
      })
      .then(()=>{
        setLoadingData(false);
      });

  },[0]);

  return (
    <div className="section--faqs">
      <Banner size={"240px"} bg={banner02}>
        <Container maxWidth="lg" alignItems="center" justify="center">
          <Grid
            container
            item
            xs={12}
            sm={12}
            alignItems="center"
            justifyContent="center"
            className="h-100-100"
          >
            <div className="tafwija--banner--content">
              <h1> Conditions générales de vente </h1>
            </div>
          </Grid>
        </Container>
      </Banner>

      <div className="p-top-40 p-bottom-40 cgu--page">
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs={12} md={10}>
            <div className="cgu--page--head">
              <h2>Conditions générales de vente</h2>

              <p>
                La société Tafwija, en tant qu’intermédiaire, accompagne sur
                cette plateforme de réservation en ligne, visiteurs et
                prestataires d’activités touristiques.
              </p>
            </div>
          </Grid>

          <Grid item xs={12} md={10}>
            
          {
            loadingData ?
            <>
              <CircularProgress />
            </>
            :
            <>
              <Grid item xs={12} md={10}>
                {data.map((item, index) => {
                  return (
                    <Collapse
                      key={index}
                      header={
                        <h3 className="cgu--page--title" > {item.title} </h3>
                      }
                    >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.description,
                          }}
                        />
                     
                      
                    </Collapse>
                  );
                })}
              </Grid>
            </>
          }
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default CGU;
