import React, { useState } from "react";

import Ratings from "../../../shared/components/UI/Ratings";
//import ReactReadMoreReadLess from "react-read-more-read-less";

const Review = ( { data } ) => {
  const review = data ?? {} ;

  return (
    <div className="card--review m-top-20">
      <div className="card--review--heading">
        <div className="card--review--heading--left">
          <h2>{ review.title }</h2>
        </div>
        <Ratings value={review.rating} readOnly />
      </div>
      <div className="card--review--body">
        <p className="extra-content"> { review.comment } </p>
      </div>
      <div className="card--review--footer">
       {
        review.user && review.user.logo ? 
          <img src={review.user.logo} alt="" /> 
          : 
          <div className="card--review--footer--custom">
            <h5>{review.user?.name?.charAt(0)}</h5>
          </div>
       }

       <h6>{review.user?.name}</h6>
      </div>
    </div>
  );
};
export default Review;
