import React, {useEffect} from "react";
import { Container, Grid } from "@material-ui/core";
import WOW from "wow.js";

import why1 from "../../shared/assets/images/icon1.svg"
import why2 from "../../shared/assets/images/icon2.svg"
import why3 from "../../shared/assets/images/icon3.svg"


const tabItems = [
  {
    id: 1,
    title: 'Annulation Gratuite',
    icon: why1,
    content: 'Annulez gratuitement jusqu’à 24h avant le début de votre activité',
  },
  {
    id: 2,
    title: 'Tarifs imbattables',
    icon: why2,
    content: 'On vous sélectionne les meilleures activités avec les meilleurs prix',
  },
  {
    id: 3,
    title: 'Service client',
    icon: why3,
    content: 'Service client très réactif pour vous aidez et répondre à toutes vos questions',
  },
  
];

const OurValues = () => {
  const [active, setActive] = React.useState(1);

  useEffect(() => {
    const wow = new WOW();
    wow.init();
  }, []);


  const TabItemComponent = ({
    icon = '',
    onItemClicked = () => console.error('You passed no action to the component'),
    isActive = false,
  }) => {
    return (
      <div className={isActive ? 'tabitem' : 'tabitem tabitem--inactive'} onClick={onItemClicked}>
        <img src={icon} alt="" />
      </div>
    )
  };

  return (
    <div className="our--values p-top-30 p-bottom-30">
      <div className="our--values--web">
      <Container maxWidth="lg" >
        <Grid container  justifyContent="center" className=" ">
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <div className="our--values--item wow slideInLeft" data-wow-offset="50" data-wow-delay="0.5s">
              <div className="our--values--item--head">
                <img src={why1} alt="" />
              </div>
              <div className="our--values--item--content">
                <h3>Annulation Gratuite</h3>
                <p>Annulez gratuitement jusqu’à 24h avant le début de votre activité</p>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <div className="our--values--item wow zoomIn" data-wow-offset="50" data-wow-delay="0.5s">
              <div className="our--values--item--head">
                <img src={why2} alt="" />
              </div>
              <div className="our--values--item--content">
                <h3>Tarifs imbattables</h3>
                <p>On vous sélectionne les meilleures activités avec les meilleurs prix</p>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <div className="our--values--item wow slideInRight" data-wow-offset="50" data-wow-delay="0.5s">
              <div className="our--values--item--head">
                <img src={why3} alt="" />
              </div>
              <div className="our--values--item--content">
                <h3>Service client</h3>
                <p>Service client très réactif pour vous aidez et répondre à toutes vos questions</p>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
      </div>
     <div className="our--values--mobile">
     <Container maxWidth="lg" >
     <div className="our--values--mobile--tabs">
        {tabItems.map(({ id, icon, title }) =><TabItemComponent
           key={title}
           icon={icon}
           title={title}
           onItemClicked={() => setActive(id)}
           isActive={active === id}
         />
      )}
      </div>
      <div className="our--values--mobile--content">
        {tabItems.map(({ id, content, title }) => {
          return active === id ? <React.Fragment key="{id}"><h3>{title}</h3> <p>{content}</p> </React.Fragment> : ''
        })}
      </div>
      </Container>
     </div>

    </div>
  );
}

export default OurValues;
