import React from "react";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import CheckoutCard from "../../shared/components/CheckoutCard";
import Button from '../../shared/components/UI/Button/Button';

import * as API from '../../api';
import { Box, CircularProgress, Container, Grid, TextField } from "@material-ui/core";
import { Alert, Rating } from "@material-ui/lab";

const labels = {
    1: 'inutile',
    2: 'Pauvre',
    3: 'Ok',
    4: 'Bon',
    5: 'Excellent',
};


function Row(props) {
    const { order } = props;
    const [ open          , setOpen          ] = React.useState(false);

    const [ ratingDone    , setRatingDone    ] = React.useState(false);
    const [ rating        , setRating        ] = React.useState(0);
    const [ hoverRating   , setHoverRating   ] = React.useState(0);
    const [ comment       , setComment       ] = React.useState(null);
    const [ loadingRating , setLoadingRating ] = React.useState(false);
    const [ reviewHelper  , setReviewHelper  ] = React.useState({ type : '' /* Extent 'severity' props from material ui component */ , message : '' });
    const [ ratingHelper  , setRatingHelper  ] = React.useState('');



    const submitRating = () => {
        setRatingHelper('');

        let _form = {
            event_id : order.id ,
            rating   : rating ,
            comment  : comment
        }

        if( !rating || rating == 0 ){
            setRatingHelper('The rating is required');
        }else{

            setLoadingRating(true);
            
            API.SendReview( _form )
                .then((res)=>{
                    setReviewHelper( { type : 'success' , message : res.message } );
                    setRatingDone(true)
                    // console.log(res.message);
                })
                .catch((err)=>{
                    setReviewHelper( { type : 'error' , message : err.data.errors } );
                    console.log(err);
                })
                .then(()=>{
                    setLoadingRating(false);
                });
        }
        
    }
    React.useEffect(()=>{
        
    },[0]);
    return (
        <React.Fragment>
        <TableRow>
            <TableCell component="th" scope="row">
            <span className="c-main">{order.title}</span>
            </TableCell>
            <TableCell align="right">
            <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
            >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
            </TableCell>  
        </TableRow>
        <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={2}>
            <Collapse in={open} timeout="auto" unmountOnExit className="collapsTable">
                {
                    reviewHelper.message &&
                    <div className="auth--wrapper--form--content--item">
                        <Alert severity={reviewHelper.type} > <div dangerouslySetInnerHTML={{__html: reviewHelper.message}}></div> </Alert>
                    </div>
                }
                <Grid container spacing={2} alignItems="center" justifyContent="center">
                    <Grid item xs={12} md={12} lg={12} >
                        <h3>Veuillez noter l'événement et mettre un commentaire</h3>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} >
                        <div style={{
                                width: 200,
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                            <Rating
                                name="size-large"
                                defaultValue={rating}
                                size="large"
                                onChange={(event, newValue) => { setRating(newValue); }}
                                onChangeActive={(event, newHover) => {setHoverRating(newHover) }}
                                disabled={ loadingRating || ratingDone }
                                />
                            {rating !== null && <Box ml={2}>{labels[hoverRating !== -1 ? hoverRating : rating]}</Box>}
                        </div>
                        { ratingHelper && <p style={{ color: 'red'}} >{ratingHelper}</p> }
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} >
                        <TextField
                            disabled={ loadingRating || ratingDone }
                            style={{width:'100%'}}
                            id="outlined-textarea"
                            placeholder="review comment"
                            multiline
                            variant="outlined"
                            onChange={(event)=>{ setComment(event.target.value) }}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} >
                        <Button buttonSize={'btn--large'} disabled={ loadingRating || ratingDone } onClick={submitRating} >
                            {
                                loadingRating ?
                                <>
                                    <CircularProgress color='white' />
                                </>
                                :
                                <>
                                    Voté   
                                </>
                            }
                        </Button>
                    </Grid>
                </Grid>
            </Collapse>
            </TableCell>
        </TableRow>
        </React.Fragment>
    );
}

const Review = (props) => {
    const [ loadingOrders     , setLoadingOrders ] = React.useState(false);
    const [ orders            , setOrders        ] = React.useState([]);

    React.useEffect(()=>{
        setLoadingOrders(true);
        API.EventsForReview()
            .then((res)=>{
                //console.log( res.data );
                setOrders( res.data );
                
            })
            .catch((err)=>{
                console.error(err);
            })
            .then(()=>{
                setLoadingOrders(false);
            });

    },[0]);
    return (
        <TableContainer  className="tafwija--table">
        <Table aria-label="collapsible table">
            <TableHead>
            <TableRow>
                <TableCell colSpan={2} >Vote & Feedback</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {
                loadingOrders ?
                <TableRow>
                <TableCell colSpan={2}>
                    <CircularProgress />
                </TableCell>
                </TableRow>
                :
                <>
                {
                    orders.length ?
                    <>
                    {
                    orders.map((order,index) => (
                    <Row key={index} order={order} />
                    ))
                    }
                    </>
                    :
                    <TableRow>
                        <TableCell>
                            Vous n'avez aucun élément pour le noter
                        </TableCell>
                    </TableRow>
                }
                </>
            }
            </TableBody>
        </Table>
        </TableContainer>
    );
};
export default Review;
