import React, { useState, useEffect } from "react";
import Carousels from "../../UI/Carousel";
import Grid from "@material-ui/core/Grid";
import Event from "../Event";
import "../style.scss";
import { Link } from "react-router-dom";

import * as API from "../../../../api";
import { CircularProgress } from "@material-ui/core";

const EventCarousel = (props) => {
  const [events, setEvents] = useState([]);
  const [page, setPage] = useState(1);
  const [loadingEvents, setLoadingEvents] = useState(false);

  const getPrice = (prices) => {
    let filteredPrices = prices.filter((item) => item.default == "1");
    if (filteredPrices.length > 0) {
      let price = filteredPrices[0].price;
      return API.priceFormat(price);
    } else {
      return 0;
    }
  };

  const getPriceDescription = (prices) => {
    let filteredPrices = prices.filter((item) => item.default == "1");
    if (filteredPrices.length > 0) {
      return filteredPrices[0].description ?? "";
    } else {
      // Handle the case where no matching item is found
      return "";
    }
  };



  useEffect(() => {
    setLoadingEvents(true);
    API.Events("Event", page)
      .then((res) => {
        setEvents(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .then(() => {
        setLoadingEvents(false);
      });
  }, [0]);

  return (
    <>
      {loadingEvents ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ margin: "3rem 0" }}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <Carousels
          items={events.map((event, index) => {
            return (
              <Grid key={event.id} item md={12} container>
                <Link
                  to={`/event/${event.id}?slug=${event.slug}`}
                  className="link--events"
                >
                  <Event
                    data={event}
                    priceDescription={getPriceDescription(event.prices)}
                    price={getPrice(event.prices)}
                    cityDepart={event.gathering.map((d, index) => (
                      <span className="events--circuit" key={index}>
                        {" "}
                        <i className="events--circuit--divider">-</i>{" "}
                        {d.gathering}{" "}
                      </span>
                    ))}
                    circuit={event.circuit.map((c, index) => (
                      <span className="events--circuit" key={index}>
                        {" "}
                        <i className="events--circuit--divider">-</i>{" "}
                        {c.circuit}{" "}
                      </span>
                    ))}
                    key={index}
                  />
                </Link>
              </Grid>
            );
          })}
          NumberOfDesktop={3}
          NumberOfTablet={2}
        />
      )}
    </>
  );
};
export default EventCarousel;
