import React from 'react';
import "./style.scss";
// import { DriveEtaOutlined } from '@material-ui/icons';

const BACKGROUND_COLOR = ['badge--danger', 'badge--success','badge--pending']


const Badge = (props) => {

  const checkBackgroundColor = BACKGROUND_COLOR.includes(props.backgroundColor) ? props.backgroundColor : BACKGROUND_COLOR[0];
  const checkSpecificBackgroundColor = props.specificBackgroundColor ? props.specificBackgroundColor : null;

  return (
    <div className={`badge ${checkBackgroundColor}`} style={{backgroundColor : checkSpecificBackgroundColor}}>
      <h4>{props.title}</h4>
    </div>
  );
}
export default Badge;
