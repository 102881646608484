import React from 'react'
import '../style.scss'


const Item = (props) => {
    const monthNames = ["Janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"];
    const date_form_fr = (date) => {
        return `${new Date(date).getUTCDay() } ${monthNames[new Date(date).getMonth()]} ${new Date(date).getFullYear()} `;
    }
    return (
        <div className="card--item--post">
            <div className="card--item--post--image">
                <img src={props.data.image} alt={props.data.title} />
            </div>
            <div className="card--item--post--content">
                <div className="card--item--post--content--head">
                    <h3> { date_form_fr(props.data.created_at) } </h3>
                    <h1>{props.data.title}</h1>
                </div>
            
                <div className="card--item--post--content--desc">
                    {props.description}
                </div>

            </div>
        </div>

    )
}
export default Item