import React from "react";
import {
  CircularProgress,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import {
  EmailOutlined,
  LockOutlined,
  PermIdentity,
  PermIdentityOutlined,
  Schedule,
  Visibility,
  VisibilityOff,
} from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import PersonIcon from "@material-ui/icons/Person";
import Input from "../../shared/components/UI/Input";
import Title from "../../shared/components/UI/Title";
import Button from "../../shared/components/UI/Button/Button";
import Card from "../../shared/components/UI/Card";

import * as API from "../../api";
import { Alert } from "@material-ui/lab";
import { useHistory } from "react-router-dom";

import { useSnackbar } from "notistack";

const Edit = (props) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [auth, setAuth] = React.useState({});

  const [loadingAuth, setLoadingAuth] = React.useState(false);
  const [loadingSubmitAuthInfo, setLoadingSubmitAuthInfo] =
    React.useState(false);

  const [authInfoHelper, setAuthInfoHelper] = React.useState({
    type: "" /* Extent 'severity' props from material ui component */,
    message: "",
  });

  const [nameHelper, setNameHelper] = React.useState("");
  const [emailHelper, setEmailHelper] = React.useState("");
  const [password_oldHelper, setPasswordOldHelper] = React.useState("");
  const [passwordHelper, setPasswordHelper] = React.useState("");
  const [password_confirmationHelper, setPasswordConfirmationHelper] =
    React.useState("");

  const submitAuthInfo = (event) => {
    // Set helper null
    setAuthInfoHelper({ type: "", message: "" });
    setNameHelper("");
    setEmailHelper("");
    setPasswordOldHelper("");
    setPasswordHelper("");
    setPasswordConfirmationHelper("");

    // Star loading form
    setLoadingSubmitAuthInfo(true);

    const formData = new FormData(event.target);
    const form = {
      name: formData.get("name"),
      email: formData.get("email"),
      oldPassword: formData.get("password_old"),
      password: formData.get("password"),
      password_confirmation: formData.get("password_confirmation"),
      country: formData.get("country"),
      city: formData.get("city"),
      company: formData.get("company"),
      phone: formData.get("phone"),
      address: formData.get("address"),
      code_postal: formData.get("code_postal"),
    };

    // check if password is correct
    if (form.password && form.password !== form.password_confirmation) {
      setPasswordConfirmationHelper("Votre mot de passe est incorrect");
    }
    //console.log( form );

    API.ChangeUserInfo(form)
      .then((res) => {
        //console.log(res);
        setAuthInfoHelper({
          type: "success",
          message: "Vos informations ont été mises à jour avec succès",
        });
        enqueueSnackbar("Vos informations ont été mises à jour avec succès", {
          variant: "success",
        });
        if (form.oldPassword !== form.password) {
          history.push("/logout?target=login");
        }
      })
      .catch((err) => {
        //console.log(err);
        if (err.status === 422) {
          setAuthInfoHelper({ type: "error", message: err.data.message });
          enqueueSnackbar(err.data.message, { variant: "error" });
          if (err.data.errors.name) {
            setNameHelper(err.data.errors.name);
          }
          if (err.data.errors.email) {
            setEmailHelper(err.data.errors.email);
          }
          if (err.data.errors.oldPassword) {
            setPasswordOldHelper(err.data.errors.oldPassword);
          }
          if (err.data.errors.password) {
            setPasswordHelper(err.data.errors.password);
          }
          if (err.data.errors.password_confirmation) {
            setPasswordConfirmationHelper(
              err.data.errors.password_confirmation
            );
          }
        }
      })
      .then(() => {
        // End loading
        setLoadingSubmitAuthInfo(false);
      });

    event.preventDefault();
  };
  React.useEffect(() => {
    setLoadingAuth(true);
    API.Auth()
      .then((res) => {
        //console.log( res.user );
        setAuth(res.user);
      })
      .catch((err) => {
        //console.log(err);
      })
      .then(() => {
        setLoadingAuth(false);
      });
  }, [0]);
  return (
    <>
      <form
        onSubmit={submitAuthInfo}
        method="POST"
        className="account--edit--infos "
      >
        {authInfoHelper.message && (
          <div className="auth--wrapper--form--content--item m-bottom-20">
            <Alert severity={authInfoHelper.type}>
              {" "}
              <div
                dangerouslySetInnerHTML={{ __html: authInfoHelper.message }}
              ></div>{" "}
            </Alert>
          </div>
        )}
        {loadingAuth ? (
          <>
            <Grid
              container
              item
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <CircularProgress />
            </Grid>
          </>
        ) : (
          <>
            <div maxWidth="lg">
              <Grid container spacing={2} alignItems="center" justifyContent="center">
                <Grid item xs={12} md={12} lg={12} className="m-bottom-20">
                  <Title title="Détails du compte" mode="medium" />
                  <br />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <div className="account--edit--infos--item">
                    <Input
                      defaultValue={auth.name}
                      placeholder={"Nom et prénom"}
                      helperText={nameHelper}
                      error
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <PersonIcon style={{ color: "#B9BFC6" }} />
                          </InputAdornment>
                        ),
                      }}
                      type={"text"}
                      name="name"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <div className="account--edit--infos--item">
                    <Input
                      defaultValue={auth.email}
                      placeholder={"Votre Email"}
                      helperText={emailHelper}
                      error
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <EmailOutlined style={{ color: "#B9BFC6" }} />
                          </InputAdornment>
                        ),
                      }}
                      type={"email"}
                      name="email"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <div className="account--edit--infos--item">
                    <Input
                      title={"Mot de passe actuel"}
                      helperText={password_oldHelper}
                      error
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <LockOutlined style={{ color: "#B9BFC6" }} />
                          </InputAdornment>
                        ),
                      }}
                      type={"password"}
                      name="password_old"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <div className="account--edit--infos--item">
                    <Input
                      title={"Nouveau mot de passe"}
                      helperText={passwordHelper}
                      error
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <LockOutlined style={{ color: "#B9BFC6" }} />
                          </InputAdornment>
                        ),
                      }}
                      type={"password"}
                      name="password"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <div className="account--edit--infos--item">
                    <Input
                      title={"Confirmer le nouveau mot de passe"}
                      helperText={password_confirmationHelper}
                      error
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <LockOutlined style={{ color: "#B9BFC6" }} />
                          </InputAdornment>
                        ),
                      }}
                      type={"password"}
                      name="password_confirmation"
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
            <div maxWidth="lg" className="m-top-20">
              <Grid container spacing={2} alignItems="center" justifyContent="center">
                <Grid item xs={12} md={12} lg={12} className="m-bottom-20">
                  <Title title="Adresse de facturation" mode="medium" />
                  <br />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <div className="account--edit--infos--item">
                    <Input
                      defaultValue={auth.country}
                      placeholder="Pays/région"
                      title={"Pays/région"}
                      helperText={""}
                      error
                      name="country"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <div className="account--edit--infos--item">
                    <Input
                      defaultValue={auth.city}
                      placeholder="ddVille"
                      title={"Ville"}
                      helperText={""}
                      error
                      name="city"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <div className="account--edit--infos--item">
                    <Input
                      defaultValue={auth.company}
                      placeholder="Mon de l'entreprise (facturatif)"
                      title={"Mon de l'entreprise (facturatif) "}
                      helperText={""}
                      error
                      name="company"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Téléphone</InputLabel>
                    <OutlinedInput
                      placeholder="Téléphone"
                      defaultValue={auth.phone}
                      /*
                    endAdornment={
                      <InputAdornment position="start">
                        <IconButton
                          onClick={()=>{ history.push('phone-confirmation') }}
                          edge="end"
                        >
                          <EditIcon />
                        </IconButton>
                        
                      </InputAdornment>
                    }
                    */
                      labelWidth={60}
                      name="phone"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <div className="account--edit--infos--item">
                    <Input
                      defaultValue={auth.address}
                      placeholder="Adresse"
                      title={"Adresse"}
                      helperText={""}
                      error
                      name="address"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <div className="account--edit--infos--item">
                    <Input
                      defaultValue={auth.code_postal}
                      placeholder="Code postal"
                      title={"Code postal"}
                      helperText={""}
                      error
                      name="code_postal"
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="account--edit--infos--item p-top-20">
              <Button
                buttonSize={"btn--large"}
                disabled={loadingSubmitAuthInfo}
              >
                {loadingSubmitAuthInfo ? (
                  <>
                    <CircularProgress color="while" />
                  </>
                ) : (
                  <>Enregistrer</>
                )}
              </Button>
            </div>
          </>
        )}
      </form>
    </>
  );
};
export default Edit;
