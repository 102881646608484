import React from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Banner from "../../shared/components/UI/Banner";
import banner02 from "../../shared/assets/images/banner04.jpg";
import EventGrid from "../../shared/components/EventsCard/EventGrid";
import SearchBar from "../../shared/components/SearchBar";

import empty_xct9 from "../../shared/assets/images/empty_xct9.svg";

import * as API from "../../api";
import { Link, useLocation } from "react-router-dom";
import { Pagination } from "@material-ui/lab";
import { CircularProgress } from "@material-ui/core";
import Event from "../../shared/components/EventsCard/Event";
import { get } from "lodash";
const Events = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const _page = parseInt(query.get("page") || "1", 10);
  const type = query.get("type");

  const [loadingEvents, setLoadingEvent] = React.useState(false);
  const [events, setEvents] = React.useState([]);
  const [page, setPage] = React.useState(_page);
  const [pages, setPages] = React.useState(0);

  const loadEvenets = (_type = type, _page) => {
    setLoadingEvent(true);
    if (location.pathname == "/search") {
      const query = new URLSearchParams(location.search);
      let city = query.get("city");
      let category = query.get("category");
      let date_start = query.get("date_start");
      let date_end = query.get("date_end");
      let _form = {
        from: date_start,
        to: date_end,
        categorie_id: category,
      };
      if (city !== "all") {
        Object.assign(_form, { citie: city });
      }

      API.Search(_form, _page)
        .then((res) => {
          setEvents(res.data);
          setPages(res.meta.last_page);
        })
        .catch((err) => {
          //console.log( err );
        })
        .then(() => {
          setLoadingEvent(false);
        });
    } else {
      API.Events(_type, _page)
        .then((res) => {
          //console.log( res.data );
          setEvents(res.data);
          setPages(res.meta.last_page);
        })
        .catch((err) => {
          //console.log( err );
        })
        .then(() => {
          setLoadingEvent(false);
        });
    }
  };
  const handleChangePagination = (value) => {
    // Set the curent page
    setPage(value);
    // make the connection to load the next page by value
    loadEvenets(type, value);
    // push value of page to the link
    //console.log( window.history );
    window.history.pushState(
      {},
      "",
      (type ? `?type=${type}&` : "?") + "page=" + value
    );
  };

  const getPrice = (prices) => {
    let filteredPrices = prices.filter((item) => item.default == "1");
    if (filteredPrices.length > 0) {
      let price = filteredPrices[0].price;
      return API.priceFormat(price);
    } else {
      return 0;
    }
  };

  const getPriceDescription = (prices) => {
    let filteredPrices = prices.filter((item) => item.default == "1");
    if (filteredPrices.length > 0) {
      return filteredPrices[0].description ?? "";
    } else {
      return "";
    }
  };


  React.useEffect(() => {
    loadEvenets(type, page);

    // Set title page
    let pageTitle = "Events";
    if (location.pathname == "/search") {
      pageTitle = "Search";
    }
    document.title = API.APP_NAME + " - " + pageTitle;
  }, [0, type, _page]);

  return (
    <div className="section--events">
      <Banner size={"240px"} bg={banner02}>
        <Container maxWidth="lg"  justify="center">
          <Grid
            item
            xs={12}
            sm={12}
            className="h-100-100"
          >
            <div className="tafwija--banner--content">
              <h1>Voyages, Séjours & activités</h1>
              <p>Réservez en deux clicks des expériences inoubliables</p>
            </div>
          </Grid>
        </Container>
      </Banner>
      <Container
        maxWidth="lg"
        justify="center"
        className="searchBar--events h-100-100"
      >
        <Grid item xs={12} sm={12} >
          <SearchBar />
        </Grid>
      </Container>

      <Container maxWidth="lg" className="p-top-60 p-bottom-40">
        <Grid spacing={2} container justifyContent="flex-start" alignItems="flex-start">
          {loadingEvents ? (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{ margin: "3rem 0" }}
            >
              <CircularProgress />
            </Grid>
          ) : (
            <>
              {events.length ? (
                <>
                  {events.map((event) => {
                    return (
                      <Grid key={event.id} item xs={12} sm={6} md={4} container>
                        <Link
                          to={`event/${event.id}?slug=${event.slug}`}
                          className="link--events"
                        >
                          <Event
                            data={event}
                            price={getPrice(event.prices)}
                            priceDescription={getPriceDescription(event.prices)}
                            cityDepart={
                              event.type != "Sejour" ? (
                              event.gathering.map((d, index) => (
                              <span className="events--circuit" key={index}>
                                {" "}
                                <i className="events--circuit--divider">
                                  -
                                </i>{" "}
                                {d.gathering}{" "}
                              </span>
                            ))
                              ) : (
                                <span className="events--circuit">
                                {" "}
                                <i className="events--circuit--divider">
                                  -
                                </i>{" "}
                                {event.citie}{" "}
                              </span>
                              )
                          }
                            circuit={event.circuit.map((c, index) => (
                              <span className="events--circuit" key={index}>
                                {" "}
                                <i className="events--circuit--divider">
                                  -
                                </i>{" "}
                                {c.circuit}{" "}
                              </span>
                            ))}
                            type={
                              event.type == "Activitie" ? (
                                <div className="ribbon-wrapper">
                                  <div className="ribbon bg--yellow">Activité</div>
                                </div>
                              ) : event.type == "Sejour" ? (
                                <div className="ribbon-wrapper">
                                  <div className="ribbon bg--green">Séjour</div>
                                </div>
                              ) :  (
                                <div className="ribbon-wrapper">
                                  <div className="ribbon bg--blue">Voyage</div>
                                </div>
                              )
                            }
                          />
                        </Link>
                      </Grid>
                    );
                  })}
                </>
              ) : (
                <>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    className="p-top-40 p-bottom-40"
                  >
                    <Grid item xs={12} md={12} container>
                      <img src={empty_xct9} height="400" className="m0auto" />
                    </Grid>
                    <Grid item xs={12} md={12} container>
                      <h1 className="p-top-40 m0auto">
                        {" "}
                        il n'y a pas encore de données{" "}
                      </h1>
                    </Grid>
                  </Grid>
                </>
              )}
            </>
          )}
        </Grid>

        {events.length && pages > 0 ? (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{ margin: "2rem 0" }}
          >
            <Pagination
              count={pages}
              page={page}
              size="large"
              onChange={(e, value) => {
                handleChangePagination(value);
              }}
            />
          </Grid>
        ) : (
          <></>
        )}
      </Container>
    </div>
  );
};
export default Events;
