import React from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Button from "../../shared/components/UI/Button/Button";
import Banner from "../../shared/components/UI/Banner";
import banner02 from "../../shared/assets/images/banner04.jpg";
import Card from "../../shared/components/UI/Card";
import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@material-ui/core";

import * as API from "../../api";
import { useHistory } from "react-router-dom";
import { Done } from "@material-ui/icons";

//import DateFnsUtils from '@date-io/date-fns';
import moment from "moment";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import "moment/locale/fr";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    width: "100%",
    marginTop: "0px",
    marginBottom: 20,
  },
  reservedDate: {
    color: "red", // Customize the color for reserved dates
  },
}));

const Booking = () => {
  const classes = useStyles();
  const history = useHistory();
  const [gift] = React.useState(JSON.parse(localStorage.getItem("gift")) || {});
  const [event] = React.useState(
    JSON.parse(localStorage.getItem("order")) || {}
  );
  const [placesAvailable, setPlacesAvailable] = React.useState(
    event.places_available
  );

  const [prices, setPrices] = React.useState([]);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [selectedTime, setSelectedTime] = React.useState("");

  const [cityStart, setCityStart] = React.useState(null);
  const [roader, setRoader] = React.useState(null);

  const [loadingSettings, setLoadingSettings] = React.useState(false);
  const [settings, setSettings] = React.useState({});
  const [roaders, setRoaders] = React.useState([]);

  const [couponName, setCouponName] = React.useState("");
  const [coupon, setCoupon] = React.useState({});
  const [couponHelper, setCouponHelper] = React.useState("");
  const [loadingCoupon, setLoadingCoupon] = React.useState(false);
  const [addCoupon, setAddCoupon] = React.useState(false);

  const [check_in, setCheck_in] = React.useState(
    new Date().toISOString().slice(0, 16)
  );

  const [check_out, setCheck_out] = React.useState(
    new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 16)
  );

  const totalPrice = (_prices, undescount = false) => {
    let total = 0;
    let _total = total;
    _prices.forEach((price) => {
      total = total + price.total_price;
    });
    if (undescount) {
      let _coupon = coupon.value ?? 0;
      _total = total - (total / 100) * _coupon;
    } else {
      _total = total;
    }
    return _total;
  };

  const handleChangeCheckbox = (item) => {
    let exept = prices.filter((p) => p.id !== item.id);
    item.status = !item.status;
    item.qt = item.status ? 1 : 0;
    item.total_price = item.price * item.qt;
    setPrices([item, ...exept]);
  };

  const handleChangeQt = (event, item, qt = null) => {
    //setValueCheckbox(event.target.value);
    let nQt = qt ?? event.target.value;
    let exept = prices.filter((p) => p.id !== item.id);
    item.qt = nQt < 1 ? "" : nQt;
    item.total_price = item.price * item.qt;
    setPrices([item, ...exept]);
  };

  const checkCoupon = (form) => {
    setCoupon({});

    setLoadingCoupon(true);
    API.CheckCoupon(form)
      .then((res) => {
        setCoupon(res.coupon);
        setCouponHelper(
          `Vous bénéficiez d'une réduction de -${res.coupon.value}% pour cette réservation`
        );
      })
      .catch((err) => {
        setCouponHelper(err.data.errors);
      })
      .then(() => {
        setLoadingCoupon(false);
      });
  };

  const submitBooking = async () => {
    let d = new Date(selectedDate);
    let date =
      new Intl.DateTimeFormat("fr", { day: "2-digit" }).format(d) +
      "/" +
      new Intl.DateTimeFormat("fr", { month: "2-digit" }).format(d) +
      "/" +
      new Intl.DateTimeFormat("fr", { year: "numeric" }).format(d);
    //console.log( date , selectedTime );

    let _prices = [];
    await prices.forEach((price) => {
      if (price.qt > 0 && price.status === true) {
        _prices.push(price);
      }
    });

    let _form = {
      event: event,
      date_activity: date,
      time_activity: selectedTime,
      prices: _prices,
      city: cityStart,
      roader: roader,
      roaders: roaders,
      settings: settings,
      price_total: totalPrice(prices, false),
      price_for_pay:
        (totalPrice(prices, false) / 100) * (100 - event.percentage_paid),
      price_coupon: {
        total: totalPrice(prices, true),
        for_pay:
          (totalPrice(prices, true) / 100) * (100 - event.percentage_paid),
      },
      coupon,
      sejour_dates: {
        check_in: check_in,
        check_out: check_out,
      },
    };
    await localStorage.setItem("order_booking", JSON.stringify(_form));
    history.push("/checkout");
  };

  const validateSejourDates = () => {
    return (
      check_in !== "" &&
      check_out !== "" &&
      new Date(check_out) > new Date(check_in)
    );
  };

  const isDateReserved = (date) => {
    // Implement your logic to check if a date is reserved
    // Return true if reserved, false otherwise
    return true; // Replace with your logic
  };

  const handleCheckInDateChange = (date) => {
    setCheck_in(date);
    handleSejourQtChange(date, check_in);
  };

  const handleCheckOutDateChange = (date) => {
    setCheck_out(date);
    handleSejourQtChange(check_in, date);
  };

  const handleSejourQtChange = (date_check_in, date_check_out) => {
    let newQt = 1;
    if (date_check_in !== "" && date_check_out !== "") {
      var date1 = new Date(date_check_out);
      var date2 = new Date(date_check_in);
      var differenceInMilliseconds = date1 - date2;
      var differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
      newQt = Math.floor(differenceInDays);
      newQt = newQt <= 0 ? 1 : newQt;
    }
    let newPrices = [];
    prices.forEach((price) => {
      price.qt = newQt;
      price.total_price = price.total_price * newQt;
      newPrices.push(price);
    });
    setPrices(newPrices);
  };

  const renderDay = (date, selectedDate, dayInCurrentMonth, dayComponent) => {
    const isReserved = isDateReserved(date);
    const classNames = [classes.reservedDate];

    if (isReserved) {
      classNames.push(classes.reservedDate);
    }

    return React.cloneElement(dayComponent, {
      className: classNames.join(" "),
    });
  };

  React.useEffect(() => {
    // if not storage order
    if (!Object.keys(event).length) {
      history.replace("/");
    }

    //console.log( gift );
    //console.log( event );

    // check if is a gift set percentage_paid 100%
    if (Object.keys(gift).length && gift.gift_email) {
      event.percentage_paid = 100;
    }

    // Set title page
    document.title = API.APP_NAME + " - Booking - " + event.title;

    let _prices = [];

    event.prices.forEach((item) => {
      _prices.push({
        ...item,
        status: parseInt(item.default) ? true : false,
        qt: item.default,
        total_price: item.price * item.default,
      });
    });

    setPrices(_prices);

    // Get Settings Data with Roaders
    setLoadingSettings(true);
    API.Settings()
      .then((res) => {
        //console.log( res );
        setSettings(res.settings);
        setRoaders(res.roaders);
      })
      .then(() => {
        setLoadingSettings(false);
      });
  }, [0]);

  React.useEffect(() => {
    //console.log( prices );
    let total_places = 0;
    prices.forEach((price) => {
      total_places = parseInt(total_places) + parseInt(price.qt);
    });
    console.log("event.places_available", event.places_available)
    setPlacesAvailable(event.places_available - total_places);
  }, [prices]);

  const handleDesableButton = () => {
    // if (placesAvailable < 0) {
    //   console.log("placesAvailable < 0");
    //   return true;
    // }
    if (event.type == "Event" && !cityStart) {
      console.log("cityStart");
      return true;
    }
    if (
      event.type !== "Event" &&
      event.type != "Sejour" &&
      parseInt(event.hour_status) &&
      !selectedTime
    ) {
      console.log("selectedTime");
      return true;
    }
    if (!totalPrice(prices)) {
      console.log("totalPrice(prices)");
      return true;
    }
    if (event.type == "Event" && parseInt(event.covoiturage) && !roader) {
      console.log("roader");
      return true;
    }
    if (couponName && !Object.keys(coupon).length) {
      console.log("couponName");
      return true;
    }
    if (
      event.type == "Sejour" &&
      (!check_in || !check_out || !validateSejourDates())
    ) {
      console.log("check_in");
      return true;
    }
    return false;
  };

  return (
    <div className="section--booking">
      <Banner size={"240px"} bg={banner02}>
        <Container maxWidth="lg" alignItems="center" justify="center">
          <Grid
            container
            item
            xs={12}
            sm={12}
            alignItems="center"
            justifyContent="center"
            className="h-100-100"
          >
            <div className="tafwija--banner--content">
              <h1> Votre réservation </h1>
            </div>
          </Grid>
        </Container>
      </Banner>
      {Object.keys(event).length && (
        <Container maxWidth="lg" className="p-top-40 p-bottom-40">
          <Grid container spacing={4}>
            <Grid item xs={12} md={8} container>
              <Card>
                <div className="booking--wrapper">
                  <h2> {event.title} </h2>
                  <Grid container spacing={3}>
                    {event.type === "Activitie" ? (
                      <MuiPickersUtilsProvider
                        libInstance={moment}
                        utils={MomentUtils}
                        locale={"fr"}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={parseInt(event.hour_status) ? 8 : 12}
                          className="m-bottom-20"
                        >
                          <KeyboardDatePicker
                            disablePast
                            disableToolbar
                            inputVariant="outlined"
                            format="DD/MM/yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            label="Choisissez une date"
                            minDate={new Date()}
                            value={selectedDate}
                            onChange={(date) => {
                              setSelectedDate(date);
                            }}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            style={{
                              width: "100%",
                              marginTop: "0px",
                              marginBottom: 20,
                            }}
                          />
                        </Grid>
                        {parseInt(event.hour_status) ? (
                          <Grid item xs={12} sm={4} className="m-bottom-20">
                            <FormControl
                              variant="outlined"
                              style={{ width: "100%", marginBottom: 20 }}
                            >
                              <InputLabel id="demo-multiple-name-label">
                                Heure *
                              </InputLabel>
                              <Select
                                labelId="demo-multiple-name-label"
                                id="demo-simple-select-outlined"
                                defaultValue={selectedTime}
                                onChange={(event) => {
                                  setSelectedTime(event.target.value);
                                }}
                                label="Heure"
                                required
                              >
                                {[
                                  "08h00 : 10h00",
                                  "10h00 : 12h00",
                                  "12h00 : 14h00",
                                  "14h00 : 16h00",
                                  "16h00 : 18h00",
                                  "18h00 : 20h00",
                                  "20h00 : 22h00",
                                ].map((item, index) => (
                                  <MenuItem key={index} value={item}>
                                    {" "}
                                    {item}{" "}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        ) : null}
                      </MuiPickersUtilsProvider>
                    ) : null}
                    {event.type === "Event" ? (
                      <>
                        <Grid item xs={6} sm={6} md={4}>
                          <div className="booking--wrapper--item">
                            <h1 className="titledark"> Date de départ : </h1>
                            <h2 className="title">
                              {" "}
                              {new Date(event.date_depart).toLocaleDateString(
                                "fr-FR"
                              )}{" "}
                            </h2>
                          </div>
                        </Grid>
                        <Grid item xs={6} sm={6} md={4}>
                          <div className="booking--wrapper--item">
                            <h1 className="titledark"> Date de fin : </h1>
                            <h2 className="title">
                              {" "}
                              {new Date(event.date_end).toLocaleDateString(
                                "fr-FR"
                              )}{" "}
                            </h2>
                          </div>
                        </Grid>
                        <Grid item xs={6} sm={6} md={4}>
                          <div className="booking--wrapper--item">
                            <h1 className="titledark">
                              {" "}
                              Places disponibles :{" "}
                            </h1>
                            <h2
                              className="title"
                              style={
                                placesAvailable > 0 ? {} : { color: "red" }
                              }
                            >
                              {placesAvailable}
                            </h2>
                          </div>
                        </Grid>
                      </>
                    ) : null}
                    {event.type === "Sejour" ? (
                      <>
                        {/* <MuiPickersUtilsProvider
                          libInstance={moment}
                          utils={MomentUtils}
                        >
                          <Grid item xs={12} sm={6} noValidate>
                            <KeyboardDateTimePicker
                              required={true}
                              variant="inline"
                              inputVariant="outlined"
                              format="DD/MM/yyyy HH:mm"
                              margin="normal"
                              id="date-picker-inline"
                              label="Check In : "
                              value={check_in}
                              onChange={handleCheckInDateChange}
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                              // renderDay={renderDay}
                              // shouldDisableDate={isDateReserved}
                              style={{
                                width: "100%",
                                marginTop: "0px",
                                marginBottom: 20,
                              }}
                              // Disable reserved dates
                            />
                          </Grid>
                        </MuiPickersUtilsProvider>
                        <MuiPickersUtilsProvider
                          libInstance={moment}
                          utils={MomentUtils}
                        >
                          <Grid item xs={12} sm={6} noValidate>
                            <KeyboardDateTimePicker
                              required={true}
                              variant="inline"
                              inputVariant="outlined"
                              format="DD/MM/yyyy HH:mm"
                              margin="normal"
                              id="date-picker-inline"
                              label="Check Out :"
                              value={check_out}
                              onChange={handleCheckOutDateChange}
                              KeyboardButtonProps={{
                                "aria-label": "changer la date",
                              }}
                              // renderDay={renderDay}
                              // shouldDisableDate={isDateReserved}
                              style={{
                                width: "100%",
                                marginTop: "0px",
                                marginBottom: 20,
                              }}
                            />
                          </Grid>
                        </MuiPickersUtilsProvider> */}
                        <Grid item xs={12} sm={6} noValidate>
                          <MuiPickersUtilsProvider
                            libInstance={moment}
                            utils={MomentUtils}
                            locale={"fr"}
                          >
                            <KeyboardDatePicker
                              disablePast
                              disableToolbar
                              inputVariant="outlined"
                              format="DD/MM/yyyy"
                              margin="normal"
                              id="date-picker-inline"
                              label="Check In :"
                              minDate={new Date()}
                              value={check_in}
                              onChange={(date) => {
                                handleCheckInDateChange(date);
                              }}
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                              style={{
                                width: "100%",
                                marginTop: "0px",
                                marginBottom: 20,
                              }}
                              shouldDisableDate={(date) => {
                                const startDate = moment(
                                  new Date(
                                    event.date_depart
                                  ).toLocaleDateString("fr-FR"),
                                  "DD/MM/yyyy"
                                );
                                const endDate = moment(
                                  new Date(event.date_end).toLocaleDateString(
                                    "fr-FR"
                                  ),
                                  "DD/MM/yyyy"
                                );

                                // Enable the date if it's within the range, disable otherwise
                                return !(
                                  date.isSameOrAfter(startDate) &&
                                  date.isSameOrBefore(endDate)
                                );
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} sm={6} noValidate>
                          <MuiPickersUtilsProvider
                            libInstance={moment}
                            utils={MomentUtils}
                            locale={"fr"}
                          >
                            <KeyboardDatePicker
                              disablePast
                              disableToolbar
                              inputVariant="outlined"
                              format="DD/MM/yyyy"
                              margin="normal"
                              id="date-picker-inline"
                              label="Check Out :"
                              minDate={new Date()}
                              value={check_out}
                              onChange={(date) => {
                                handleCheckOutDateChange(date);
                              }}
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                              style={{
                                width: "100%",
                                marginTop: "0px",
                                marginBottom: 20,
                              }}
                              shouldDisableDate={(date) => {
                                const startDate = moment(
                                  new Date(
                                    event.date_depart
                                  ).toLocaleDateString("fr-FR"),
                                  "DD/MM/yyyy"
                                );
                                const endDate = moment(
                                  new Date(event.date_end).toLocaleDateString(
                                    "fr-FR"
                                  ),
                                  "DD/MM/yyyy"
                                );

                                // Enable the date if it's within the range, disable otherwise
                                return !(
                                  date.isSameOrAfter(startDate) &&
                                  date.isSameOrBefore(endDate)
                                );
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>

                        {/* <Grid
                          item
                          xs={12}
                          sm={6}
                          className={classes.container}
                          noValidate
                        >
                          <TextField
                            id="check_in"
                            label="Check-in"
                            type="datetime-local"
                            value={check_in}
                            onChange={(e) =>
                              handleCheckInChange(new Date(e.target.value))
                            }
                            className={classes.textField}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            style={{
                              width: "100%",
                              marginTop: "0px",
                              marginBottom: 20,
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          className={classes.container}
                          noValidate
                        >
                          <TextField
                            id="check_out"
                            label="Check-out"
                            type="datetime-local"
                            value={check_out}
                            onChange={(e) =>
                              handleCheckOutChange(new Date(e.target.value))
                            }
                            className={classes.textField}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            style={{
                              width: "100%",
                              marginTop: "0px",
                              marginBottom: 20,
                            }}
                          />
                        </Grid> */}
                      </>
                    ) : null}

                    {/* {event.type === "Sejour" ? (
                      <MuiPickersUtilsProvider
                        libInstance={moment}
                        utils={MomentUtils}
                        locale={"fr"}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={parseInt(event.hour_status) ? 8 : 12}
                          className="m-bottom-20"
                        >
                          <KeyboardDatePicker
                            disablePast
                            disableToolbar
                            inputVariant="outlined"
                            format="DD/MM/yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            label="Choisissez une date d\'arrivée"
                            minDate={new Date()}
                            value={check_in}
                            onChange={(date) => {
                              setCheck_in(date);
                            }}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            style={{
                              width: "100%",
                              marginTop: "0px",
                              marginBottom: 20,
                            }}
                          />
                        </Grid>
                        {parseInt(event.hour_status) ? (
                          <Grid item xs={12} sm={4} className="m-bottom-20">
                            <FormControl
                              variant="outlined"
                              style={{ width: "100%", marginBottom: 20 }}
                            >
                              <InputLabel id="demo-multiple-name-label">
                                Heure *
                              </InputLabel>
                              <Select
                                labelId="demo-multiple-name-label"
                                id="demo-simple-select-outlined"
                                defaultValue={selectedTime}
                                onChange={(event) => {
                                  setSelectedTime(event.target.value);
                                }}
                                label="Heure"
                                required
                              >
                                {[
                                  "08h00 : 10h00",
                                  "10h00 : 12h00",
                                  "12h00 : 14h00",
                                  "14h00 : 16h00",
                                  "16h00 : 18h00",
                                  "18h00 : 20h00",
                                  "20h00 : 22h00",
                                ].map((item, index) => (
                                  <MenuItem key={index} value={item}>
                                    {" "}
                                    {item}{" "}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        ) : null}
                      </MuiPickersUtilsProvider>
                    ) : null} */}
                  </Grid>

                  <div className="booking--wrapper--item">
                    <div className="booking--wrapper--item--prices">
                      {prices
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((item) => {
                          return (
                            <div
                              key={item.id}
                              className="booking--wrapper--item--prices--price"
                            >
                              <div className="booking--wrapper--item--prices--price--head">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      onChange={() =>
                                        handleChangeCheckbox(item)
                                      }
                                      name="checked"
                                      color="primary"
                                      checked={item.status}
                                    />
                                  }
                                  label={item.name}
                                />
                              </div>
                              <div className="booking--wrapper--item--prices--price--body">
                                <h2 className="price">
                                  <span className="price-label">Prix: </span>
                                  {API.priceFormat(item.price)}
                                </h2>

                                <TextField
                                  type="number"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  variant="outlined"
                                  value={item.qt}
                                  onChange={(e) => handleChangeQt(e, item)}
                                  disabled={
                                    !item.status || event.type == "Sejour"
                                  }
                                />

                                <h2 className="price">
                                  <span className="price-label">
                                    Prix Total:{" "}
                                  </span>
                                  {API.priceFormat(item.total_price)}
                                </h2>
                              </div>
                              <div className="booking--wrapper--item--prices--price--footer"></div>
                            </div>
                          );
                        })}
                    </div>
                  </div>

                  {event.type === "Event" ? (
                    <>
                      <div className="booking--wrapper--item">
                        <h2>Ville de départ * </h2>
                        <label>Précisez la ville de votre départ</label>
                        <FormControl
                          variant="filled"
                          className="selectbox--item"
                        >
                          <Select
                            onChange={(e) => {
                              setCityStart(e.target.value);
                            }}
                          >
                            {event.gathering.map((item, index) => {
                              return (
                                <MenuItem key={index} value={item.gathering}>
                                  {" "}
                                  {item.gathering}{" "}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </div>

                      {parseInt(event.covoiturage) ? (
                        <>
                          {loadingSettings ? (
                            <CircularProgress />
                          ) : (
                            <div className="booking--wrapper--item">
                              <h2>Roader ( Co-voiturage ) * </h2>
                              <label>
                                Précisez si vous êtes motorisé ou pas
                              </label>
                              <FormControl
                                variant="filled"
                                className="selectbox--item"
                              >
                                <Select
                                  defaultValue={roader}
                                  onChange={(e) => {
                                    setRoader(e.target.value);
                                  }}
                                >
                                  {roaders.map((item) => {
                                    return (
                                      <MenuItem key={item.id} value={item.id}>
                                        {" "}
                                        {item.name}{" "}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </div>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : null}
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} md={4} container>
              <div className="recap--booking">
                <div className="recap--booking--body">
                  <div className="recap--booking--body--p1">
                    <h3>{event.title}</h3>
                    {/* <h3 className="price">
                      {API.priceFormat(totalPrice(prices))}
                    </h3> */}
                  </div>
                  <div className="recap--booking--body--p2">
                    {cityStart && (
                      <div className="recap--booking--body--p2--line">
                        <h6>Ville de départ:</h6>
                        <p> {cityStart} </p>
                      </div>
                    )}

                    <div className="recap--booking--body--p2--line">
                      <ul>
                        {prices.map((item, index) => {
                          if (item.qt > 0)
                            return (
                              <li key={index}>
                                <strong>
                                  {item.qt} {item.name}
                                </strong>
                              </li>
                            );
                        })}
                      </ul>
                    </div>
                    {event.type === "Sejour" ? (
                      <h6> max {event.capacitie} nombre de guest </h6>
                    ) : null}

                    {event.covoiturage == "1" && roader ? (
                      <div className="recap--booking--body--p2--line">
                        <h6>Roader ( Co-voiturage ): </h6>
                        <p> {roaders.find((r) => r.id == roader).name} </p>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  {Object.keys(coupon).length ? (
                    <div className="recap--booking--body--p3">
                      <h3>Réduction de -{coupon.value}% </h3>
                      <h3 className="price">
                        {API.priceFormat(
                          totalPrice(prices, true) - totalPrice(prices, false)
                        )}
                      </h3>
                    </div>
                  ) : null}

                  <div className="recap--booking--body--p3">
                    <h3>Total</h3>
                    <h3 className="price">
                      {API.priceFormat(totalPrice(prices, true))}
                    </h3>
                  </div>

                  <div
                    className="recap--booking--body--p3"
                    style={{ display: "block" }}
                  >
                    {!addCoupon && (
                      <h3
                        style={{ marginBottom: "18px", cursor: "pointer" }}
                        onClick={() => setAddCoupon(true)}
                      >
                        {" "}
                        + Ajouter un code de réduction{" "}
                      </h3>
                    )}

                    {addCoupon && (
                      <FormControl
                        variant="outlined"
                        style={{ width: "100%" }}
                        error={!Object.keys(coupon).length && couponHelper}
                        disabled={loadingCoupon}
                      >
                        <InputLabel htmlFor="outlined-adornment-coupon">
                          Saisir votre coupon
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-coupon"
                          onChange={(e) => {
                            setCouponName(e.target.value);
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              <Button
                                onClick={() => {
                                  checkCoupon({
                                    event_id: event.id,
                                    coupon_name: couponName,
                                  });
                                }}
                                edge="end"
                                disabled={loadingCoupon || !couponName}
                              >
                                {loadingCoupon ? (
                                  <CircularProgress size={25} />
                                ) : (
                                  <Done />
                                )}
                              </Button>
                            </InputAdornment>
                          }
                          labelWidth={70}
                        />
                        {couponHelper && (
                          <FormHelperText>{couponHelper}</FormHelperText>
                        )}
                      </FormControl>
                    )}
                  </div>

                  {event.percentage_paid !== 0 ? (
                    // 100 - event.percentage_paid === 0 ? (
                    //   ""
                    // ) :
                    loadingSettings ? (
                      <div className="recap--booking--body--p4">
                        <CircularProgress />
                      </div>
                    ) : (
                      <div className="recap--booking--body--p4">
                        <div className="recap--booking--body--p4--total">
                          <p>
                            Avance à payer de{" "}
                            <span style={{ fontSize: 14 }}>
                              {API.priceFormat(
                                totalPrice(prices, true) -
                                  (totalPrice(prices, true) / 100) *
                                    (100 - event.percentage_paid)
                              )}
                            </span>{" "}
                            dans 48h pour finaliser la réservation.
                          </p>
                        </div>
                      </div>
                    )
                  ) : (
                    <></>
                  )}

                  {/* {loadingSettings ? (
                    <div className="recap--booking--body--p4">
                      <CircularProgress />
                    </div>
                  ) : (
                    <div className="recap--booking--body--p4">
                      <div className="recap--booking--body--p4--total">
                        <p>
                          Payer un acompte de{" "}
                          <span>
                            {API.priceFormat( totalPrice(prices,true) - (totalPrice(prices,true) / 100) * ( 100 - event.percentage_paid)
                            )}
                          </span>{" "}
                          pour valider cette réservation.
                        </p>
                      </div>
                    </div>
                  )} */}
                </div>
                <div className="recap--booking--footer">
                  <Button
                    buttonStyle={"btn--second"}
                    buttonSize={"btn--large"}
                    onClick={submitBooking}
                    disabled={handleDesableButton()}
                  >
                    Confirmer votre réservation
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      )}
    </div>
  );
};
export default Booking;
