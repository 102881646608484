import React from 'react';
import "./style.scss";
import { RoomOutlined } from '@material-ui/icons';

const DepartCity = (props) => {
  return (
    <div className="depart--city">
      {/* <img src={require('../../../assets/images/car.svg')} alt="Covoiturage"/> */}
      <RoomOutlined />
      <h4>{props.title}</h4>
    </div>
  );
}
export default DepartCity;
