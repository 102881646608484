import React from "react";
import Modal from "../../shared/components/UI/Modal";

import * as API from "../../api";
import { Card, Grid } from "@material-ui/core";
import Title from "../../shared/components/UI/Title";

const InfoEventModal = ({ shown, close, order }) => {
  if (!Object.keys(order).length) return null;

  const event = order.event;

  const totalPlaces = API.OrderInfo(order).totalPlaces;
  const roader = API.OrderInfo(order).roader;
  const totalPrice = API.OrderInfo(order).totalPrice;
  const totalPriceByDiscount = API.OrderInfo(order).totalPriceByDiscount;
  const totalPricePay = API.OrderInfo(order).totalPricePay;
  const totalPriceRest = API.OrderInfo(order).totalPriceRest;

  const total = API.priceFormat(totalPriceByDiscount);
  const totalPay = API.priceFormat(totalPricePay);
  const theRest = API.priceFormat(totalPriceRest);
  const zeroPaid = API.priceFormat(totalPricePay) === "0,00 MAD" ? false : true;

  const coupon = parseInt(order.coupon_value) && {
    name: order.coupon_name,
    value: order.coupon_value,
    price: API.priceFormat(totalPriceByDiscount - totalPrice),
  };
  const userDiscount = parseInt(order.customer_discount) && {
    name: "user",
    value: order.customer_discount,
    price: API.priceFormat(totalPriceByDiscount - totalPrice),
  };

  return (
    <>
      <Modal
        shown={shown}
        close={close}
        size={"modal--large"}
        title={event.title}
      >
        <div>
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item xs={12} md={3}>
              <img src={event.image} alt={event.title} />
            </Grid>
            <Grid item xs={12} md={9}>
              <div className={`tafwija--checkout--card `}>
                <div className="tafwija--checkout--card--body">
                  <div className="tafwija--checkout--card--body--p1">
                    <h3> Evénement : {event.title}</h3>
                  </div>
                  <div className="tafwija--checkout--card--body--p2">
                    <div className="tafwija--checkout--card--body--p2--line">
                      <h6>Ville de départ:</h6>
                      <p>{order.depart_city}</p>
                    </div>

                    <div className="tafwija--checkout--card--body--p2--line">
                      <h6>Roader ( Co-voiturage ): </h6>
                      <p>{roader}</p>
                    </div>

                    <div className="tafwija--checkout--card--body--p2--line">
                      <h6>Personne(s): </h6>
                      <p>{totalPlaces}</p>
                    </div>
                    {event.type == "Activitie" ? (
                      <>
                        <div className="tafwija--checkout--card--body--p2--line">
                          <h6>Date de reservation: </h6>
                          <p>{order.date_activity}</p>
                        </div>
                        {parseInt(order.hour_status || event.hour_status) ? (
                          <div className="tafwija--checkout--card--body--p2--line">
                            <h6>Créneau: </h6>
                            <p>{order.time_activity}</p>
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      ""
                    )}

                    {event.type == "Event" ? (
                      <>
                        <div className="tafwija--checkout--card--body--p2--line">
                          <h6> Date de départ : </h6>
                          <p>
                            {new Date(event.date_depart).toLocaleDateString(
                              "fr-FR"
                            )}
                          </p>
                        </div>
                        <div className="tafwija--checkout--card--body--p2--line">
                          <h6> Heure de départ : </h6>
                          <p>{event.hour_depart}</p>
                        </div>
                      </>
                    ) : (
                      ""
                    )}

                    {order.prices && (
                      <div className="tafwija--checkout--card--body--p2--line">
                        <h6>Prix: </h6>
                        <p>
                          <span>
                            {order.prices.map((item, index) => {
                              const total =
                                parseFloat(item.price) *
                                (item.qt ?? item.quantities);
                              return (
                                <p key={index}>
                                  <span style={{ color: "#999" }}>
                                    {item.name} - &#62;{" "}
                                    {API.priceFormat(item.price)} x{" "}
                                    {item.qt ?? item.quantities}{" "}
                                  </span>{" "}
                                  : {API.priceFormat(total)}
                                </p>
                              );
                            })}
                          </span>
                        </p>
                      </div>
                    )}
                  </div>
                  {Object.keys(userDiscount).length &&
                  parseInt(userDiscount) ? (
                    <div className="tafwija--checkout--card--body--p3">
                      <h3>Réduc offre bienvenue -{userDiscount.value}% : </h3>
                      <h3 className="price"> {userDiscount.price} </h3>
                    </div>
                  ) : null}

                  {Object.keys(coupon).length &&
                  parseInt(coupon.value) &&
                  !parseInt(userDiscount) ? (
                    <div className="tafwija--checkout--card--body--p3">
                      <h3>Réduc du code promo -{coupon.value}% : </h3>
                      <h3 className="price"> {coupon.price} </h3>
                    </div>
                  ) : null}
                  <div className="tafwija--checkout--card--body--p3">
                    <h3>Montant Total</h3>
                    <h3 className="price">{total}</h3>
                  </div>
                  {zeroPaid ? (
                    <>
                      <div className="tafwija--checkout--card--body--p3">
                        <h3>Avance à payer sous 48h</h3>
                        <h3 className="price">{totalPay}</h3>
                      </div>
                      <div className="tafwija--checkout--card--body--p3">
                        <h3>à payer sur place</h3>
                        <h3 className="price">{theRest}</h3>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="tafwija--checkout--card--body--p3 p-bottom-20">
                        <h3>à payer sur place</h3>
                        <h3 className="price">{theRest}</h3>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={12}>
              {event.activityPlace ? (
                <div className="p-bottom-30">
                  <div className="p-bottom-20">
                    <Title title="Lieu de votre activité" mode="medium" />
                  </div>
                  <a style={{textDecoration:'none'}} href={event.activityPlace} target="_blank" title="...">
                    {" "}
                    Accéder à la position de votre lieu{" "}
                  </a>
                  <div
                  className="p-top-10"
                    dangerouslySetInnerHTML={{
                      __html: event.activityPlaceDesc,
                    }}
                  />
                </div>
              ) : (
                <></>
              )}
              {event.activityMeetDesc ? (
                <div className="p-bottom-30">
                  <div className="p-bottom-20">
                    <Title title="Vos point de rencontres" mode="medium" />
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: event.activityMeetDesc,
                    }}
                  />
                </div>
              ) : (
                <></>
              )}
              <div className="p-bottom-20">
                <Title title="Organisateur" mode="medium" />
              </div>

              <p className="p-bottom-10">
                {" "}
                <b>Nom :</b> {order.partner}
              </p>
              <p>
                {" "}
                <b>Téléphone :</b>{" "}
                <a href={`tel:${order.partner_phone}`} style={{textDecoration:'none'}}>{order.partner_phone}</a>
              </p>
            </Grid>
          
          </Grid>
        </div>
      </Modal>
    </>
  );
};
export default InfoEventModal;
