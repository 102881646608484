import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";


import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import CheckoutCard from "../../shared/components/CheckoutCard";


import * as API from "../../api";
import { CircularProgress } from "@material-ui/core";
/*
function Row(props) {
  const { order } = props;
  return (
    <React.Fragment>
      <TableRow>
        <TableCell component="th" scope="row">
          <span className="c-main"> N° {order.id}</span>
        </TableCell>
        <TableCell align="left">{order.event}</TableCell>
        <TableCell align="left">{order.date} </TableCell>
        <TableCell align="right">
          {parseInt(order.etat) ? <>Payé</> : <></>}{" "}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
*/


function Row(props) {
  const { order } = props;
  const [open, setOpen] = React.useState(false);
  const totalPlaces = API.OrderInfo(order).totalPlaces;
  const roader = API.OrderInfo(order).roader;
  const totalPrice = API.OrderInfo(order).totalPrice;
  const totalPriceByDiscount = API.OrderInfo(order).totalPriceByDiscount;
  const totalPricePay = API.OrderInfo(order).totalPricePay;
  const totalPriceRest = API.OrderInfo(order).totalPriceRest;
  return (
    <React.Fragment>
      <TableRow>
        <TableCell component="th" scope="row">
          <span className="c-main"> N° {order.id}</span>
        </TableCell>
        <TableCell align="left">
          {order.event}
        </TableCell>
        <TableCell align="left">
          {order.partner}<br />{order.partner_phone}
        </TableCell>
        <TableCell align="left">
          {parseInt(order.etat) == 1 ? <> { API.priceFormat( totalPricePay ) == 0 ? "Payé" : "Payable sur Place"}</> : <>En attente</>}
        </TableCell>
        <TableCell align="left">
          {" "}
          { API.priceFormat( totalPricePay ) == 0 ? API.priceFormat(totalPricePay) : API.priceFormat(totalPriceRest)}
          <br/>
           <small> pour 1 article</small>
        </TableCell>
        <TableCell align="left">{order.date}</TableCell>
        <TableCell align="right">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            className="collapsTable"
          >
            <CheckoutCard
              orderInfo={order}
              coupon={ parseInt(order.coupon_value) && { name : order.coupon_name , value : order.coupon_value      , price : API.priceFormat( totalPriceByDiscount - totalPrice) } }
              userDiscount={ parseInt(order.customer_discount) && { name : 'user' , value : order.customer_discount , price : API.priceFormat( totalPriceByDiscount - totalPrice) } }

              title={order.event}
              price={API.priceFormat(totalPrice)}
              departCity={order.depart_city}
              carpool={roader}
              date={new Date(order.date).toDateString()}
              qte={totalPlaces}
              total={API.priceFormat(totalPriceByDiscount)}
              totalPay={API.priceFormat(totalPricePay)}
              theRest={API.priceFormat(totalPriceRest)}
              modePay={parseInt(order.etat) == 1}
              zeroPaid={ API.priceFormat( totalPricePay ) === "0,00 MAD" ?  false : true }
              checkoutDescription={false}
              prices={ <span>{order.prices.map((item, index) => {
                const total = parseFloat(item.price) * item.quantities
                return (
                  <p key={index}><span style={{color: '#999'}} >{item.name} - &#62; {API.priceFormat(item.price)} x {item.quantities} </span> : {API.priceFormat(total)}</p>
                )
              })}</span>}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}


const CommandConfirmed = (props) => {
  const [loadingOrders, setLoadingOrders] = React.useState(false);
  const [ordersCconfirmed, setOrdersConfirmed] = React.useState([]);

  React.useEffect(() => {
    setLoadingOrders(true);
    API.UserOrdersConfirmed()
      .then((res) => {
        setOrdersConfirmed(res.data);
      })
      .catch((err) => {
        //console.log(err);
      })
      .then(() => {
        setLoadingOrders(false);
      });
  }, [0]);

  return (
    <TableContainer className="tafwija--table">
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell >N° </TableCell>
            <TableCell >Résérvation</TableCell>
            <TableCell >Partenaire & Tél</TableCell>
            <TableCell >Statut</TableCell>
            <TableCell >Total Payé</TableCell>
            <TableCell colSpan={2} >Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loadingOrders ? (
            <TableRow>
              <TableCell colSpan={6}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : (
            <>
              {ordersCconfirmed.length ? (
                <>
                  {
                  ordersCconfirmed
                    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at) )
                    .map((order, index) => (
                      <Row key={index} order={order} />
                    ))
                  }
                </>
              ) : (
                <TableRow>
                    <TableCell colSpan={6}>
                      Il n'y a pas encore de données
                    </TableCell>
                </TableRow>
              )}
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default CommandConfirmed;
