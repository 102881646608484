import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import SearchBar from "../../shared/components/SearchBar";
import banner01 from "../../shared/assets/images/banner01.jpg";
import banner02 from "../../shared/assets/images/banner02.jpg";
import banner03 from "../../shared/assets/images/banner03.jpg";
import banner04 from "../../shared/assets/images/banner04.jpg";
import Title from "../../shared/components/UI/Title";
import HotDeals from "../../shared/components/EventsCard/HotDeals";
import EventCarousel from "../../shared/components/EventsCard/EventCarousel";
import ActivitieCarousel from "../../shared/components/EventsCard/ActivitieCarousel";
import SejourCarousel from "../../shared/components/EventsCard/SejourCarousel";
import OurValues from "./OurValues";
import WOW from "wow.js";
import * as API from "../../api";

var images = [],
  index = 0;
images[0] = banner01;
images[1] = banner02;
images[2] = banner03;
images[3] = banner04;
index = Math.floor(Math.random() * images.length);

const Home = () => {
  const [auth, setAuth] = React.useState(false);
  const [loadingAuth, setLoadingAuth] = React.useState(false);
  const [settings, setSettings] = React.useState({});

  useEffect(() => {
    const wow = new WOW();
    wow.init();

    setLoadingAuth(true);
    API.Auth()
      .then(({ user }) => {
        setAuth(true);
      })
      .catch(() => {
        setAuth(false);
      })

      .then(() => {
        setLoadingAuth(false);
      });

    API.Settings().then((res) => {
      setSettings(res.settings);
    });
    localStorage.removeItem('intended');
  }, []);

  return (
    <div>
      <div
        className="tafwija--banner"
        style={{
          backgroundImage: `linear-gradient(to bottom right, rgba(3 ,56 ,125,.2), rgba(255 ,182 ,44,.2)), url(${images[index]})`,
        }}
      >
        <Container maxWidth="lg" className="h-100-100">
          <Grid
            container
            // spacing={4}
            alignItems="center"
            justifyContent="center"
            className="h-100-100"
          >
            <Grid
              item
              xs={12}
              md={8}
              lg={8}
              container
              justifyContent="center"
              alignItems={"center"}
            >
              <div className="tafwija--banner--content">
                <h1
                  className=" wow zoomIn"
                  data-wow-offset="50"
                  data-wow-delay="0.5s"
                >
                  Redécouvrez la beauté du Maroc
                </h1>
                <p
                  className=" wow zoomIn"
                  data-wow-offset="50"
                  data-wow-delay="0.5s"
                >
                  Réservez en deux clicks des expériences inoubliables
                </p>
              </div>
              <SearchBar />
            </Grid>
          </Grid>
        </Container>
      </div>

      {!loadingAuth && (
        <>
          {(!auth && settings )? (
            <>
              { parseInt( settings.customer_discount ) > 0 ?
                (
                <div className="section--4 p-top-10 p-bottom-10">
                  <Container maxWidth="lg">
                    <Grid
                      container
                      className=" wow slideInLeft"
                      data-wow-offset="50"
                      data-wow-delay="0.5s"
                    >
                      <Grid item xs={12} md={12} container alignItems="center">
                        <div className="message--bonus">
                        <p>
                          Bénéficier d’une réduction de
                          <strong style={{ color: "#ffb62c", fontWeight: "800", fontSize: "16px", }} >
                            { settings.customer_discount } %
                          </strong>
                          sur votre première commande sur l’ensemble de nos événements.
                        </p>
                        </div>
                      </Grid>
                    </Grid>
                  </Container>
                </div>
                )
                :
                (
                  <></>
                )
              }
            </>
          ) : (
            <></>
          )}
        </>
      )}
      <OurValues />

      <div className="section--1 p-top-20 p-bottom-60">
        <Container maxWidth="lg">
          <Grid
            container
            className="p-top-40 p-bottom-40 wow slideInLeft"
            data-wow-offset="50"
            data-wow-delay="0.5s"
          >
            <Grid item xs={12} md={12} container>
              <Title title={"Notre sélection"} />
            </Grid>
          </Grid>
          <HotDeals />
        </Container>
      </div>

      <div className="section--2 p-top-20 p-bottom-60">
        <Container maxWidth="lg">
          <Grid container spacing={4} className="p-top-40 p-bottom-40">
            <Grid item xs={12} md={12} container>
              <Title title={"Nos autres Voyages"} color="white" />
            </Grid>
          </Grid>
          <EventCarousel />
        </Container>
      </div>


      <div className="section--5 p-top-20 p-bottom-80">
        <Container maxWidth="lg">
          <Grid container spacing={4} className="p-top-40 p-bottom-40">
            <Grid item xs={12} md={12} container>
              <Title title={"Nos autres activités "} />
            </Grid>
          </Grid>

          <ActivitieCarousel />
        </Container>
      </div>


      <div className="section--5 p-top-20 p-bottom-80">
        <Container maxWidth="lg">
          <Grid container spacing={4} className="p-top-40 p-bottom-40">
            <Grid item xs={12} md={12} container>
              <Title title={"Nos autres séjours"} />
            </Grid>
          </Grid>

          <SejourCarousel />
        </Container>
      </div>
    </div>
  );
};

export default Home;
